import API from './api';
import URLS from '../URLS';


const AssignmentServices = {

    assignResource: function (PatientId, data) {
        return API.axios({
            method: 'post',
            url: `${URLS.patients}/${PatientId}${URLS.resources}`,
            data: data
        });
    },

    // GetRemindersByPatientId
    getRemindersByPatientId: function (PatientId) {
        return API.axios({
            method: 'get',
            url: `${URLS.patients}${URLS.activity}/${PatientId}`
        });
    },

 
    // GetAllReminders
    getAllReminders: function () {
        return API.axios({
            method: 'get',
            url: `${URLS.patients}${URLS.activity}${URLS.all}`
        });
    },
    

    completeReminder: function (PatientId, data) {
        return API.axios({
            method: 'put',
            url: `${URLS.patients}${URLS.activity}/${PatientId}`,
            data: data
        });
    },

    deleteRerminderByPatientID: function (ReminderId) {
        return API.axios({
            method: 'put',
            url: `${URLS.patients}${URLS.activity}/deleted/${ReminderId}`,
        });
       
    },

    // GetRemindersByDates
    getPercentCompletedReminder: function (PatientId) {
        return API.axios({
            method: 'get',
            url: `${URLS.patients}${URLS.activity}${URLS.completed}/${PatientId}`,
        });
    },

    // GetRemindersByDates
    getRemindersByDates: function (PatientId, startOfPeriod, endOfPeriod) {
        return API.axios({
            method: 'get',
            url: `${URLS.patients}${URLS.activity}${URLS.dates}/${PatientId}`,
            params:{
                startOfPeriod: startOfPeriod,
                endOfPeriod: endOfPeriod
            }
        });
    },
}


export default AssignmentServices;