import React, { useState } from 'react';
import ReactGA from 'react-ga';
import 'moment/locale/es';

const TermsAndConditions = ({ setStep }) => {

	const [ acceptCheck, setAcceptCheck ] = useState(false);

	const handleAcceptCheck = (ev) => {
		setAcceptCheck(ev.target.checked);
	};

	const handleContinue = (e) => {
		e.preventDefault();

		if (!acceptCheck) {
			var objDiv = document.getElementById('termsscroll');
			objDiv.scrollTop = objDiv.scrollHeight;
		} else {
			setStep(2);
		}
	};

	ReactGA.event({ category: 'Registro', action: 'TerminosYCondiciones' });

	return (
		<div className="content-container">
			<div className="middle-center-content">
				<div className="heading">
					<h1 className="paged-title">Términos y condiciones</h1>
					<p>Antes de continuar debes revisar los términos y condiciones relacionados al uso del servicio.</p>
				</div>
				<form action="">
					<div className="bodycontent">
						<div id="termsscroll" className="terms mb-5">
							<h1 className="">
								<span className="">TÉRMINOS Y CONDICIONES DE USO DE APLICACIÓN “REDBLU”</span>
								<span className="">&rdquo;</span>
							</h1>
							<p className="red text-center" style={{ fontSize: '11px' }}>
								<strong> AVISO IMPORTANTE:</strong> SI PRESENTA UNA URGENCIA EN SALUD, NO UTILICE ESTA
								APLICACIÓN Y<br />
								<strong>DIRÍJASE DE INMEDIATO AL CENTRO DE ATENCIÓN MÁS CERCANO.</strong>
							</p>
							<p className="">
								<span className="c5 c12">BIENVENIDO/A A REDBLU</span>
							</p>
							
							<h2 className="">1. DISPOSICIONES GENERALES</h2>
							<p className="mb-1">
								<span>
									<strong>REDBLU SPA</strong>, pone a disposición de la población esta aplicación para
									recibir la atención no presencial de un profesional de la salud a través de
									videollamada, cuyo acceso y uso se rige por los términos y condiciones descritos a
									continuación y por la legislación que se aplique en la República de Chile; en
									consecuencia, todas las visitas, transacciones, actos y atenciones de salud que se
									realicen a través de esta aplicación y sus efectos jurídicos, quedarán regidos por
									estas reglas y sometidas a esa legislación.
								</span>
							</p>
							<p className="mb-1">
								<span>
									Esta aplicación en ningún caso podrá ser usada en caso de una urgencia psiquiátrica
									o médica, de encontrarse el paciente en esa situación, deberá dirigirse al centro de
									salud más cercano.
								</span>
							</p>
							<p className="mb-1">
								<span>
									Este documento describe los términos, condiciones generales y las condiciones
									particulares de la aplicación, en adelante "Términos y Condiciones", incluidas las
									relativas a las herramientas provistas a través de la misma para la realización de
									video-consultas a distancia y el intercambio de información y/o datos personales, en
									adelante también referidos como “los Servicios”.
								</span>
							</p>
							<p className="mb-1">
								<span>
									La presente aplicación y sus términos y condiciones aquí contenidos, se ajustan a
									las normativas legales y éticas que regulan la atención en salud mental en Chile.
								</span>
							</p>
							<p className="mb-1">
								<span>
									Se encuentra prohibido utilizar la aplicación con una finalidad ilegal o prohibida
									en estos términos y condiciones y en las leyes vigentes. La sola visita de este
									sitio web no impone al consumidor obligación alguna, a menos que haya aceptado en
									forma inequívoca las condiciones ofrecidas por el REDBLU SPA, en la forma indicada
									en estos términos y condiciones.
								</span>
							</p>
							<h2 className="mt-2">2. DE LOS PROFESIONALES QUE ENTREGARÁN ATENCIONES DE SALUD A DISTANCIA</h2>

							<p className="mb-1">
								<span>
									Los profesionales que prestan servicios a través de esta plataforma, están
									debidamente acreditados conforme a las exigencias regulatorios existentes en Chile y
									han sido seleccionados por REDBLU SPA por su idoneidad, conocimientos y experiencia
									en el ámbito de la salud mental; dichos profesionales han aceptado realizar atención
									de pacientes en modalidad de videollamada de acuerdo a los protocolos clínicos y
									criterios que indican la psiquiatría en particular, la psicología y medicina en
									general, a la práctica que tienen en Chile estas disciplinas. Cada profesional es
									responsable de cumplir con las buenas prácticas clínicas propias de su profesión,
									siendo responsable también del abandono de las mismas o de eventos de “mala praxis”.
								</span>
							</p>

							<h2 className="mt-2">3. REGISTRO COMO USUARIO Y ACCESO</h2>
							<p className="mb-1">
								<span>
									Para tener acceso a los servicios en línea de esta aplicación, usted deberá
									registrarse a través de ella misma como usuario, al efecto deberá crear una cuenta
									personal, suministrando la información que se solicitará al completar la ficha de
									información personal, siendo su obligación, como la de todo paciente ante un
									prestador de salud, proveer información correcta, verdadera y actualizada, tanto al
									momento de registrarse como cuando deba realizar la actualización de algún dato de
									dicha ficha. En caso de suministrar información falsa o incorrecta, el proveedor
									podrá suspender su cuenta y negarle el acceso a esta aplicación.
								</span>
							</p>
							<p className="mb-1">
								<span>
									Al registrarse, Ud. deberá hacerlo con su número de RUT y una clave de acceso, la
									cual podrá modificar cuando lo desee siguiendo las indicaciones de la aplicación;
									dicha clave será personal e intransferible, siendo de su responsabilidad mantenerla
									en reserva y fuera del conocimiento de terceros, lo que aplica incluso a pacientes
									menores de 18 años, sin perjuicio de los derechos de sus padres o tutor legal. Al
									aceptar estos términos y condiciones Ud. asume la responsabilidad por el uso no
									autorizado de su cuenta. En el caso que la seguridad de su cuenta se vea afectada
									por cualquier motivo, deberá notificar al proveedor de manera inmediata.
								</span>
							</p>
							<p className="mb-1">
								<span>
									La presentación por el paciente de una solicitud de registro a través de esta
									aplicación equivale a su aceptación de los presentes términos y condiciones. El
									proveedor podrá rechazar solicitudes de registro por estar incompletas o por otras
									razones fundadas.
								</span>
							</p>

							<h2 className="mt-2">4. COSTO Y PAGO DE LOS SERVICIOS</h2>
							<p className="mb-1">
								<span>
									4.1 El costo de la atención por videoconferencia mediante esta aplicación, dependerá
									de la tarifa que fije cada profesional de salud, la cual se encuentra claramente
									visible en la ficha del profesional desplegada en la aplicación. El pago de la
									atención deberá ser realizado una vez que el usuario haya programado en la agenda
									del profesional día y hora de atención.
								</span>
							</p>
							<p className="mb-1">
								<span>
									4.2 Para realizar el pago de la atención de salud, la aplicación pone a disposición
									del usuario el mecanismo de pago electrónico WebPay de Transbank. REDBLU SPA se
									reserva el derecho de incluir nuevos medios de pago en el futuro. La boleta por el
									servicio prestador será enviada al correo electrónico registrado por el paciente en
									un plazo no superior a 24 horas después de realizada la atención.
								</span>
							</p>
							<p className="mb-1">
								<span>
									4.3 En caso de que el paciente no pueda presentarse en la hora agendada,  podrá solicitar su reagendamiento por única vez <b>al menos con 48 horas de anticipación</b>, lo cual deberá hacer  mediante el formulario que al efecto dispone esta aplicación, fuera de ese plazo se considerará como solicitud de cancelación y será facultativo reagendar nueva hora; asimismo, una segunda solicitud de reagendamiento será considerada cancelación. En caso de que el paciente desee cancelar una hora agendada, también deberá solicitarlo por el formulario de que dispone la aplicación;  en general,  no se reembolsará lo pagado por horas canceladas, no obstante, se evaluará cada caso según la antelación con que se solicite la cancelación y los antecedentes que se hagan  valer para ello.
								</span>
							</p>
							<p className="mb-1">
								<span>
									Si el paciente no se presenta a la cita ni solicita reagendar oportunamente, no se le reembolsará el valor pagado.
								</span>
							</p>
              			<p className="mb-1">
								<span>
                				4.4 Si el profesional tuviere que  cancelar una hora agendada, deberá hacerlo mediante el formulario que al efecto dispone la aplicación señalando la situación que la motiva;  el back office gestionará el reagendamiento o la cancelación con el paciente; si no fuere posible el reagendamiento, se reembolsará al paciente el valor pagado <b>dentro de los 15 días hábiles siguientes</b> al rechazo del reagendamiento;   corresponderá al profesional asumir los costos administrativos y de transacción de las horas  canceladas por él.
								</span>
							</p>

							<h2 className="mt-2">5. POLÍTICA DE PRIVACIDAD</h2>
							<p className="mb-1">
								<span>
									5.1. Toda información personal o clínica que el paciente entregue al proveedor y/o
									al profesional que lo atienda mediante esta aplicación, tendrá el carácter de
									reservada y sensible, y su almacenamiento y tratamiento se regirá por las Leyes
									19.628 y 20.584 de Chile.
								</span>
							</p>
							<p className="mb-1">
								<span>
									5.2. En función de proteger la reserva de su información personal, el paciente se
									obliga a participar de la videollamada a través de la cual se le dará la atención de
									salud, desde un lugar físico que le permita dialogar con tranquilidad con el
									profesional, sin que haya interrupciones y sin la escucha de terceros; será de
									responsabilidad del paciente las consecuencias que pueda sufrir a causa de no
									adoptar dichas medidas.
								</span>
							</p>
							<p className="mb-1">
								<span>
									5.3. Estará prohibido la grabación de las atenciones de salud prestadas a través de
									esta aplicación, a menos que el paciente, el profesional y proveedor consientan
									expresamente en ello.
								</span>
							</p>
							<p className="mb-1">
								<span>
									5.4. El proveedor se obliga a no compartir la información personal y clínica de sus
									pacientes fuera de sus entidades asociadas; sin embargo, con la aceptación de estos
									Términos y Condiciones, el paciente autoriza al proveedor a almacenar y emplear
									dicha información en cualquiera de los siguientes casos: (i) para los fines de
									otorgamiento de atenciones de salud al paciente del proveedor, sea a través de esta
									aplicación sea en forma presencial; (ii) para permitir que la Isapre respectiva o
									FONASA provea la cobertura financiera respectiva al paciente; (iii) en caso de venta
									de la totalidad o una mayoría sustancial de los activos del proveedor o su fusión o
									reorganización con otra entidad, que implique la continuidad de los servicios del
									proveedor; y (iv) cuando sea requerido en los casos que autoriza la ley.
								</span>
							</p>
							<p className="mb-1">
								<span>
									5.5. El proveedor se reserva el derecho de utilizar datos estadísticos anónimos
									emanados o provenientes de informaciones generadas y almacenadas durante el proceso
									de interacción entre usuarios y profesionales de la salud, con el fin de mejorar la
									calidad de atención de los pacientes y de estudios académicos.
								</span>
							</p>
							<p className="mb-1">
								<span>
									5.6. Los datos de navegación, la dirección de protocolo ("IP") de internet del
									dispositivo (o del servidor proxy que utiliza) del paciente, sus patrones de correo
									electrónico y sistemas operativos móviles, datos de geolocalización y demás
									información que recibiere el proveedor con motivo del uso de esta aplicación, serán
									mantenidos en reserva y utilizados para los fines de su atención de salud y
									revelados en los casos que la ley autoriza.
								</span>
							</p>

							<h2 className="mt-2">6. RESPONSABILIDAD</h2>
							<p className="mb-1">
								<span>
                				La responsabilidad del proveedor por el uso de esta aplicación se limita a las atenciones de salud prestadas a través de ella por profesionales de su staff, sin que le corresponda responsabilidad alguna por servicios que no sean provistos directamente por él.
								</span>
							</p>
							<p className="mb-1">
								<span>
                				El paciente deberá contar con un dispositivo con cámara de video y audio de calidad y una conexión a internet adecuadas para asegurar la realización de videollamadas para recibir atención de salud a distancia.
								</span>
							</p>
              			<p className="mb-1">
								<span>
                				Las deficiencias que pueda presentar el uso de esta aplicación por fallas del dispositivo en el que se encuentre instalada o por deficiencias en la calidad de la conexión a Internet que utilice el paciente, no harán incurrir en responsabilidad alguna al proveedor y sus profesionales, y si de ello se deriva la imposibilidad de recibir la atención de salud previamente agendada, el proveedor no estará obligado a reembolsar al paciente el pago de la prestación no realizada  ni a agendar una nueva cita.
								</span>
							</p>

							<h2 className="mt-2">7. CONDICIONES TÉCNICAS</h2>
							<p className="mb-1">
								<span>
									7.1. Para utilizar esta aplicación, el usuario deberá contar con un dispositivo con
									acceso a Internet, con sistemas operativos Microsoft Windows, iOS o Android, y con
									un mínimo de 1 Mbps de velocidad de conexión a Internet de banda ancha; en caso de
									utilizar un computador, deberá contar con cámara web y micrófono; asimismo, el
									usuario deberá contar con una casilla electrónica válida (email).
								</span>
							</p>
							<p className="mb-1">
								<span>
									7.2. Esta aplicación utiliza "cookies" y archivos de registro web para analizar el
									uso de la aplicación, mejorar la calidad del servicio y personalizar en la medida de
									lo posible la experiencia del usuario. Una cookie es un archivo de datos que reside
									en su computadora, teléfono inteligente, tableta o cualquier otro dispositivo desde
									el que ingrese y permite su reconocimiento como usuario cuando regrese a la
									aplicación desde el mismo equipo, siempre que utilice el mismo navegador.
								</span>
							</p>
							<p className="mb-1">
								<span>
									Usted tiene el derecho a eliminar o bloquear las cookies de la aplicación utilizando
									la configuración de su navegador, pero ello podría afectar su acceso a la aplicación
									o el uso de sus servicios.
								</span>
							</p>
							<p className="mb-1">
								<span>
									7.3. La aplicación puede contener enlaces a sitios web u otras aplicaciones, los que
									puede no ser de propiedad u operados por REDBLU SPA. En atención a que REDBLU SPA no
									tiene control sobre tales sitios y aplicaciones, no será responsable por los
									contenidos, materiales, acciones y/o servicios prestados por aquéllos, ni por daños
									o pérdidas ocasionadas por la utilización de los mismos, sean causadas directa o
									indirectamente. La existencia de enlaces a otros sitios web o aplicaciones, no
									implica una sociedad, relación, aprobación, respaldo de REDBLU SPA respecto a ellos.
								</span>
							</p>
							<p className="mb-1">
								<span>
									7.4. Para garantizar la seguridad y estabilidad de la aplicación y de la
									transferencia de información a través de ella, REDBLU SPA podrá adoptar las medidas
									técnicas que estime adecuadas frente a cualquier amenaza, incluida la suspensión de
									los servicios de la aplicación.
								</span>
							</p>

							<h2 className="mt-2">8. PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
							<p className="mb-1">
								<span>
									8.1. Los contenidos de esta aplicación se encuentran protegidos por derechos de
									propiedad intelectual e industrial pertenecientes a REDBLU SPA, es así como los
									programas, modelos, diseños, marcas, signos distintivos, nombres comerciales,
									dominios, imágenes, fotografías, sonido, audio, vídeo, diseño gráfico, código
									fuente, logos, textos, gráficos, ilustraciones, bases de datos y otros elementos de
									la aplicación son de la propiedad exclusiva de REDBLU SPA, quien se reserva
									expresamente todos los derechos derivados de la misma.
								</span>
							</p>
							<p className="mb-1">
								<span>
									Queda expresamente prohibida toda forma de reproducción, distribución o difusión de
									la totalidad o parte de los contenidos de esta aplicación con fines comerciales sin
									la autorización expresa y escrita de REDBLU SPA.
								</span>
							</p>
							<p className="mb-1">
								<span>
									8.2. El paciente estará a obligado a utilizar el contenido incluido en esta
									plataforma sólo para su propio uso, y a respetar los derechos de propiedad
									intelectual e industrial de REDBLU SPA; asimismo, deberá abstenerse de suprimir,
									alterar, eludir o manipular cualquier dispositivo de protección o sistema de
									seguridad que estuviera instalado en esta aplicación.
								</span>
							</p>

							<h2 className="mt-2">9. DERECHO DE EXCLUSIÓN</h2>
							<p className="mb-1">
								<span>
									REDBLU SPA se reserva el derecho de denegar o retirar el acceso a la aplicación y/o
									servicios ofrecidos a través de ella sin necesidad de preaviso, a cualquier usuario
									que incumpla las condiciones estipuladas en estos Términos y Condiciones.
								</span>
							</p>

							<h2 className="mt-2">10. MODIFICACIONES</h2>
							<p className="mb-1">
								<span>
									REDBLU SPA se reserva el derecho de efectuar sin previo aviso las modificaciones que
									considere oportunas en esta aplicación, pudiendo cambiar, suprimir o añadir los
									contenidos y servicios que se prestan a través del mismo y/o la forma en que
									aparezcan presentados.
								</span>
							</p>
							<p className="mb-1">
								<span>
									Asimismo, REDBLU SPA se reserva el derecho a modificar, desarrollar o actualizar en
									cualquier momento y sin previa notificación los Términos y Condiciones de Uso de
									esta aplicación; sin perjuicio de ello, deberá informar de los cambios efectuados en
									la siguiente acceso que haga el usuario a la aplicación a fin de que proceda a
									otorgar su aceptación, de no dar aceptación, no podrá acceder a los servicios; el
									usuario quedará obligado automáticamente por las condiciones de uso que se hallen
									vigentes en el momento en que acceda y haga uso de los servicios.
								</span>
							</p>

							<h2 className="mt-2">11. RECLAMOS Y CONFLICTOS</h2>
							<p className="mb-1">
								<span>
									Ud. podrá formular reclamos y sugerencias en relación a esta aplicación y los
									servicios prestados a través de ella, asimismo, si tiene alguna duda sobre los
									Términos y Condiciones que rigen la aplicación o sobre los servicios ofrecidos,
									deberá comunicarse con el equipo de Atención al Cliente al mail contacto@redblu.cl,
									para resolver dicha duda.
								</span>
							</p>
							<p className="mb-1">
								<span>
									Cualquier controversia derivada del uso de esta aplicación y sobre estos Términos y
									Condiciones de Uso, será sometida a la resolución de los Tribunales Ordinarios de
									Justicia de la comuna de Santiago de Chile.
								</span>
							</p>

							<h2 className="mt-2">12. DATOS DEL PROVEEDOR DE LA APLICACIÓN</h2>
							<p className="mb-1">
								<span className="font-weight-bold">
									La presente aplicación es administrada por REDBLU SPA., RUT 77.351.266-3, con
									domicilio en Pfister 1896, Concepción, Chile, teléfono 41-2227534, y su
									representante legal es don Jorge Ochoa.{' '}
								</span>
							</p>

							<h2 className="mt-2">13. DECLARACIÓN EXPRESA DE ACEPTACIÓN Y CONSENTIMIENTO INFORMADO </h2>
							<p className="mb-1">
								<span>
									Luego de haber leído los términos y condiciones precedentes, declaro voluntariamente
									que los he comprendido a cabalidad y que acepto acceder a una atención por parte de
									un profesional del proveedor vía videollamada, con el fin de recibir la asistencia y
									evaluación en forma oportuna y así dar continuidad al tratamiento que requiero.
								</span>
							</p>
							<p className="mb-1">
								<span>
									Asimismo declaro voluntariamente lo siguiente respecto de las atenciones de salud de
									REDBLU:
								</span>
							</p>
							<p className="mb-1">
								<span>
									1. Que tengo conocimiento del carácter voluntario de recibir atención a distancia de
									un profesional mediante videollamada a través de esta aplicación.
								</span>
							</p>
							<p className="mb-1">
								<span>
									2. Que tengo conocimiento que los profesionales que me otorguen atención respetarán
									la privacidad y confidencialidad de mis datos clínicos y personales y de mi familia,
									conforme a la normas jurídicas y éticas que regulan la información clínica y la
									información personal de quienes reciben atenciones de salud, y que el profesional
									registrará en mi ficha clínica la información que estime relevante sobre la atención
									que me brinde y la información que yo le proporcione.
								</span>
							</p>
							<p className="mb-1">
								<span>
									3. Declaro que he leído el presente documento, se me han aclarado dudas sobre él y
									que comprendo todo su contenido, por lo que consiento libre e informadamente en
									recibir atención a distancia por parte de profesionales de REDBLU SPA mediante la
									aplicación REDBLU, cuyos Términos y Condiciones se han descrito precedentemente.
								</span>
							</p>
							<p className="mb-1">
								<span>
									Finalmente declaró que al aceptar los Términos y Condiciones contenidas en este
									documento junto al consentimiento informado contenido al final del mismo, acepto
									expresamente todas las disposiciones en él contenidas y accedo voluntariamente a
									otorgar dicho consentimiento, quedando obligado/a a cumplir con lo aquí estipulado
									para usuarios y pacientes.
								</span>
							</p>
							<p className="mb-1">
								<span>
									Si usted decide no aceptar estos Términos y Condiciones de Uso, no podrá usar de
									modo alguno esta aplicación.
								</span>
							</p>
							<p className="mb-1">
								<span>
									La aceptación que se otorgue a este documento implica una aceptación de los términos
									aquí contenidos, y es equivalente a una manifestación de consentimiento para todos
									los efectos legales.
								</span>
							</p>
							<p className="mb-1 mt-2">
								<span className="font-weight-bold text-uppercase">JULIO 2022</span>
							</p>

							<div className="form-check mt-4 pb-4">
								<input
									type="checkbox"
									className="form-check-input"
									onChange={handleAcceptCheck}
									id="acceptCheck"
								/>
								<label className="form-check-label" htmlFor="acceptCheck">
									{' '}
									Acepto los términos y condiciones
								</label>
							</div>
						</div>
					</div>
					<div className="actioncontent">
						<div className="row">
							<div className="col-12 text-right">
								<input
									type="submit"
									value="CONTINUAR"
									className={!acceptCheck ? 'disabled' : ''}
									onClick={handleContinue}
								/>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default TermsAndConditions;
