export const ErrorCodes = {

  //VERIFICACION DE PERMISOS 

  'PERM_BROWSER_INCOMPATIBLE': {
    title: 'El navegador no es compatible',
    description: 'Estás utilizando un navegador que no es compatible con la plataforma, por lo que te recomendamos que intentes desde otro o puedes verificar que el que estás usando se encuentre actualizado a su última versión.',
    ref_url: '/solucion/navegador-incompatible',
  },

  'PERM_BROWSER_VIDEO_CODEC': {
    title: 'El navegador no es compatible',
    description: 'El navegador no es compatible con el formato de reproducción de video. La plataforma utiliza como codec de video VP8 o H264 para la reproducción de video. Si no cuentas con esto no podrás visualizar correctamente al profesional/paciente. Lo que puedes hacer es ingresar con un navegador distinto.',
    ref_url: '/solucion/navegador-incompatible',
  },

  'PERM_BROWSER_AUDIO_CODEC': {
    title: 'El navegador no es compatible',
    description: 'El navegador no es compatible con el formato de reproducción de audio. La plataforma utiliza como codec de audio OPUS para la reproducción del audio. Si no cuentas con esto no podrás escuchar correctamente al profesional/paciente. Lo que puedes hacer es ingresar con un navegador distinto.',
    ref_url: '/solucion/navegador-incompatible',
  },

  'PERM_MIC_PERMISSION_DISMISSED': {
    title: 'Has omitido el permiso del micrófono',
    description: 'La plataforma necesita que puedas permitir el uso del micrófono para que podamos utilizarlo en la teleconsulta.',
    ref_url: '/solucion/permiso-microfono-omitido',
  },

  'PERM_MIC_PERMISSION_DENIED': {
    title: 'Has bloqueado el permiso del micrófono',
    description: 'La plataforma necesita que puedas permitir el uso del micrófono para que podamos utilizarlo en la teleconsulta.',
    ref_url: '/solucion/permiso-microfono-bloqueado',
  },

  'PERM_MIC_PERMISSION_DENIED_SYSTEM': {
    title: 'El sistema ha bloqueado el uso del micrófono',
    description: 'Hemos detectado que el sistema ha bloqueado el uso del micrófono.',
    ref_url: '/solucion/permiso-microfono-bloqueado-por-sistema',
  },

  'MIC_NOT_FOUND': {
    title: 'No se han encontrado micrófonos',
    description: 'La plataforma ha detectado que no se encuentran micrófonos a utilizar y es necesario para la teleconsulta.',
    ref_url: null,
  },

  'PERM_CAM_PERMISSION_DISMISSED': {
    title: 'Has omitido el permiso de la cámara',
    description: 'La plataforma necesita que puedas permitir el uso de la cámara para que podamos utilizarlo en la teleconsulta.',
    ref_url: '/solucion/permiso-camara-omitido',
  },

  'PERM_CAM_PERMISSION_DENIED': {
    title: 'Has bloqueado el permiso de la cámara',
    description: 'La plataforma necesita que puedas permitir el uso de la cámara para que podamos utilizarlo en la teleconsulta.',
    ref_url: '/solucion/permiso-camara-bloqueado',
  },

  'CAM_NOT_FOUND': {
    title: 'No se han encontrado cámaras',
    description: 'La plataforma ha detectado que no se encuentran cámaras a utilizar y es necesario para la teleconsulta.',
    ref_url: null,
  },

  'PERM_CAM_PERMISSION_DENIED_SYSTEM': {
    title: 'El sistema ha bloqueado el uso de la cámara',
    description: 'Hemos detectado que el sistema ha bloqueado el uso de la cámara.',
    ref_url: '/solucion/permiso-camara-bloqueado-por-sistema',
  },




  


  //VERIFICACION DE CAMARA
  'CAM_ACCESS_READABLE': {
    title: 'No se puede acceder a la cámara',
    description: 'La plataforma ha intentado acceder a la cámara pero no se ha logrado, usualmente esto ocurre porque otra aplicación la está utilizando. Cierra aplicaciones como Skype, Zoom o Teams.',
    ref_url: null,
  },

  'CAM_ACCESS_GENERAL': {
    title: 'No se ha logrado inicializar la cámara seleccionada',
    description: 'La plataforma ha intentado inicializar la cámara pero no se ha logrado, ¿Estás seguro de que la cámara está conectada?, ¿La cámara funciona para otras aplicaciones?. Es necesario de que la cámara no este siendo ocupada por otros programas como Teams / Skype o Zoom.',
    ref_url: null,
  },

  //VERIFICACION DE MICROFONO
  'MIC_ACCESS_READABLE': {
    title: 'No se puede acceder al Micrófono',
    description: 'La plataforma ha intentado acceder al micrófono pero no se ha logrado, usualmente esto ocurre porque otra aplicación la está utilizando.',
    ref_url: null,
  },

  'MIC_ACCESS_GENERAL': {
    title: 'No se ha logrado inicializar el micrófono seleccionado',
    description: 'La plataforma ha intentado inicializar el micrófono pero no se ha logrado, ¿Estás seguro de que el micrófono está conectado?, ¿El micrófono funciona para otras aplicaciones?.',
    ref_url: null,
  },


};