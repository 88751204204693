

export const useCustomers = ({ detail, title, billing}) => {
  

  detail.nombrePaciente = title;
  detail.email          = billing.email || "";
  detail.phone          = billing.phone || "";
  detail.region         = billing.state || billing.billing_state2 || "";
  detail.ciudad         = billing.city || "";
  detail.calle          = (billing.address_1 || "") + (billing.address_2 || "");
  
  detail = billing;

  return { title, detail};
  
}
