import React, { useState, useEffect } from 'react';
import queryString from "query-string";
import vattentionApi from "../../services/vattentions";
import findPatientAgoraChanel from "../../teleconsulta/utils/findPatientAgoraChanel";
import agoraApi from "../../services/agora";

export default function useTeleconsultaPaciente():
{
    obtenerUIUser: Function,
    getAgoraDynamicRtcToken: Function
}
{
    function obtenerUIUser (rutUsuario: string) {
        
        let numRut = rutUsuario.substring(0, (rutUsuario.length - 2)) + '-' + (Math.random()* (10 - 1));
        return numRut;

    };    
    
    /**
     * @function getAgoraDynamicRtcToken obtiene el token, channel name para utilziarlos en el join de agora.
     * @param dispatch recibe el dispatch del reducer
     */
    async function getAgoraDynamicRtcToken(dispatch: any) {
    
        let channelData = {
            channel: null,
            token: null,
            appid: null,
            rutPaciente: null,
            nombreMedico: null,
            especialidad: null,
            vattentionId: null,
            rutMedico:null,
        }
       
        await findPatientAgoraChanel()
        .then((response) => {
            console.log('response',response);
            dispatch({
                type: "setChannel",
                value: response.channelName,
            });

            dispatch({
                type: "setToken",
                value: response.key,
            });

            dispatch({
                type: "setEncryptionPassword",
                value: response.encryptionPassword,
            });

            dispatch({
                type: "setAppId",
                value: response.appID,
            });

            dispatch({
                type: "setNombreMedico",
                value: response.tokenRedgesamData.doctorName,
            });

            dispatch({
                type: "setDoctorRut",
                value: response.tokenRedgesamData.doctorRut,
            });

            dispatch({
                type: "setSpecialty",
                value: response.tokenRedgesamData.specialty,
            });
            

            dispatch({
                type: "setNombrePaciente",
                value: response.tokenRedgesamData.patientName,
            });
            

            dispatch({
                type: "setRutPaciente",
                value: response.tokenRedgesamData.patientRut,
            });
            

            dispatch({
                type: "setVattentionId",
                value: response._id,
            });


            if (response.codec) {
                dispatch({
                    type: "setCodec",
                    value: response.codec,
                });
            }

            channelData = {
                channel: response.channelName,
                token: response.key,
                appid: response.appID,
                rutPaciente: response.tokenRedgesamData.patientRut,
                rutMedico: response.tokenRedgesamData.doctorRut,
                nombreMedico: response.tokenRedgesamData.doctorName,
                especialidad: response.tokenRedgesamData.specialty,
                vattentionId: response._id
            }

            return channelData;

        })
        .catch((error) => {
            return {
                channel: null,
                token: null,
                appid: null,
                rutPaciente: null,
                nombreMedico: null,
                especialidad: null,
                vattentionId: null,
                rutMedico:null
            };
        });

        return channelData;
  }; 





  return {
    obtenerUIUser,
    getAgoraDynamicRtcToken
  };
}
