import React, { useEffect, useState } from "react";
import authentication from "../../services/authentication";
import { setNewToken } from "../../services/api";
import WaitingRoom from "./WaitingRoom";
import Home from "./Home";
import AudioVideoTest from "../../teleconsulta/verificacionPermisos/index";
import AppTeleconsultaPaciente from "../../teleconsulta/AppTeleconsultaPaciente";
import {setLogedPatient } from "../../services/api";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
const currentDate = new Date();

const Index = ({
  patient,
  prophandleEspera,
  propstopSearching,
  readyJoinRoom,
  setOpenModal,
  openModal,
   setIsOpen,
   setWaitingRoom,
   isWaitingRoom
}) => {
   
  const { setLogged } = useContext(UserContext);
  const [enEspera, setEnEspera] = useState(
    JSON.parse(localStorage.getItem("enSalaEspera")) || {
        esperando: false,
        fechaCreacion: null,
        dispositivosProbados: false,
        permisos: false,
        appointment:null
    }
  );

  useEffect(() => {
    localStorage.setItem("enSalaEspera", JSON.stringify(enEspera));
  }, [enEspera]);

  useEffect(() => {
    if (enEspera.esperando) {
      updateLocaStorage("esperando", true);
    }

    if (enEspera.dispositivosProbados) {
      updateLocaStorage("dispositivosProbados", true);
    }

    if (enEspera.temporal) {
      updateLocaStorage("temporal", true);
    }
  }, []);

  useEffect(() => { }, [enEspera]);

   const handleEnEspera = (citaId) => {
      updateLocaStorage("appointment", citaId);
      updateLocaStorage("fechaCreacion", currentDate);
      updateLocaStorage("esperando", true);
      updateLocaStorage("permisos", false);
   };

   const handlePermisos = () => {
      updateLocaStorage("permisos", true);
      setWaitingRoom(true);
   };

   const handleSalirEspera = () => {
      updateLocaStorage("fechaCreacion", null);
      updateLocaStorage("esperando", false);
      updateLocaStorage("permisos", false);
      updateLocaStorage("appointment", null);
      setWaitingRoom(false);
   };

   const handleSalirTeleConsulta = () => {
      updateLocaStorage("esperando", false);
      updateLocaStorage("permisos", false);
      updateLocaStorage("fechaCreacion", null)
      updateLocaStorage("temporal", false);
   }

  const updateLocaStorage = async (item, value) => {
    setEnEspera((prev) => ({
      ...prev,
      [item]: value,
    }));
  };

  const handleLogout = (e) => {
    e.preventDefault();
    authentication
      .logout()
      .then((response) => {
         setNewToken(null);
         setLogedPatient(null);
         setLogged(false);
         localStorage.clear();
         window.location.replace("/login"); //history.push('/paciente');
      })
       .catch((error) => {
         console.log(error);
         setNewToken(null);
          setLogedPatient(null);
          setLogged(false);
          localStorage.clear();
         window.location.replace("/login"); //history.push('/paciente');
      });
  };

   return (
      <div id="patientVersion" className="fullh">
         {readyJoinRoom ? (
            <AppTeleconsultaPaciente autoJoin={true} handleSalirTeleConsulta={handleSalirTeleConsulta} />
         ) : (
               <>
                  {!enEspera.esperando && !enEspera.permisos &&
                     <Home
                        handleEnEspera={handleEnEspera}
                        handleLogout={handleLogout}
                        setOpenModal={setOpenModal}
                        openModal={openModal}
                        setIsOpen={setIsOpen}
                     />
                  }

                  {enEspera.esperando && !enEspera.permisos &&
                     <AudioVideoTest handlePermisos={handlePermisos} />
                  }

                  {isWaitingRoom && enEspera.permisos &&
                     <WaitingRoom handleSalirEspera={handleSalirEspera} />
                  }

               </>
            )}
      </div>
   );
};

export default Index;
