// import axios from 'axios';
import API from "./api";
import URLS from "../URLS";

const patientServices = {
  // getPatients : function(){

  //   return API.axios({
  //     method: 'get',
  //     url: URLS.patients
  //   });

  // },

  getPatient: function (patientId) {
    return API.axios({
      method: "get",
      url: URLS.patients + "/" + patientId,
    });
  },

  getMedic: function (medicId) {
    return API.axios({
      method: "get",
      url: URLS.medics + "/" + medicId,
    });
  },

  getFichaPatient: function (patientRut, rutMedico) {
    return API.axios({
      method: "get",
      url: URLS.patients + "/ficha/" + patientRut + "/" + rutMedico
    });
  },

  updateFichaPatient: function (patientId, rutMedico, nombreMedico, texto) {
    return API.axios({
      method: "patch",
      url: URLS.patients + "/update/ficha",
      data: {
        patientId: patientId,
        texto: texto,
        rutMedico: rutMedico,
        nombreMedico: nombreMedico
      }
    })
  },

  post: function (patientData) {
    return API.axios({
      method: "post",
      url: URLS.patients,
      data: patientData,
    });
  },

  patch: function (patientId, data) {
    return API.axios({
      method: "patch",
      url: URLS.patients + "/" + patientId + "/update",
      data: data,
    });
  },

  //crea notificaciones
  addResources: function (patientId, notificationData) {
    return API.axios({
      method: "post",
      url: URLS.patients + "/" + patientId + "/resources",
      data: {
        reminder: notificationData,
      },
    });
  },

  getResources: function (patientId, params) {
    return API.axios({
      method: "get",
      url: URLS.patients + "/activity/dates/" + patientId,
      params: params,
    });
  },
  // TODO: comentado luego del merge con historia 428 
  // setNotificationCompleted: function(patientId, notificationId, data) {
  // 	return API.axios({
  // 		method: 'patch',
  // 		url: URLS.patients + '/' + patientId + '/notification/' + notificationId,
  // 		data: data
  // 	});
  // },
  setNotificationCompleted: function (reminderId, data) {
    return API.axios({
      method: "put",
      url: URLS.patients + "/activity/" + reminderId,
      data: data,
    });
  },
  getActivityUser: function (patientId) {
    return API.axios({
      method: 'get',
      url: URLS.monitorUser + '/getActivityUser?rut=' + patientId
    });
  },
  setLogActivityUser: function (patientId, activity) {
    return API.axios({
      method: 'post',
      url: URLS.monitorUser + '/updateActivityUser',
      data: {
        rut: patientId,
        activityUser: activity
      }
    });
  },

  patientCloseWindow: function (patient) {
    if (patient && patient.rut) {
      navigator.sendBeacon(URLS.baseURL + URLS.monitorUser + "/updateActivityUser", `{ "rut": "${patient.rut}", "activityUser" : "Ha cerrado la ventana / recargado la página" }`);
    }
  },

  // GetRemindersByDates
  getRemindersByDates: function (PatientId, startOfPeriod, endOfPeriod) {
    return API.axios({
      method: 'get',
      url: `${URLS.patients}${URLS.activity}${URLS.dates}/${PatientId}`,
      params: {
        startOfPeriod: startOfPeriod,
        endOfPeriod: endOfPeriod
      }
    });
  },
};

export default patientServices;
