// Opciones de Selectores
export const optionsType = [
    { value: "", label: "Seleccione", icon: "" },
    { value: "audio", label: "Audio", icon: "" },
    { value: "video", label: "Video", icon: "" },
    { value: "lectura", label: "Lectura", icon: "" },
    { value: "enlace-externo", label: "Link a Recurso", icon: "" },
    { value: "otros", label: "Sin enlace", icon: "" },
    // { value: "captivate", label: "Recursos Captivate", icon: "" },
];

export const optionsPeriodicity = [
    { value: "diaria", label: "Diaria" },
    //{ value: "semanal", label: "Semanal" },
];
export const optionsTypeLink = [
    { value: "", label: "Seleccione" },
    // { value: "https://app.redblu.cl/recursos/test1", label: "Test1", img: "https://app.redblu.cl/recursos/img/1.png" },
    // { value: "https://app.redblu.cl/recursos/test2", label: "Test2", img: "https://app.redblu.cl/recursos/img/2.png" },
];