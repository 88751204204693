import React from "react";

function Button({ onClick, active, name, icon, shortDescription, show }) {
  return (
    <button
      onClick={onClick}
      className={
        active ? "btn-activity-active sinhover_actividades text-light" : "text-muted"
      }
      disabled={show}
    >
      <span className="activity-name">
        {name}
        {icon ? <img src={icon} alt="" /> : null}
      </span>
      <span className="activity-descripcion">
        {shortDescription}
      </span>
    </button>
  );
}

export default Button;

// titulo = { name }
// desc = { shortDescription }
// icon = { active? IconCheckDone: null }
// onClick = { this.verRecurso(_id) }
// active = { this.state.recursoActivo == _id }
