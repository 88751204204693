import { ErrorOutline, ImageSearch, NavigateBefore } from '@material-ui/icons';
import React from 'react';
import {
   useHistory,
} from "react-router-dom";
import './ResourceNotFound.css';

const ResourceNotFound = () => {

  let history = useHistory();

  return(
    <>
      <div className="steped-component">
        <div className="header-short">
          <div className="stepped-nav">
            <div className="backbutton">
              <button onClick={() => history.goBack()}>
                <svg
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                >
                  {" "}
                  <path
                    fill="#000000"
                    d="M21,11.25H4.811l7.72-7.72c0.293-0.293,0.293-0.768,0-1.062c-0.293-0.293-0.768-0.293-1.061,0l-9,9 c-0.005,0.005-0.006,0.012-0.011,0.017c-0.063,0.066-0.116,0.142-0.151,0.227c-0.038,0.091-0.057,0.187-0.057,0.283 c0,0.001-0.001,0.002-0.001,0.004c0,0.011,0.006,0.02,0.006,0.031c0.003,0.087,0.018,0.173,0.051,0.255 c0.038,0.093,0.094,0.176,0.164,0.246l8.999,8.999c0.146,0.146,0.339,0.22,0.53,0.22s0.384-0.073,0.531-0.219 c0.293-0.293,0.293-0.768,0-1.062l-7.72-7.72H21c0.414,0,0.75-0.336,0.75-0.75S21.414,11.25,21,11.25z"
                  />{" "}
                </svg>
              </button>
            </div>
            <div className="stepped-title">
              <span>Sin resultados</span>
            </div>
          </div>
        </div>
      </div>
      <div className="resource-not-found text-center">
        <div className="row justify-content-center">
          <div className="col-md-10 col-xs-12">
            <div className="resource-not-found-container">
              <ImageSearch />
              <h1>Recurso no encontrado</h1>
              <h2>El recurso al cual estás intentando acceder no se ha encontrado, es posible de que haya sido <b>eliminado, renombrado o que nunca haya existido</b>.</h2>
            </div>
            <div>
              <button 
                onClick={() => history.goBack()} 
                className="btn-submit btn pl-4 pr-4 mt-4">
                  <NavigateBefore /> Volver
              </button>
            </div>
          </div>
        </div>
        <div className="end-page pt-5 pb-5"></div>
      </div>
    </>
  )
};

export default ResourceNotFound;