import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import HeaderPruebas from './components/header/HeaderPruebas';
import Loading from '../../components/Loading';
import NotFound from '../../Pages/NotFound/NotFound';
import RevisionAgenda from '../components/revisionAgenda/RevisionAgenda';
import TestDevice from '../Assistance/TestDevice/TestDevice';
import citasService from '../services/Citas';
import medicsService from '../../services/medics';
import { WebIndexPruebas } from './WebIndexPruebas';
import { handleLogout } from '../hooks/useLogout';
import { inicializaSocket } from '../../services/socket';


const ProfesionalesPruebas = () => {

  const history = useHistory();
  // const { isLogged, user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCitas, setIsLoadingCitas] = useState(false);
  const [citas, setCitas] = useState([]);
  const [userId, setUserId] = useState(null);
  const [profesionales, setProfesionales] = useState([]);
  const [user, setUser] = useState(false);
  const [isVisibleSelect, setIsVisibleSelect] = useState(false);

  useEffect(
    () => {

      const initSocket = () => {
        inicializaSocket({
          room: user.rut,
          rut: user.rut,
          name: `${user.firstname} ${user.lastname}`,
        });

      };
      user && initSocket()
    },
    [user, user.firstname, user.lastname, user.rut],
  )

  useEffect(() => {
    document.body.classList.add('vista-profesional');
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setIsLoadingCitas(true);

    const getAppointments = async () => {
      try {
        const response = await citasService.getCitas({ id: userId })
        if (response) {
          setCitas(response)
          setIsLoadingCitas(false);
        };
        setIsLoadingCitas(false);
      } catch (error) {
        setCitas([]);
        setIsLoading(false);
        setIsLoadingCitas(false);
      }
    };
    getAppointments();
    setIsLoading(false);



  }, [userId]);

  useEffect(() => {
    setIsLoading(true);
    const getMedics = async () => {
      try {
        const response = await medicsService.getMedicsAll()
     
        if (response.status === 200) {
          if (Array.isArray(response.data)) {
            setProfesionales(response.data);
            setIsVisibleSelect(true);
            setIsLoading(false);
          } else {
            setIsVisibleSelect(false);
            setProfesionales([]);
            setIsLoading(false);
            return;
          }
          
        };
      } catch (error) {
        setProfesionales([]);
        setIsVisibleSelect(false);
        setIsLoading(false);
      }
    };
    getMedics();
  }, []);


  return (
    <>
      {isLoading ? (
        <div className="fullh movile-app">
          <Loading />
        </div>
      ) : (
        <>
          <header>
            {user &&
              <HeaderPruebas
                psicologo={user}
              />
            }
          </header>
            {isVisibleSelect ?
              <RevisionAgenda
                psicologo={user}
                profesionales={profesionales}
                setUser={setUser}
                setUserId={setUserId}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setIsLoadingCitas={setIsLoadingCitas}

              />
              :
              <>
                <div className="abs-center flex-wrap">
                  <p className="pt-3">Servicio no disponible</p>
                  <button
                    className="btn btn-submit btn-md ml-2"
                    onClick={() => { window.location.replace('/profesional') }}>
                    Volver
                  </button>
                </div>
              </>

            }
          <Switch>
            <Route
              exact
              path={"/listado-profesionales/probar-teleconsulta"}>
              <TestDevice />
            </Route>
            {isLoadingCitas ? (
              <div className="fullh movile-app">
                <Loading />
              </div>
            ) : (
              <Route
                exact
                path={"/listado-profesionales"}
              >

                <WebIndexPruebas
                  psicologo={user}
                  handleLogout={handleLogout}
                  dummyDataCalendar={citas}
                  citas={citas}
                  history={history}
                  isLoadingServiceIndex={isLoading}
                />

                {citas.message && (
                  <div className="container">
                    <h1 className="abs-center">{citas.message}</h1>
                  </div>
                )
                }
              </Route>

            )
            }

            <Route path="*" component={NotFound} />
          </Switch>
        </>
      )}
    </>
  );
};

export default ProfesionalesPruebas;
