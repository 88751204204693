import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import iconCompleta from './../../assets/images/iconos/icon-historia-completada.svg';
import AudioActividad from './categoria/AudioActividad';
import VideoActividad from './categoria/VideoActividad';
import EnlaceActividad from './categoria/EnlaceActividad';
import LecturaActividad from './categoria/LecturaActividad';
import CaptivateActividad from './categoria/CaptivateActividad';
import { DefaultActividad } from './categoria/DefaultActividad';
import StarRating from './StarRating';
import { useForm } from '../../Profesional/hooks';
// Servicio
import commentaries from '../../services/commentaries';

//Analytics
import ReactGA from 'react-ga';
import patientsService from '../../services/patients';

const customModalStyles = {
  content: {
    width: '70%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const Actividad = ({
  categoria,
  nombre,
  objetivo,
  instrucciones,
  url,
  urlDescripcion,
  setSelectedReminder,
  esCompletada,
  recordatorioId,
  dayId,
  handleClickSi,
  recordatorio,
  patientId
}) => {

  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);
  const [rating, setRating] = useState(0);
  const [nombreMedico, setNombreMedico] = useState('');
  const [apellidoMedico, setApellidoMedico] = useState('');
  const [especialidadMedico, setEspecialidadMedico] = useState('');

  // const [remminderId] = useState(recordatorio.reminderId);
  // const [resourceId] = useState(recordatorio.resource._id);
  //   const [comentariosGuardados, setComentariosGuardados] = useState([]);
  // Utilización de formValues para capturar el texto
  const [formValues, handleInputChange,] = useForm({
    comentario: "",
  });

  const { comentario } = formValues;
  // Objeto que tiene la data de comentarios y rating
  const data = {
    patientId: patientId,
    remminderId: recordatorio.reminderId,
    resourceId: recordatorio.resource._id,
    completedDay: new Date(),
    valoration: rating,
    commentarie: comentario,
  };

  // Efecto para rating
  useEffect(() => {
    setRating(0);
    // console.log(recordatorio)
  }, [patientId, recordatorio]);

  useEffect(() => {
    patientsService.getMedic(recordatorio.medicUser).then(({ data }) => {
      try {
        setNombreMedico(data.firstname);
        setApellidoMedico(data.lastname);
        setEspecialidadMedico(data.specialty);
      } catch (error) {
        setNombreMedico('');
        setApellidoMedico('');
        setEspecialidadMedico('');
      }

    });
  }, [recordatorio.medicUser]);

  // let nombreMedico = '';
  // if (recordatorio.medicUser && recordatorio.medicUser.firstname) {
  //   // nombreMedico = recordatorio.medicUser.firstname + " " + (recordatorio.medicUser.specialty && recordatorio.medicUser.specialty !== "undefined" ? recordatorio.medicUser.specialty : "")
  // }
  const handleModal = () => {
    setAlertModalIsOpen(true);
  };

  const handleCompleted = () => {
    setIsCompleted(true);
      commentaries.createCommentaries(data);
    //console.log(recordatorio); // TODO:descomentar
    handleClickSi(recordatorioId, dayId);
  };

  // Función para rating
  const handleSetRating = (r) => {
    setRating(r);
    ReactGA.event({category:"Rating Actividad", action:r.toString()})
  };

  // Se realiza agrega filtro de categorias
  const getCategoria = () => {
    let varCategoria ='';
    if (categoria.length > 0 ) {
      varCategoria = categoria.toLowerCase().trim();

      if (varCategoria === 'youtube' || varCategoria === 'video') {
        return <VideoActividad url = { url } urlDescripcion = { urlDescripcion } />
      } 
      if (varCategoria === 'video') {
        return <EnlaceActividad url={url} urlDescripcion={urlDescripcion} />;
      }
      if (varCategoria === 'enlace-externo') {
        return <EnlaceActividad url={url} urlDescripcion={urlDescripcion} />;
      }
      if (varCategoria === 'lectura' || varCategoria === 'pdf') {
        return <LecturaActividad url={url} urlDescripcion={urlDescripcion} subtipo={varCategoria} />;
      }
      if (varCategoria === 'audio') {
         return <AudioActividad url={url} urlDescripcion={urlDescripcion} />;
      }
      if (varCategoria === 'captivate') {
        return <CaptivateActividad url={url} urlDescripcion={urlDescripcion} />;
      }
      else{
        return <DefaultActividad url={url} urlDescripcion={urlDescripcion} />;
      }
     
    } else {
      // console.log('No tiene categoria asignada');
      varCategoria = '';
      return <DefaultActividad url={url} urlDescripcion={urlDescripcion} />;
    }
  };

  return (
    <div className="overflow-hidden">
      <div className="steped-component">
        <div className="header-short">
          <div className="stepped-nav">
            <div className="backbutton">
              <button onClick={() => setSelectedReminder(null)}>
                <svg
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                >
                  {" "}
                  <path
                    fill="#000000"
                    d="M21,11.25H4.811l7.72-7.72c0.293-0.293,0.293-0.768,0-1.062c-0.293-0.293-0.768-0.293-1.061,0l-9,9 c-0.005,0.005-0.006,0.012-0.011,0.017c-0.063,0.066-0.116,0.142-0.151,0.227c-0.038,0.091-0.057,0.187-0.057,0.283 c0,0.001-0.001,0.002-0.001,0.004c0,0.011,0.006,0.02,0.006,0.031c0.003,0.087,0.018,0.173,0.051,0.255 c0.038,0.093,0.094,0.176,0.164,0.246l8.999,8.999c0.146,0.146,0.339,0.22,0.53,0.22s0.384-0.073,0.531-0.219 c0.293-0.293,0.293-0.768,0-1.062l-7.72-7.72H21c0.414,0,0.75-0.336,0.75-0.75S21.414,11.25,21,11.25z"
                  />{" "}
                </svg>
              </button>
            </div>
            <div className="stepped-title">
              <span>Detalle de actividad</span>
            </div>
          </div>
        </div>
      </div>

      {getCategoria()}
      <div className="detalle-actividad relative">
        <div className="recurso-view p-0">
          {nombre && (
            <div className="row p-3 align-items-end">
              <div className="col-8">
                {esCompletada ? (
                  <span className="pending-status">
                    <img src={iconCompleta} width="20" alt="" /> Completada
                  </span>
                ) : (
                  <span className="pending-status">Pendiente</span>
                )}
                <h1 className="mb-0">{nombre}</h1>
              </div>
              <div className="col-4 text-center">
                <div>
                  <span className="pending-status">ASIGNADA</span>
                </div>
                {/*<span>{horaAsignada.format("HH:mm A")}</span>*/}
              </div>
              <div className="col-12">
                <span className="pending-status">
                   Asignado por <br />
                  <span className="font-weight-bold">{nombreMedico} {apellidoMedico} - {especialidadMedico}</span>
                </span>
              </div>
            </div>
          )}

          {instrucciones && (
            <div className="view-description border-top p-3">
              <h3 className="mb-1">Instrucciones:</h3>
              <p>{instrucciones}</p>
            </div>
          )}

          {objetivo && (
            <div className="view-description p-3 actividad-cta">
              <h3 className="mb-1">Objetivo de la actividad:</h3>
              <p>{objetivo}</p>
            </div>
          )}

          <Modal
            isOpen={alertModalIsOpen}
            style={customModalStyles}
            onRequestClose={() => setAlertModalIsOpen(false)}
          >
            <div className="alertModal">
              <h1>{alertModalText}</h1>
              <div className="row align-items-start p-3 actividad-cta p-3 view-description border-0">
                <div className="col-12 pr-0">
                  <h3 className="mb-1 ">Dejanos tu comentario:</h3>
                  <p>¿Cuéntanos que te pareció esta experiencia?</p>
                  <input
                    name="comentario"
                    type="text"
                    placeholder="ingrese comentario ..."
                    autoComplete="off"
                    value={comentario}
                    onChange={handleInputChange}
                  />

                  <label className="text-center mt-2">Valoración</label>
                  <StarRating onSetRating={handleSetRating} value={rating} />
                </div>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-submit btn-lg"
                  onClick={() => {
                    setAlertModalIsOpen(false);
                    handleCompleted();
                  }}
                >
                  Enviar
                </button>
              </div>
            </div>
          </Modal>
          <div className="row align-items-start p-3 actividad-cta">
            <div className="col-12 text-center">
              {!esCompletada ? (
                <>
                  <button
                    onClick={handleModal}
                    className="btn btn-submit btn-full-width"
                  >
                    COMPLETAR <img src={iconCompleta} width="20" alt="" />
                  </button>
                  <small className="pt-1">
                    Presiona el botón para marcar que has completado la
                    actividad
                  </small>
                </>
              ) : (
                  <>
                  <button className="btn btn-submit btn-gray btn-full-width no-pointer">
                    COMPLETADO <img src={iconCompleta} width="20" alt="" />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="end-page pt-5 pb-5"></div>

        {isCompleted && (
          <div className="completedAnimation">
            <div className="p-5 animated jackInTheBox">
              <img src={iconCompleta} width="120" alt="" />
              <h2 className="title">¡Muy bien!</h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Actividad;
