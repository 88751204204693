// import axios from 'axios';
import API from './api';
import URLS from '../URLS';

const subscriptionsServices = {

  unsubscribeEmailNotification: function (params) {
    return API.axios({
       method: "put",
       url: URLS.subscriptions + "/darseBaja",
       data: params,
    });
 },

  subscribeNotification : function( subscription ){
    return API.axios({
      method: 'post',
      url: URLS.subscriptions,
      data: {
        subscription : JSON.stringify(subscription)
      }
    });
  },

  unSubscribeNotification : function( subscription ){
    return API.axios({
      method: 'delete',
      url: URLS.subscriptions,
      data: {
        subscription : JSON.stringify(subscription)
      }
    });
  },
} 

export default subscriptionsServices;