import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import imgIconNextWhite from "./../../assets/images/iconos/icono-white-next.svg";
import { QueueMusic, PlayCircleOutline } from "@material-ui/icons";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ResourceServices from '../../services/resources';

const RecomendedResource = () => {
   const history = useHistory();
   const urlRedBLU = process.env.REACT_APP_URL_REDBLU || '';
   const [resource, setResource] = useState({
      _id: '',
      bgImage: '',
      name: '',
      shortDescription: ''
   });

   useEffect(() => {
      ResourceServices.getRandomResource()
         .then((res) => {
            setResource(res.data);
         })
         .catch((error) => {
            console.log(error);
         })
   }, [urlRedBLU]);

   const goToRecursos = () => {
      history.push('/paciente/recursos');
   }

   const goToRecurso = () => {
      history.push('/paciente/recursos/' + resource._id);
   }

   return (
      <div className="citasProximas mb-3 bg-white rounded mt-4 px-3">
       <div data-tour="recrec">
         <div className="row littleTitle">
            <div className="col-12 text-center">
               <h4>TE RECOMENDAMOS</h4>
            </div>
         </div>

         <div  className="row align-items-center mt-4 align-items-stretch">
            <div className="col-sm-6 col-12 card-recurso mb-3 mb-sm-0">
               <div className="rounded-image">
                  <a
                     onClick={goToRecurso}
                     style={{
                        backgroundImage: 'url(' + resource.bgImage + ')'
                     }}>
                     <span className="overlay-icon">
                        <PlayCircleOutline />
                     </span>
                     <span className="image-overlay bg-shadow-inverted"></span>
                  </a>
               </div>
            </div>
            <div className="col-sm-6 col-12 detalle-recurso">
               <h2>{resource.name}</h2>
               <p>{resource.shortDescription}</p>
               <button
                  onClick={goToRecurso}
                  className="btn-submit">
                  Explorar
                  <img
                     className="pl-2"
                     width="22"
                     src={imgIconNextWhite}
                     alt="Ingresar"
                  />
               </button>
            </div>
            </div>
         </div>

         <div className="row mt-4">
            <div className="card-bar">
               <div data-tour="recall" className="col-12">
                  <div className="py-2">
                     <div className="row align-items-center">
                        <div className="col-sm-8 col-12 pb-3 pb-sm-0">
                           <p>Descubre los recursos que hemos preparado para tí</p>
                        </div>
                        <div className="col-sm-4 col-12 pl-sm-0 pl-3">
                           <button
                              className="btn-submit full-width"
                              onClick={goToRecursos}>
                              Conocer recursos
                              <QueueMusic />
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="row mt-4">
            <div className="card-bar">
               <div data-tour="agenhora" className="col-12">
                  <div className="py-2">
                     <div className="row align-items-center">
                        <div className="col-sm-8 col-12 pb-3 pb-sm-0">
                           <p>¿Necesitas agendar una hora?. Puedes elegir al profesional y horario que más te acomode</p>
                        </div>
                        <div className="col-sm-4 col-12 pl-sm-0 pl-3">
                           <Link
                              className="btn btn-submit  full-width p-2"
                              to={{ pathname: urlRedBLU }}
                              target="_blank">
                              Agendar una hora
                              <EventAvailableIcon />
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default RecomendedResource;