import React, { useEffect, useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { getSocketClient, isSocketEnable } from '../../services/socket';
import { DateRange, Person, Videocam, QueueMusic } from '@material-ui/icons';

function ItemNavCuenta({ label, to, Icon, activeOnlyWhenExact, forceActive, showGlobe, count, children }) {
   let match = useRouteMatch({
      path: to,
      exact: activeOnlyWhenExact,
   });

   if (forceActive) {
      match = forceActive === to;
   }

   return (
      <div className="navitem col">
         <Link to={to}>
            <button className={match ? "active" : ""}>
               {children}
               <span>{label}</span>
               {showGlobe &&
                  <div className="newMessageIndicator animated heartBeat delay-1s p-0">
                     <span className="text-light p-1 pb-2">{count}</span>
                  </div>
               }
            </button>
         </Link>
      </div>
   );
}

const Navigation = ({ forceActive, data }) => {
   const [showGlobe, setShowGlobe] = useState(false);
   const [messagesCount, setMessagesCount] = useState([]);

   useEffect(() => {
      checkNotificacion();
      if (isSocketEnable) {
         listenSocket();
      }
   }, [isSocketEnable, data]);

   const listenSocket = () => {
      let socket = getSocketClient();

      if (socket && data) {
         socket.on("notification", ({ type, text }) => {

            setShowGlobe(true);
            if (text.value === "true") {
               setMessagesCount(data.length + 1);
            } else {
               setMessagesCount(text.value);
            }

            localStorage.setItem("notificaciones", JSON.stringify({ notificacionActividades: true, count: data.length }));
         });
      }
   };
   const checkNotificacion = () => {
      let data = JSON.parse(localStorage.getItem("notificaciones"));
      if (data && messagesCount) {
         setShowGlobe(true);
      }
   };

   return (
      <div data-tour="nav" className="mainnav row">
         <ItemNavCuenta
            label="ACTIVIDADES"
            to="/paciente/actividades"
            activeOnlyWhenExact={true}
            forceActive={forceActive}
            showGlobe={showGlobe}
            count={messagesCount}
         ><span data-tour="act" ><DateRange /></span></ItemNavCuenta>

         <ItemNavCuenta
            label="TELECONSULTA"
            Icon={<Videocam />}
            to="/paciente"
            activeOnlyWhenExact={true}
            forceActive={forceActive}
            showGlobe={false}
         ><span data-tour="home" ><Videocam /></span></ItemNavCuenta>

         <ItemNavCuenta
            label="RECURSOS"
            Icon={<QueueMusic />}
            to="/paciente/recursos"
            forceActive={forceActive}
            showGlobe={false}
         ><span data-tour="rec" ><QueueMusic /></span></ItemNavCuenta>

         <ItemNavCuenta
            label="MI CUENTA"
            Icon={<Person />}
            to="/paciente/editar"
            forceActive={forceActive}
            showGlobe={false}
         ><span data-tour="micuenta" ><Person /></span></ItemNavCuenta>
      </div>
   );
};

export default Navigation;
