import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Carousel } from 'react-responsive-carousel';
import { frasesMotivadoras } from "../../Profesional/helpers/frasesMotivadoras";

export const ResumenSemanal = ({
  notificationsWeek,
  fechaIniWeek,
  fechaFinWeek,
  onChangeReminder,
  messagesCount
}) => {
  const [arregloDias, setArregloDias] = useState([]);
  // const [diaIni, setDiaIni] = useState(fechaIniWeek);
  // const [diaFin, setDiaFin] = useState(fechaFinWeek);
  const [totalAct, setTotalAct] = useState(notificationsWeek.length);
  const [porceCompletado, setPorceCompletado] = useState(0);
  let cantidadCompletadas = 0;

  useEffect(() => {
    setArregloDias([...notificationsWeek]);
    //  console.log(notificationsWeek);
    notificationsWeek.map((res) => {
      if (res.completed) {
        cantidadCompletadas++;
      }
      return null;
    });

    // console.log(diaIni);
    let porcentaje = 0;
    if (notificationsWeek.length <= 0) {
      porcentaje = 0;
    } else {
      porcentaje = (cantidadCompletadas * 100) / notificationsWeek.length;
    }
    setPorceCompletado(porcentaje.toFixed());
    
  }, [notificationsWeek, fechaIniWeek, fechaFinWeek, cantidadCompletadas, notificationsWeek.length, messagesCount]);

  // console.log(porceCompletado);

  return (
    <>
      <div className="card animated fadeIn pt-3 p-0 m-0 box_resumen_semanal">
        <div className="text-center p-0 mt-0 mb-2">
          <h2 className="recurso-title">Resumen Semanal </h2>
          <small>
            Actividades completadas según últimos 7 días es de un{" "}
            <span className="text-success"> {porceCompletado}%</span>
          </small>
        </div>
        <div
          className="table-responsive pb-3"
          style={{ pointerEvents: "none" }}
        >
          <table className="table table-borderer p-0 mb-0">
            <thead className="">
              <tr className="bg-tele text-light text-center small p-2 ">
                <th className="align-middle w-1 p-1">N°</th>
                <th className="align-middle w-25">Fecha de asignación</th>
                <th className="align-middle w-25">Nombre</th>
                <th className="align-middle w-24">Estado</th>
                <th className="align-middle w-25">Fecha de realización</th>
              </tr>
            </thead>
          </table>
        </div>
        <PerfectScrollbar>
          <table className="table table-bordered table-striped ">
            <tbody className="text-center align-middle small ">
              {arregloDias.length > 0 &&
                arregloDias.map((d, i) => {
                  // Se agrega validación de estado de asignaciones segun fecha
                  let today = moment();
                  let estado = "";
                  if (!d.completed && !today.isAfter(moment.utc(d.dateTime), 'd')) {
                    estado = "PENDIENTE"
                  } else if (!d.completed) {
                    estado = "-"
                  } else {
                    estado = "COMPLETADA"
                  }

                  //   console.log(moment(d.days));
                  return (
                    <tr key={i} className=" text-center p-2">
                      <td className="align-middle w-1 p-1">{i + 1}</td>
                      <td className="align-middle w-25">
                        {moment(d.assignmentDate).format("L")}
                      </td>
                      <td className="align-middle w-25 ">
                        {d.data.activityName}
                      </td>
                      <td
                        className={
                          estado === "COMPLETADA" ? "text-success align-middle w-25 " :
                          estado === "-" ? "text-dark align-middle w-25" :
                              "text-info align-middle w-25  "
                        }
                      >
                        {estado}
                      </td>
                      <td className="align-middle w-25 ">
                        {d.completed ? moment(d.updatedAt).format("L") : "-"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </PerfectScrollbar>

        <div className="text-center p-2">
          <label className="text-tele">
            Total de actividades asignadas: {totalAct}
          </label>
        </div>
      </div>
    </>
  );
};
