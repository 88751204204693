import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import img_next from "../../teleconsulta/assets/images/icono-white-next.svg";
import notiSoundOgg from "../../teleconsulta/assets/sounds/goes-without-saying.ogg";
import notiSoundMp3 from "../../teleconsulta/assets/sounds/goes-without-saying.mp3";
import findPatientAgoraChanel from "../../teleconsulta/utils/findPatientAgoraChanel";
import RollbarErrorTracking from '../../components/Logger';

let timeOut;
let timeLogger;

const REACT_APP_REQUEST_VATTENTION_INTERVAL =
process.env.REACT_APP_REQUEST_VATTENTION_INTERVAL || 10000;

const BuscarConsultaMedica = ({
   isLoged,
   tryAutologin,
   location,
   stopSearching,
   dispositivosProbados,
   handleEntrarTeleConsulta
}) => {
   const history = useHistory();
   const isMounted = useRef(true); // Utilizado para verificar si el componente está montado o no
   const [nombreMedico, setNombreMedico] = useState("");
   const [rutMedico, setRutMedico] = useState("");
   const [especialidad, setEspecialidad] = useState("");
   const [modalNoInternet, setModalNoInternet] = useState(false);
   const [pacientJoin, setPacientJoin] = useState(null);
   const [isShowModal, setIsShowModal] = useState(false);

   useEffect(() => {
      if (
         !isLoged &&
         location.pathname !== "/paciente" &&
         location.pathname !== "/paciente/teleconsulta"
      ) {
         history.push("/paciente");
      }

      return () => {
         isMounted.current = false;
      };
   }, [history, isLoged, location.pathname]);

   const showModal = (e) => {
      try {
         playAudio();
      } catch (error) {
         isMounted.current = false;
      }
   };

   const findAgoraChanel = () => {
      try {
         findPatientAgoraChanel()
            .then((response) => {
               setNombreMedico(response.tokenRedgesamData.doctorName);
               setRutMedico(response.tokenRedgesamData.doctorRut);
               setEspecialidad(response.tokenRedgesamData.specialty);
               setIsShowModal(true);
               showModal();
               clearTimeout(timeOut);
            })
            .catch((error) => {
               if (error === "Error: Network Error") {
                  setModalNoInternet(true);
               } else {
                  setModalNoInternet(false);
               }

               timeOut = setTimeout(
                  findAgoraChanel,
                  REACT_APP_REQUEST_VATTENTION_INTERVAL
               );
            });
      } catch (error) {
         console.log(error);
      }

   };

   useEffect(() => {

      if( !pacientJoin ){
         findAgoraChanel()
      }

      timeOut = setTimeout(
         findAgoraChanel,
         REACT_APP_REQUEST_VATTENTION_INTERVAL
      );

      timeLogger = setTimeout(
         runningLogger,
         120000
      );

      return () => { 
         clearTimeout(timeOut);
         pauseAudio();
         clearTimeout(timeOut);
         isMounted.current = false;

      };
   }, []);

   const runningLogger = () => {
      RollbarErrorTracking.logErrorDebug("BUSCACONSULTA");
   };

   const handleClick = () => {
      pauseAudio();
      setPacientJoin(true);
      setIsShowModal(false);
      clearInterval(timeOut);
      handleEntrarTeleConsulta();
   };

   useEffect(() => {
      if (pacientJoin === false) {
         setTimeout(() => {
            if (document.getElementById("notificationAudio")) {
               document.getElementById("notificationAudio").pause();
            }
         }, 30000);
      }
      return () => {
         clearTimeout(timeOut);
         isMounted.current = false;
      };
   }, [pacientJoin]);


   const playAudio = async () => {
      try {
         await document.getElementById("notificationAudio").play();
         setPacientJoin(false);
      } catch (error) {
         console.error(error);
      }
   }


   const pauseAudio = () => {
      try {
         document.getElementById("notificationAudio").pause();
      } catch (error) {
         console.error(error);
      }
   }


   return (

      <>
         {modalNoInternet &&
            <div className="ownModal modalBackground animated fadeIn">
               <div className="ownModal-wrapper">
                  <div className="modal-dialog modal-dialog-centered atencion-ahora modal-warning" role="document">
                     <div className="modal-content">
                        <div className="modal-body">
                           <h3 className="text-center">
                              ¡Se ha perdido la conexión!
                        </h3>
                           <span>
                              Haz perdido la conexión, puede ser que en la red en la
                              cúal estás conectado se encuentre bloqueada. Cambiate de
                              red y espera unos segundos antes de recargar esta
                              página.
                        </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         }
         {isShowModal &&
            <div className="ownModal modalBackground animated fadeIn">
               <div className="ownModal-wrapper">
                  <div className="modal-dialog modal-dialog-centered atencion-ahora" role="document">
                     <div className="modal-content">
                        <div className="modal-body">
                           <h3 className="text-center">Tienes una video atención ahora con<br />
                              <strong>{nombreMedico}</strong>
                           </h3>
                           <div className="row mb-2">
                              <div className="col-12">
                                 <div><span>RUT: <strong>{rutMedico}</strong></span></div>
                                 <div><span>Especialidad: <strong>{especialidad}</strong></span></div>
                              </div>
                           </div>
                           <span>Para una mejor experiencia y estabilidad en la conexión te recomendamos que te conectes desde una red WIFI.</span>
                        </div>
                        <div className="modal-footer text-center">
                           <button
                              type="button"
                              className="btn btn-success animated pulse infinite slow"
                              onClick={handleClick}
                           >
                              <strong>Ingresar a la consulta </strong>
                              <img width="18" src={img_next} alt="Ingresar" />
                           </button>
                        </div>
                     </div>
                  </div>
               </div>

               <audio id="notificationAudio" loop>
                  <source src={notiSoundOgg} type="audio/ogg" />
                  <source src={notiSoundMp3} type="audio/mpeg" />
                  Your browser does not support the audio element.
               </audio>
            </div>
         }

         <div className="searching">
            <div className="searching-container">
               <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
               Esperando al profesional...
            </div>
         </div>

         <audio id="notificationAudio" loop>
            <source src={notiSoundOgg} type="audio/ogg" />
            <source src={notiSoundMp3} type="audio/mpeg" />
            Tu navegador no soporta el elemento de audio
         </audio>
      </>
   )
};

BuscarConsultaMedica.propTypes = {};

BuscarConsultaMedica.defaultProps = {};

export default BuscarConsultaMedica;
