import React from 'react';
import DatePicker from 'react-datepicker';
// images
import IconPlus from "../../assets/images/icon4.svg";
// Styles
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

export const OpcionesRecurso = (props) => {
   const {
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      // time,
      // onChangeHour,
      handleInputChange,
      // repeat,
      // errors,
      periodicity,
     optionsPeriodicity,
      fechaActual
      // row
   } = props
//  const maxRepeat = 4;
//  const minRepeat = 0;
   return (
     <div className="col-12">
       <div className="row mt-3 border-top pt-3">
        <div className="col-md-6 col-sm-6 col-12 mb-3">
          <label className="footer-tele text-left">(*)Fecha Inicial</label>
          <DatePicker
            selected={startDate}
            name="fechaInicial"
             onChange={(date) => {
               let dateIni = moment(date).toDate();
               setStartDate(dateIni);
               let dateFin = moment(date).add(1, 'days').toDate();
               setEndDate(dateFin);
             }}
            dateFormat="dd-MM-yyyy"
            selectsStart
            startDate={moment(fechaActual).toDate()}
             minDate={moment(fechaActual).toDate()}
             maxDate={moment(fechaActual).add(14,"days").toDate()}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-12 mb-3">
          <label className="footer-tele text-left">(*)Fecha de Termino</label>
          <DatePicker
            selected={endDate}
            name="fechaFinal"
             onChange={(date) => {
               let dateFinEnd = moment(date).toDate();
               setEndDate(dateFinEnd);
            }}
            dateFormat="dd-MM-yyyy"
            selectsEnd
             startDate={moment(endDate).toDate()}
            // endDate={endDate}
             minDate={moment(startDate).add(1, 'days').toDate()}
            // maxDate={addDays(startDate, 14)}
          />
        </div>
        
        <div className="col-md-6 col-sm-6 col-6 mb-3">
          <label className="footer-tele pl-0 ">(*)Periodicidad</label>
          <select
            className={"topfilters form-control p-0 "}
            name="periodicity"
            onChange={handleInputChange}
            value={periodicity}
          >
            {optionsPeriodicity.map((opt, idx) => {
              return (
                <option key={idx} value={opt.value}>
                  {opt.label}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mt-4 col-md-6 col-sm-6 col-6">
          <button className={"btn-rounded"} type="submit">
            <span>
              <img src={IconPlus} alt="" />
            </span>{" "}
            Añadir
          </button>
        </div>
       </div>
     </div>     
   );
}
