import React, { useState, useEffect, useRef } from "react";
import Loading from "../../../../components/Loading";
import {
  NavigateNext,
  Check,
  Close,
  Replay
} from '@material-ui/icons';
import {
  Select,
  InputLabel,
  FormControl,
  MenuItem
} from '@material-ui/core';
import './VerificarMicrofono.css';
import AgoraRTC from 'agora-rtc-sdk-ng';
import MicrofonoAnimado from "../images/MicrofonoAnimado";
import MicrofonoDosAnimado from "../images/MicrofonoDosAnimado";
import ErrorList from "../Error/ErrorList";

const VerificarMicrofono = ({ setStep, onSelectedDevice }) => {
  const container = useRef(null);
  const [startTestingDevice, setStartTestingDevice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [microphoneDeviceList, setMicrophoneDeviceList] = useState(false);
  const [audioLevel, setAudioLevel] = useState(0);
  const [deviceSelected, setDeviceSelected] = useState();
  const [localStream, setLocalStream] = useState(null);
  const [errors, setErrors] = useState([]);
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    if (startTestingDevice) {
      initTestDevice();
    }
  }, [deviceSelected]);

  useEffect(() => {
    if (!container.current) return;
    localStream && localStream.play(container.current);

    return () => {
      localStream && localStream.stop();
    };
  }, [container, localStream, isLoading]);

  const handleStartTesting = async () => {
    setStartTestingDevice(true);
    setShowHelp(false);
    await getDevices();
    await initTestDevice();
  };

  const getDevices = async () => {
    setErrors([]);

    try {
      const availableMicrophonesList = await AgoraRTC.getMicrophones();
      setMicrophoneDeviceList(availableMicrophonesList);
      if (availableMicrophonesList.length > 0) {
        setDeviceSelected(availableMicrophonesList[0]);
      }
    } catch (error) {
      setStartTestingDevice(false);
      if (error.message.includes("dismissed")) {
        setErrors((error) => [...error, "PERM_MIC_PERMISSION_DISMISSED"]);
      } else if (error.message.includes("denied")) {
        setErrors((error) => [...error, "PERM_MIC_PERMISSION_DENIED"]);
      } else if (error.message.includes("readable")) {
        setErrors((error) => [...error, "MIC_ACCESS_READABLE"]);
      } else {
        setErrors((error) => [...error, "MIC_ACCESS_GENERAL"]);
      }
    }
  }

  const initTestDevice = async () => {
    if (!deviceSelected) return;
    setIsLoading(true);
    setErrors([]);

    try {
      const audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
        microphoneId: deviceSelected.deviceId
      });
      setLocalStream(audioTrack);

      setInterval(() => {
        setAudioLevel(audioTrack.getVolumeLevel() * 5000);
      }, 500);

    } catch (error) {
      if (error.message.includes("dismissed")) {
        setErrors((error) => [...error, "PERM_MIC_PERMISSION_DISMISSED"]);
      } else if (error.message.includes("denied")) {
        setErrors((error) => [...error, "PERM_MIC_PERMISSION_DENIED"]);
      } else if (error.message.includes("readable")) {
        setErrors((error) => [...error, "MIC_ACCESS_READABLE"]);
      } else {
        setErrors((error) => [...error, "MIC_ACCESS_GENERAL"]);
      }
    }
    setIsLoading(false);
  };

  const handleChangeDeviceId = (e) => {
    e.preventDefault();
    setDeviceSelected(microphoneDeviceList.find(mic => mic.deviceId === e.target.value));
  };

  const microphoneWorking = async () => {
    onSelectedDevice("microphone", deviceSelected.label);
    await closeVideo();
    setStep(currentStep => currentStep + 1);
  };

  const microphoneNoWorking = () => {
    closeVideo();
    setShowHelp(true);
    setStartTestingDevice(false);
  };

  const closeVideo = async () => {
    await localStream.stop();
    await localStream.close();
    await setLocalStream(false);
    await timeOut(2000);
  };

  const timeOut = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (
    <>
      <div className="wizzard-heading">
        <span className="step-number">04</span>
        <h2 className="step-title">Micrófono</h2>
      </div>

      <div className="wizzard wizzard-audio-container mb-4">
        {isLoading &&
          <div className="row">
            <div className="col-12 mb-3">
              <Loading />
            </div>
          </div>
        }

        {startTestingDevice ?
          <div className="row">
            <div className="col-10 m-auto text-center">
              <h4>Dí unas palabras</h4>
              <p>para que la aplicación detecte que el micrófono está funcionando. Al hacerlo la barra de color verde debería agrandarse.</p>
            </div>
            <div className="col-12 m-auto pt-4 text-center">
              <MicrofonoDosAnimado />
            </div>
          </div>
          :
          <div className="row">
            <div className="col-10 m-auto text-center">
              <h4>Conecta tu micrófono</h4>
              <p>Antes de continuar te sugerimos que conectes el micrófono que vas a utilizar en la teleconsulta.</p>
            </div>
            <div className="col-12 m-auto pt-4 text-center">
              <MicrofonoAnimado />
            </div>
            <div className="col-12 mt-4 text-center">
              {(errors && errors.length > 0) || showHelp ?
                <button className="btn-submit" onClick={() => handleStartTesting()}>
                  Volver a intentar <Replay />
                </button>
                :
                <button
                  className="btn-submit"
                  onClick={() => handleStartTesting()}
                >
                  Ya lo hice, deseo continuar <NavigateNext />
                </button>
              }
            </div>
          </div>
        }

        {localStream && (
          <div className="row border-bottom mb-2">
            <div className="col-12 text-center">
              <div className="pb-4 pt-3">
                <span>Al hablar deberías escuchar el eco de tu voz</span>
              </div>
              <div className="pb-4 pl-4 pr-4">
                <div className="audioLevel">
                  <div className="noiseLevel" style={{ width: audioLevel }}></div>
                </div>
              </div>
            </div>
          </div>
        )}

        {localStream && !isLoading && (
          <div className="row">
            <div className="col-12 mb-3">
              <div ref={container} className="video-player"></div>
            </div>
            <div className="col-12 mb-4">
              <div className="row">
                <div className="col-7">
                  <h4>¿No es el micrófono correcto?</h4>
                  <p>Si no te escuchas al hablar intenta seleccionando otro</p>
                </div>
                <div className="col-5">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="change-microphone-label">Seleccionar Micrófono</InputLabel>
                    <Select
                      labelId="change-microphone-label"
                      id="change-microphone"
                      value={deviceSelected.deviceId}
                      onChange={handleChangeDeviceId}
                      label="Seleccionar Micrófono"
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccionar micrófono</em>
                      </MenuItem>
                      {microphoneDeviceList.map((device, index) => (
                        <MenuItem
                          key={"microphoneInput" + device.deviceId}
                          value={device.deviceId}>
                          {device.label ? device.label : "Micrófono " + (index + 1)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </div>
              </div>
            </div>

            <div className="col-12 border-top pt-3">
              <h4>¿Te escuchas al hablar?</h4>
            </div>
            <div className="col-6 mt-2">
              <button className="btn btn-success " onClick={microphoneWorking}>
                <Check /> <b>Si, me escucho</b>
              </button>
            </div>
            <div className="col-6 mt-2 text-right">
              <button className="btn btn-danger btn-outline" onClick={microphoneNoWorking}>
                <Close /> No me escucho
              </button>
            </div>
          </div>
        )}
      </div>

      {showHelp &&
        <div className="wizzard wizzard-audio-container mt-4">
          <div className="help-information">
            <div className="row">
              <div className="col-12 mb-2">
                <h3>Celulares o tablets</h3>
              </div>
              <div className="col-12">
                <ol>
                  <li>
                    <h4>¿Has subido el volumen?</h4>
                    <p>
                      En <strong>Android</strong> se distingue el volumen de las
                      llamadas, notificaciones y multimedia por separado.
                      Presiona el botón de control de volumen al costado de tu
                      dispositivo para aumentar o disminuir el sonido.
                    </p>
                  </li>
                  <li>
                    <h4>¿Estás usando audífonos?</h4>
                    <p>
                      Comprueba si el problema es de los audífonos quitándolos y
                      haciendo la prueba de sonido nuevamente. Algunos audífonos
                      traen incorporado un control de volúmen, verifica si los
                      tuyos lo tienen.
                    </p>
                  </li>
                  <li>
                    <h4>¿Aún no se escucha?</h4>
                    <p>
                      Si no estás usando audifonos, solamente los parlantes del equipo es posible que no puedas escuchar tu eco debido a la interferencia que se crea entre los parlantes y el micrófono, sobre todo si están muy cerca unos de otros. Es por esto que es recomendable utilizar unos audifonos.
                    </p>
                  </li>
                </ol>
              </div>

              <div className="col-12 mb-2 pt-3 border-top">
                <h3>Computador</h3>
              </div>
              <div className="col-12">
                <ol>
                  <li>
                    <h4>¿Has subido el volumen?</h4>
                    <p>
                      Verifica por medio del ícono de altavoces si has bajado o
                      muteado el volumen.
                    </p>
                  </li>
                  <li>
                    <h4>¿Estás usando audífonos?</h4>
                    <p>
                      Comprueba si el problema es de los audífonos quitándolos y
                      haciendo la prueba de sonido nuevamente. Algunos audífonos
                      traen incorporado un control de volúmen, verifica si los
                      tuyos lo tienen.
                    </p>
                  </li>
                  <li>
                    <h4>¿Tienes conectado más dispositivos de audio?</h4>
                    <p>
                      Verifica en el panel dispositivos de audio que hayas
                      seleccionado sea el correcto.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      }
      <ErrorList errors={errors} />
    </>
  );
};

export default VerificarMicrofono;
