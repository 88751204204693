import React, { useReducer, useState, useEffect, useContext} from "react";
import AgoraRTC from 'agora-rtc-sdk-ng';
import Alertas from "./components/Alertas/Alertas";
import alertaState from './components/Alertas/alertaState';
import RedbluLogo from './../assets/images/redblu-logo.svg';
import Loading from "../components/Loading";
import MediaPlayer from './components/MediaPlayer';
import patiensService from "../services/patients";
import PlayerControllers from "./components/MediaPlayerControllers/PlayerControllers";
import PropTypes from "prop-types";
import useAgora from './hooks/useAgora';
import useTeleconsultaPaciente from "./hooks/useTeleconsultaPaciente";
import useAlertMessage from './hooks/useAlertMessage';
import vattentionService from "../services/vattentions";

import { AlertasErroresPaciente } from "./components/Alertas/AlertasErroresPaciente";
import { AlertasExcepciones } from "./components/Alertas/AlertasExcepciones";
import { getLogedPatient } from "../services/api";
import { MensajePrevioPaciente } from "./components/Mensajes/MensajePrevioPaciente";
import { useAudioLevel } from "./hooks/useAudioLevel";
import { useHistory } from "react-router-dom";
import { UserContext } from "../context/UserContext";

let refresh = false;

const defaultState = {
   appId: "",
   channel: "",
   uid: "",
   token: undefined,
   cameraId: "",
   microphoneId: "",
   mode: "rtc",
   codec: "vp8",
   encryptionPassword: null,
   nombreMedico: "",
   doctorRut: "",
   specialty: "",
   nombrePaciente: "",
   rutPaciente:"",
   vattentionId: ""
};


const reducer = (
   state: typeof defaultState,
   action: { type: string;[propName: string]: any }
) => {
   switch (action.type) {
      default:
         return state;
      case "setAppId":
         return {
            ...state,
            appId: action.value,
         };
      case "setChannel":
         return {
            ...state,
            channel: action.value,
         };
      case "setUid":
         return {
            ...state,
            uid: action.value,
         };
      case "setToken":
         return {
            ...state,
            token: action.value,
         };
      case "setCamera":
         return {
            ...state,
            cameraId: action.value,
         };
      case "setMicrophone":
         return {
            ...state,
            microphoneId: action.value,
         };
      case "setMode":
         return {
            ...state,
            mode: action.value,
         };
      case "setCodec":
         return {
            ...state,
            codec: action.value,
         };
      case "setEncryptionPassword":
         return {
            ...state,
            encryptionPassword: action.value,
         };
      case "setNombreMedico":
         return {
            ...state,
            nombreMedico: action.value,
         };
      case "setDoctorRut":
         return {
            ...state,
            doctorRut: action.value,
         };
      case "setSpecialty":
         return {
            ...state,
            specialty: action.value,
         };
      case "setNombrePaciente":
         return {
            ...state,
            nombrePaciente: action.value,
         };
      case "setRutPaciente":
         return {
            ...state,
            rutPaciente: action.value,
         };
      case "setVattentionId":
         return {
            ...state,
            vattentionId: action.value,
         };
   }
};

const beforeunloadEvent = (props) => (ev) => {

   var confirmationMessage = "¿Está seguro que desea salir?";
   if (refresh === false) {
      if (typeof props.handleSalirTeleConsulta === 'function') {
         props.handleSalirTeleConsulta();
      }
   } else {
      confirmationMessage = "¿Esta seguro que desea actualizar la teleconsulta?"
   }

   (ev || window.event).returnValue = confirmationMessage;
   return confirmationMessage;
};

var timeOut: any = 0;
const client = AgoraRTC.createClient({ codec: 'vp8', mode: 'rtc' });

function AppTeleconsultaPaciente(props) {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [exception, setException] = useState({});
   const [isLoading, setIsLoading] = useState(false);
   const [vattentionId, setVattentionId] = useState(null);
   const [ nombreProfesional, setNombreProfesional ] = useState<String>();
   const [ especialidad, setEspecialidad ] = useState<String>();
   const [state, dispatch] = useReducer(reducer, defaultState);
   const [numRutUsuario, setnumRutUsuario] = useState<String>();
   const [numRutMedico, setNumRutMedico] = useState<String>();
   const [audioLevelMedic, setAudioLevelMedic] = useState<number>(0);
   const {
      localAudioTrack, 
      localVideoTrack, 
      leave, 
      join, 
      joinState, 
      remoteUsers, 
      error,
      localCameras,
      localMicrophones,
      switchCamera,
      switchMicrophone,
      audioLevel,
      isSharingScreen,
      localSharingScreen,
      setIsSharingScreen,
    } = useAgora(client);

    const {
      obtenerUIUser,
      getAgoraDynamicRtcToken
    } = useTeleconsultaPaciente();

    alertaState.alerta = useAlertMessage();

    

   useEffect(() => {    
      window.addEventListener("beforeunload", beforeunloadEvent(props));

      return () => {
         window.removeEventListener("beforeunload", beforeunloadEvent(props));
      };

   }, [props]);

   useEffect(() => {
      let divContainer = document.getElementById('patientVersion');
      if(divContainer){
        divContainer.style.maxWidth = 'initial';
        divContainer.style.display = 'flex';
        divContainer.style.flexDirection = 'column';
        divContainer.style.minHeight = '100vh';
      }


      client.on("exception", function (evt) {
         setException({ evt });
       });

      refresh = false;
      patiensService
         .setLogActivityUser(user.rut, "Ingresando a la atención")
         .then((response) => { });

      return () => {
         clearTimeout(timeOut);
      };

   }, []);

  
   //salir de agora cuando el componente se desmonta
   useEffect(() => {
      return () => {
         if (client) {
            leave();
         }
      };
   }, [client]);  
   
   useEffect(() => {
      console.log('error ::> ',error);
   }, [error]);


   /**
    * Controla el inicio e ingreso de usuario a teleconsulta
    * @function getAgoraDynamicRtcToken Obtiene el appid, channel y token.
    * @function join realiza el init del stream local y publica al canal.
    * @function updateVAttentionStartAttentionByName deja la marca que el médico inicia la consulta
    * @function getStats();
    */
   const handleJoin = async () => {
      setIsLoading(true);
      const { channel, token, appid, rutPaciente, nombreMedico, especialidad, vattentionId, rutMedico} = await getAgoraDynamicRtcToken(dispatch);
      console.log("channel:"+channel," token:"+token," appid:" +appid," rutPaciente:"+rutPaciente);
      setNombreProfesional(nombreMedico);
      setEspecialidad(especialidad);
      setVattentionId(vattentionId);
      setnumRutUsuario(obtenerUIUser(rutPaciente));
      setNumRutMedico(obtenerUIUser(rutMedico));
      // console.log(numRutMedico);

      await join(appid, channel, token, obtenerUIUser(rutPaciente));
      
      if (join) { setIsLoading(false) }
        
      await vattentionService.updateVAttentionStartAttentionByName(
         state.channel,
         {
            userType: 'paciente',
            startAttention: new Date(),
         }
      );
      
      if(!error){
         patiensService
         .setLogActivityUser(user.rut, "Ingreso a teleconsulta")
         .then((response) => { });
      }
   };

   /**
    * handleLeave
    * @function leave Finaliza la teleconsulta
    *
    */

   const handleLeave = async () => {

      setIsLoading(true);
      props.handleSalirTeleConsulta();
      
      if (joinState) {
         await leave();
      }

      history.push( '/paciente/teleconsulta/resumen/' + vattentionId);
   };
   
  // UseAudioLevel
   const level = useAudioLevel(audioLevel, numRutMedico,'paciente');

   return (
      <> 
         {(!joinState && !isLoading)&&
            <>
               <MensajePrevioPaciente>
                  <button 
                     id='join' 
                     type='button' 
                     className='btn btn-submit '
                     disabled={isLoading}
                     hidden={joinState} onClick={handleJoin}
                  >
                     Iniciar Teleconsulta
                  </button> 
                  </MensajePrevioPaciente>
            </>
         }
         {(isLoading && !joinState)
            ? <div className="mt-3 _newsdk_videocall "><div className="player-container"><Loading /> </div></div>
            : null
         }
          {joinState &&
            <>
              <div className="col-12 header-meet-patient">
                <div className="header-meet">
                    <div className="row align-items-center">
                      <div className="col-4 videoButtonState text-left">
                        <img src={RedbluLogo} alt="Redblu" width={120} />
                      </div>
                      <div className="col-8 text-left">
                          <div className="medicData">
                            <span className="medicDataName">
                                {nombreProfesional}
                            </span>
                            <span className="medicDataSpeciality">
                                {especialidad}
                            </span>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
              <div className="_newsdk_videocall _newsdk_patient_interface">
                <div className='player-container'>
                  <div className='local-player-wrapper'>
                      {(localVideoTrack || localAudioTrack) &&
                      <MediaPlayer
                          rol={'paciente'}
                          join={joinState}
                          isRemoteTrack={false}
                          videoTrack={localVideoTrack}
                          audioTrack={undefined}
                          audioLevel={level.audioLvl}></MediaPlayer>
                      }
                    </div>


                    {(remoteUsers.length === 0) ?
                      <div className="remote-player-empty">
                          <span>En espera del profesional...</span>
                      </div>
                      :
                      <>{remoteUsers.map(user => (
                          <div className='remote-player-wrapper' key={user.uid}>
                            <MediaPlayer
                                rol={'paciente'}
                                join={joinState}
                                isRemoteTrack={true}
                                videoTrack={user.videoTrack}
                                audioTrack={user.audioTrack}
                                audioLevel={level.audioLvl}></MediaPlayer>
                          </div>
                      ))}
                      </>
                    }

                    {(joinState && remoteUsers.length === 0) ?
                      <div className="status-paciente">
                          <div className="paciente-salir-teleconsulta pb-5 text-center">
                            <p>¿Finalizó tu atención? presiona para continuar</p>
                            <button
                                className="btn-submit"
                                onClick={handleLeave}
                            >Finalizar mi atención</button>
                          </div>
                      </div>
                : null }
                </div>
              </div>
              <div className="footer-meet">
                <PlayerControllers 
                  switchCamera={switchCamera}
                  switchMicrophone={switchMicrophone} 
                  localVideoTrack={localVideoTrack} 
                  localAudioTrack={localAudioTrack}
                  localCameras={localCameras}
                  localMicrophones={localMicrophones}
                  joinState={joinState}
                  localSharingScreen={localSharingScreen}
                  isSharingScreen={isSharingScreen}
                  setIsSharingScreen={setIsSharingScreen}
                />
                <AlertasExcepciones exception={exception} rol={'paciente'} />
                <Alertas />
              </div>
            </>
         }
      </>
   );
}

AppTeleconsultaPaciente.propTypes = {
   autoJoin: PropTypes.bool,
   handleSalirTeleConsulta: PropTypes.func
};

AppTeleconsultaPaciente.defaultProps = {
   autoJoin: false,
   handleSalirTeleConsulta: PropTypes.func
};

export default AppTeleconsultaPaciente;
