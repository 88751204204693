import React, { useState } from "react";
import { Select, MenuItem, TextField, Drawer } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';


const DeviceSelector = ({deviceOptions, handlerFunction, tipo, handleClickAway, dispositivoSeleccionado}) => {
    const [defaultValue, setDefaultValue] = useState(dispositivoSeleccionado);
    const [disabledButton, setDisabledButton] = useState(false);

    /**
     * onChangeSelect
     * @function setDefaultValue Actualiza el elemento seleccionado del cuadro desplegable
     * @function handlerFunction Llama a la función por props para iniciar un nuevo track de micrófono o cámara.
     *
     */
    const onChangeSelect = async(e) => {
        setDisabledButton(true);
        setDefaultValue(e.target.value);
        await handlerFunction(e);
        await handleClickAway();
        setDisabledButton(false);
    };

    return(
      <>
        {(typeof deviceOptions !== 'undefined' && deviceOptions.length > 0) &&
        
            <Select
                displayEmpty
                name="selector-dispositivo"
                value={defaultValue}
                onChange={onChangeSelect}
                fullWidth
                disabled={disabledButton}
            >
                    <MenuItem value="" disabled>{tipo}...</MenuItem>
                    
                    {deviceOptions.map(camara => (
                        <MenuItem 
                            key={camara.deviceId} 
                            value={camara.deviceId}>
                                {camara.label}
                        </MenuItem>
                    ))}

            </Select>
               
        }
      </>
    )
};

export default DeviceSelector;