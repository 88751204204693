import { useEffect, useState } from 'react';
import { NavigateBefore } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import TroubleShootingLoader from './TroubleShootingLoader';
import './TroubleShooting.css';

const TroubleShootingIndex = () => {
  const { error } = useParams();
  const [solutionComponent, setSolutionComponent] = useState(null);

  useEffect(() => {
    if(error){
      const solution = TroubleShootingLoader[error];
      setSolutionComponent(solution ? solution : TroubleShootingLoader.notFound);
    }
  }, [error]);

  return(
    <div className="container">
      <div className="row justify-content-md-center">
        <div className="col-md-10 col-lg-8 col-sm-12 col-12">
          <div className="troubleshooting px-4 py-3">
            {solutionComponent}
            <div className="back-button">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3"></div>
                  <div className="col-sm-6">
                    <button 
                      className="btn btn-submit full-width"
                      onClick={() => {
                      window.open('', '_self', '');
                      window.close();
                    }}><NavigateBefore /> Volver</button>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            </div>
            <div className="separator"></div>
          </div>
        </div>
      </div>
    </div>
  )
};


export default TroubleShootingIndex;


