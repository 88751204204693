import React, { useState } from 'react';
import { useHistory, useRouteMatch } from "react-router-dom";
import ReactGA from 'react-ga';
import NavProgress from './NavProgress';
import TermsAndConditions from './TermsAndConditions';
import CreacionCuenta from './NewAccount';

function Index() {
  const history = useHistory();
  const [step, _setStep] = useState(1);
  const match = useRouteMatch();
  const setStep = (step) => {
    ReactGA.pageview(match.path);
    if( step === 6 ){
      history.push("/login");
    } else if( step === 3 ){
      history.push("/login");
    }else{
      _setStep(step);
    }
  }

  const handleBackButton = (ev) => {
    ev.preventDefault();
    if (step > 1) {
      _setStep(step-1);
    }else{
      history.push("/login");
    }
  }

	return (
		<div className="fullh">
      <NavProgress stepnumber={step} onBackButton={handleBackButton} />

      { step === 1 ?
        <TermsAndConditions setStep={setStep} />
      : null }

      { step === 2 ?
        <CreacionCuenta setStep={setStep} />
        : null }

    </div>
	);
}

export default Index;
