import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {
  Send
} from '@material-ui/icons';

import './Input.css';


const useStyles = makeStyles((theme) => ({
  chatSection: {
    width: '100%',
    height: '100vh'
  },
  inputFile: {
    display: 'none'
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
}));

const Input = ({ setMessage, sendMessage, message }) => {
  const classes = useStyles();
  return (
  <>
    <AppBar position="absolute" className="message-bar">
      <Toolbar>
        <input
          className="input"
          type="text"
          placeholder="Escribir mensaje..."
          value={message}
          onChange={({ target: { value } }) => setMessage(value)}
          onKeyPress={event => event.key === 'Enter' ? sendMessage(event) : null}
        />
        
        <div className={classes.grow} />
        
        <IconButton edge="end" color="inherit" onClick={e => sendMessage(e)}>
          <Send />
        </IconButton>

      </Toolbar>
    </AppBar>
    
  </>)
}

export default Input;