import React from 'react';
import imgNext from './../../../assets/images/iconos/icono-white-next.svg';

const EnlaceActividad = ({ url }) => {
   
    return (
      <>
         {
            <div className="actividadFondo p-4 pt-5 pb-5 text-center">
                <a
                   href={url}
                   className={url ? "btn btn-submit btn-anchor btn-with-icon " : "btn btn-secondary disabled"}
                   target="_blank"
                   rel="noopener noreferrer"
                >
                   {
                      url ? 'IR AL ENLACE' : 'NO HAY ENLACE DISPONIBLE'
                   }
                   {
                      url ? <img src={imgNext} alt="Ir al enlace" /> : null
                   }

                </a>
                <div className="mt-1">
                   {
                      url ? <small>Presiona el botón para visitar el enlace.</small> : null
                   }
                </div>
            </div>
         }
      </>
    )
}

export default EnlaceActividad
