import { useEffect, useState } from 'react';
import { Fab, Dialog } from '@material-ui/core';
import { Chat } from '@material-ui/icons'; 
import { getSocketClient } from '../../../services/socket';

const ChatDialog = (
  {
    open, 
    handleClose,
    handleToggle, 
    style,
    chatRoomSelected,
    isChatSelected,
    children
  }
) => {

  const[socket, setSocket] = useState();
  const[newMessage, setNewMessage] = useState(false);
  const[showAlert,setShowAlert] = useState(false);
  const[recievedMessage, setRecievedMessage] = useState({});

  useEffect(() => {
    setSocket(getSocketClient());
  }, []);

  useEffect(() => {
    if(socket){
      socket.on("notifyNewMessage", (data) => {
        setNewMessage(true);
        setRecievedMessage({
          data:data
        });
      });
    }
  }, [socket]);

  const innerHandlerToggle = () => {
    if(!isChatSelected && !chatRoomSelected){
      setNewMessage(false);
      setShowAlert(false);
    }
    handleToggle();
  }

  useEffect(() => {
    if(!isChatSelected){
      if(showAlert){
        setShowAlert(false);
        setNewMessage(false);
      }
    }
  }, [isChatSelected]);

  useEffect(() => {
    /*
      Cases:
      1. User 1 habla a user 2 con chat cerrado.
      2. Muestra alerta siempre y cuando sea distinto al usuario con el que habla.
    */

    if(newMessage){
      if(!open){
        // Case 1
        setShowAlert(true);
      } else {
        // Case 2
        if(isChatSelected && chatRoomSelected){
          if((recievedMessage.data.userFrom !== chatRoomSelected.medicRut) &&
            (recievedMessage.data.userFrom !== chatRoomSelected.patientRut)){
            setShowAlert(true);
          } 
        }
      }
    }
  }, [recievedMessage]);


  return(
    <div className="chatPaciente animated backInUp">
      <Fab
        style={style}
        color="primary"
        aria-label="add"
        onClick={innerHandlerToggle}
        className="chatGlobe">

          {showAlert  &&
            <>
              <span className="newMessageText animated heartBeat"><span></span>Nuevo mensaje</span>
              <span className="newMessageIndicator animated heartBeat"></span>
            </>
          }
          <Chat />
        Chat
      </Fab>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        className="chatPacienteModal">
          {children}
      </Dialog>
    </div>
  )
}

export default ChatDialog;