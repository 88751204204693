import React, { useState } from "react";
import audioFile from "../../sounds/audio-test.mp3";
import ReproducirSonido from "./../images/ReproducirSonido";
import ReproducirSonidoPrevia from "./../images/reproducir-sonido.svg";
import { Check, Close, PlayArrow, Replay } from "@material-ui/icons";
import "./VerificarAudio.css";

const VerificarAudio = ({ setStep }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [verificado, setVerificado] = useState(false);
  const [isShowHelp, setIsShowHelp] = useState(false);

  const playSound = async () => {
    setIsPlaying(true);
    document.getElementById("audioTest").play();
  };

  const playedSound = () => {
    document.getElementById("audioTest").pause();
    setVerificado(true);
    setStep( currentStep => currentStep+1);
  };

  const notPlayedSound = () => {
    document.getElementById("audioTest").pause();
    setIsPlaying(false);
    setIsShowHelp(true);
  };

  return (
    <>
      <div className="wizzard-heading">
        <span className="step-number">02</span>
        <h2 className="step-title">Audio</h2>
      </div>

      <div className="wizzard wizzard-audio-container text-center">
        {!isPlaying && !verificado && (
          <div className="row">
            <div className="col-12 col-sm-9 m-auto">
              <img
                src={ReproducirSonidoPrevia}
                alt="Reproducir sonido"
                width={340}
                className="w-100"
              />
              <p>
                Reproduciremos un sonido para verificar que puedas escuchar y
                ajustar el nivel de volumen en tu equipo
              </p>
            </div>
            <div className="col-12 mt-4">
              {isShowHelp ? (
                <button className="btn-submit" onClick={playSound}>
                  Volver a intentar <Replay />
                </button>
              ) : (
                <button className="btn-submit" onClick={playSound}>
                  Reproducir sonido <PlayArrow />
                </button>
              )}
            </div>
          </div>
        )}

        {isPlaying && !verificado && (
          <>
            <div className="row">
              <div className="col-sm-9 col-12 m-auto">
                <ReproducirSonido />
                <h4>¿Escuchas la música?</h4>
                <p>
                  Se está reproduciendo una música en estos momentos, presiona
                  el botón correspondiente
                </p>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-sm-1 col-12"></div>
              <div className="col-sm-5 col-6 mt-4">
                <button className="btn btn-success " onClick={playedSound}>
                  <Check /> <b>Si</b> escucho
                </button>
              </div>
              <div className="col-sm-5 col-6 mt-4">
                <button
                  className="btn btn-danger btn-outline"
                  onClick={notPlayedSound}
                >
                  <Close /> No escucho
                </button>
              </div>
              <div className="col-12 col-sm-1"></div>
            </div>
          </>
        )}
      </div>

      {isShowHelp && (
        <div className="wizzard wizzard-audio-container mt-4">
          <div className="help-information">
            <div className="row">
              <div className="col-12 mb-2">
                <h3>Celulares o tablets</h3>
              </div>
              <div className="col-12">
                <ol>
                  <li>
                    <h4>¿Has subido el volumen?</h4>
                    <p>
                      En <strong>Android</strong> se distingue el volumen de las
                      llamadas, notificaciones y multimedia por separado.
                      Presiona el botón de control de volumen al costado de tu
                      dispositivo para aumentar o disminuir el sonido.
                    </p>
                  </li>
                  <li>
                    <h4>¿Estás usando audífonos?</h4>
                    <p>
                      Comprueba si el problema es de los audífonos quitándolos y
                      haciendo la prueba de sonido nuevamente. Algunos audífonos
                      traen incorporado un control de volúmen, verifica si los
                      tuyos lo tienen.
                    </p>
                  </li>
                  <li>
                    <h4>¿Aún no se escucha?</h4>
                    <p>
                      Puede que el dispositivo que estés usando tenga dañado los
                      altavoces y por ende no puedas escuchar el sonido. Si es
                      el caso tendrás que cambiarte a otro dispositivo para
                      poder acceder a la teleconsulta.
                    </p>
                  </li>
                </ol>
              </div>

              <div className="col-12 mb-2 pt-3 border-top">
                <h3>Computador</h3>
              </div>
              <div className="col-12">
                <ol>
                  <li>
                    <h4>¿Has subido el volumen?</h4>
                    <p>
                      Verifica por medio del ícono de altavoces si has bajado o
                      muteado el volumen.
                    </p>
                  </li>
                  <li>
                    <h4>¿Estás usando audífonos?</h4>
                    <p>
                      Comprueba si el problema es de los audífonos quitándolos y
                      haciendo la prueba de sonido nuevamente. Algunos audífonos
                      traen incorporado un control de volúmen, verifica si los
                      tuyos lo tienen.
                    </p>
                  </li>
                  <li>
                    <h4>Verifica el navegador</h4>
                    <p>
                      Verifica que no hayas silenciado o bloqueado las opciones
                      de reproducción de sonido en el navegador. Dependiendo del
                      navegador que estés utilizando tendrás que dirigirte a la
                      configuración de sonido.
                    </p>
                  </li>
                  <li>
                    <h4>¿Tienes conectado más dispositivos de audio?</h4>
                    <p>
                      Verifica en el panel dispositivos de audio que hayas
                      seleccionado sea el correcto.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      )}
      <audio id="audioTest" loop>
        <source src={audioFile} type="audio/ogg" />
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </>
  );
};

export default VerificarAudio;
