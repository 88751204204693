import Rollbar from 'rollbar';
import {getLogedPatient} from "../services/api";

export const RollbarErrorTracking = (() => {

    const RollbarObj = new Rollbar(
        {
            accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
            captureUncaught: true,
            captureUnhandledRejections: true, 
        },
    );

    const logErroMedico = (info) => {
        RollbarObj.debug(info);
    };

    const logErrorDebug = (info) => {
        RollbarObj.debug(info, {usuario:getLogedPatient()});
    };

    const logErrorInRollbar = (error) => {
        throw new Error(error);
    };

    return { logErrorInRollbar, logErrorDebug, logErroMedico };

})();

export default RollbarErrorTracking;