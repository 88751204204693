import React from 'react';

const Loading = () => {

  return(
    <div id="loading">
      <div className="col-md-12 text-center">
        <div className="loading-spinner">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div> 
  )
}

export default Loading;