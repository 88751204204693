import React from "react";

export const Encabezado = () => {
  return (
    <>
      <div className="row animated fadeIn">
        <div className="col-12">
          <h6 className="font-weight-bold">
            Agregar una nueva entrada a la ficha del paciente
          </h6>
          <p className="small">
            Detalla las observaciones que quieras sobre el paciente. La
            información que ingreses será poder vista en futuras consultas
            por otros psicólogos.
          </p>
        </div>
      </div>
    </>
  );
};
