import React from 'react';
import img_prueba_sonido from "./../assets/images/permitir.svg";

const SolicitaPermiso = ({runMicVideoTest}) => {
    return(
        <div>
            <div className="page-name mb-4">
                <h1>Antes de comenzar</h1>
            </div>

            <div className="homeRecomendations">
                <div className="row">
                <div className="col-12">
                    <div className="guiastep">
                        <div className="number little-number">1</div>
                        <div className="guiastep-title">
                            <h3>Habilitar Micrófono y Cámara</h3>
                        </div>
                        <div className="guiastep-content">
                            <p>
                            Presiona el siguiente botón para autorizar a la
                            aplicación a acceder a la cámara y micrófono.
                            </p>
                            <button
                            onClick={runMicVideoTest}
                            className="btn btn-submit btn-permission animated pulse infinite slow"
                            >
                            Habilitar Micrófono y Cámara
                            </button>
                        </div>
                    </div>

                    <div className="guiastep mt-4">
                        <div className="number">2</div>
                        <div className="guiastep-title">
                            <h3>
                            <em>Permitir</em> solicitud
                            </h3>
                        </div>
                        <div className="guiastep-content">
                            <p>
                            Al presionar el botón anterior te aparecerá un
                            cuadro emergente en donde deberás escoger la
                            opción <strong>permitir</strong>.
                            </p>
                            <img
                            src={img_prueba_sonido}
                            alt="Prueba de sonido"
                            />
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default SolicitaPermiso;