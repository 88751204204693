// import axios from 'axios';
import API, { isApiLoged } from './api';
import URLS from '../URLS';

const vattentionServices = {
  getVAttentions: function () {
    return API.axios({
      method: "get",
      url: URLS.vattentions,
    });
  },

  getVAttention: function (vattentionId) {
    return API.axios({
      method: "get",
      url: URLS.vattentions + "/" + vattentionId,
    });
  },

  getVAttentionMedic: function (vattentionId, tokenRedgesam) {
    return API.axios({
      method: "get",
      url: URLS.vattentions + "/" + vattentionId,
      params: {
        tokenRedgesam: tokenRedgesam,
      },
    });
  },

  createVAttention: function (vattentionData) {
    return API.axios({
      method: "post",
      url: URLS.vattentions,
      data: vattentionData,
    });
  },

  //   const config = { headers: {'Content-Type': 'application/json'} };
  // axios.put(url, content, config).then(response => {
  //     ...
  // });

  updateVAttention: function (vattentionId, data, tokenRedgesam) {
    let params;
    if (tokenRedgesam) {
      params = {
        tokenRedgesam: tokenRedgesam,
      };
    }

    return API.axios({
      method: "put",
      url: URLS.vattentions + "/" + vattentionId,
      data: data,
      params: params,
    });
  },

  updateVAttentionByName: function (channelName, data) {
    return API.axios({
      method: "put",
      url: URLS.vattentions + "/updatebyname/" + channelName,
      data: data,
    });
  },

  updateVAttentionStartAttentionByName: function (channelName, data) {
    return API.axios({
      method: "put",
      url: URLS.vattentions + "/setstartattention/" + channelName,
      data: data,
    });
  },

  getPatientRoom: function () {
    return API.axios({
      method: "get",
      url: URLS.vattentions + "/patientroom",
    });
  },

  closeVAttentionByName: function (channelName) {
    return API.axios({
      method: "post",
      url: URLS.vattentions + "/close",
      data: {
        channel: channelName,
      },
    });
  },

  medicCloseWindow: function (APIKey) {

    navigator.sendBeacon(URLS.baseURL + URLS.vattentions + "/medicCloseWindow", `{ "APIKey": "${APIKey}" }`);

  },

  getMedicActiveRoom: function (APIKey) {
    return API.axios({
      method: "get",
      url: URLS.vattentions + "/medicActiveRoom",
      params: {
        APIKey: APIKey
      }
    });
  },
};

export default vattentionServices;
