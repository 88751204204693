import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
// import RedbluApp from "./routes/RedbluApp";
import ReactPWAInstallProvider from "react-pwa-install";
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context/UserProvider';
import { AppRouter } from './routes/Router';

ReactDOM.render(

    <UserProvider>
      <ReactPWAInstallProvider enableLogging>
        <AppRouter />
      </ReactPWAInstallProvider>
      </UserProvider>,
    
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();


