import React, { useEffect } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { obtieneDias } from './ObtieneDias';
// import { set } from 'date-fns/esm';
import storeFechaSeleccionada from '../resumenActividadesGrafico/store';

//Analytics
import ReactGA from 'react-ga';
// import { Action } from 'react-tiny-fab';

let diasPeriodo = [];
// let grafico;

export const Seguimiento = ({
  arrayDias,
  diaInicial,
  diaFinal,
  chartContainer,
  actRealizadas,
  actNoRealizadas,
  totalActividades,
  diffDays,
  patientId,
  render
}) => {

  useEffect(() => {

    // Rangos de Fechas para labels
    // Obtener labels de Fechas
    let arrayDaysAux = [];
    let arrayDaysLabel = [];
    let daysLabels = [];
    let arrayCompletadas = [];
    let arrayNoCompletadas = [];
    let arrayTotales = [];
  

    obtieneDias(
      diaInicial,
      diaFinal,
      arrayDias,
      arrayDaysAux,
      arrayDaysLabel,
      daysLabels,
      arrayCompletadas,
      arrayNoCompletadas,
      arrayTotales,
      actRealizadas,
      actNoRealizadas,
      totalActividades,
      diffDays
    );

    diasPeriodo = arrayDaysAux;
    
    // console.log(arrayTotales);
    const updateActivityChart = () => {
      let Chart = window.Chart;
      let chartData = {
        labels: daysLabels,
        datasets: [
          {
            type: "line",
            label: "Actividades realizadas",
            borderColor: "#4354FE",
            backgroundColor: "white",
            borderWidth: 2,
            pointRadius: 5,
            pointHoverRadius: 7,
            pointHoverBackgroundColor:"#4354FE",
            pointHoverBorderWidth:3,
            fill: false,
            data: [...arrayCompletadas],
          },
          {
            type: "bar",
            label: "Totales",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            fill: false,
            data: [...arrayTotales],
          },
        ],
      };

      let ctx = chartContainer.getContext("2d");
      window.myMixedChart = new Chart(ctx, {
        type: "bar",
        data: chartData,
        options: {
          onClick: clickFecha,
          responsive: true,
          tooltips: {
            mode: "index",
            intersect: true,
          },
        },
      });
    };
    updateActivityChart();

  // TODO:
  //   TypeError: e is not a constructor
  // File "web/components/seguimientoRecurso/Seguimiento.jsx", line 84, in Seguimiento
  //         window.myMixedChart = new Chart(ctx, {
  // File "web/components/seguimientoRecurso/Seguimiento.jsx", line 97, in Seguimiento
  //       updateActivityChart();

  }, [actNoRealizadas, actRealizadas, arrayDias, chartContainer, diaFinal, diaInicial, diffDays, render.renderSaved, totalActividades]);


  const clickFecha = (e, element) => {
    // console.log(element)
      if(element[0]){
         let fechaSeleccionada = diasPeriodo[element[0]._index];
         storeFechaSeleccionada.fecha.setFecha(fechaSeleccionada);
      }
      ReactGA.event({category:"Fecha Gráfico", action:"Click"})
   }

  return (
    <>
      <div className="card-body">
        <div className="chart-area">
          <canvas
            ref={(e) => (chartContainer = e)}
            key={render.renderSaved + diffDays + arrayDias + diaFinal + diaInicial + actRealizadas + actNoRealizadas + totalActividades + patientId + chartContainer} 
            id="activity-graph"
            className="p-0"
          ></canvas>
        </div>
      </div>

      {/* <div
                className="row mt-1 animated fadeIn"
            >
                <div className="col-md-12 col-12 ">
                    <div className="card little-shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-dark">
                                Información
                                    </h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-area">
                                <div className="row pt-4">
                                    <div className="col-md-12">

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div> */}
    </>
  );
};
