import {
  useReducer,
  useEffect,
  useState
} from 'react';
import {
  UserContext
} from './UserContext';
import {
  UserReducer
} from './UserReducer';
import {
  isApiLoged, setNewToken,
} from "./../services/api";
import authentication from "./../services/authentication";
import { inicializaSocket } from '../services/socket';
import Loading from '../components/Loading';
import Dictionary from '../Profesional/utils/Dictionary';
import RoutesDefinition from '../routes/RoutesDefinition';

const INITIAL_STATE = {
  isLogged: false,
  isFinishChecking: false,
  accessToken: '',
  user: {
    _id: '',
    firstname: '',
    lastname: '',
    email: '',
    rut: '',
    phone: '',
    role: ''
  },
}

const UserProvider = ({
  children
}) => {
  const [state, dispatch] = useReducer(UserReducer, INITIAL_STATE);
  const [_isApiLoged, setIsApiLoged] = useState(isApiLoged);
  // Con esto tengo la habilidad de hacer dispath y de obtener el usuario a lo largo de cualquier parte de la aplicación

  useEffect(() => {
    if (!_isApiLoged && !state.isLogged) {
      validateUserAuthentication();
    }
  }, [_isApiLoged]);

  const validateUserAuthentication = async () => {

    try {
      const response = await authentication.refreshToken();

      if (!response) {
        if(RoutesDefinition.isAccesingPrivateRoutes(window.location.pathname)){
          window.location.pathname = "/login";
          return;
        }
        setData({
          ...state,
          isFinishChecking: true,
        });
        return;
      }

      const { accessToken, medic, patient, role } = response.data;
      setNewToken(accessToken);

      if(role === Dictionary.ROLE.PROFESIONAL){
        handleProfesionalLogin(accessToken, medic, role);
        return;
      }

      if(role === Dictionary.ROLE.PATIENT){
        handlePatientLogin(accessToken, patient, role);
        return;
      }

    } catch (error) {
      setData({
        ...state,
        isFinishChecking: true,
      });
    }
  }

  const handlePatientLogin = (accessToken, patient, role) => {
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/" ||
      window.location.pathname === "/profesional"
    ) {
      window.location.pathname = "/paciente";
      return;
    }
    setData({
      isLogged: true,
      isFinishChecking: true,
      accessToken: accessToken,
      user: {
        ...patient,
        role: role
      },
      isWaitingRoom: false,
    });
    handleInitSocket(patient, accessToken);
  }

  const handleProfesionalLogin = (accessToken, medic, role) => {
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/" ||
      window.location.pathname === "/paciente"
    ) {
      window.location.pathname = "/profesional";
      return;
    }
    setData({
      isLogged: true,
      isFinishChecking: true,
      accessToken: accessToken,
      user: {
        ...medic,
        role: role
      },
      isWaitingRoom: false,
    });
    handleInitSocket(medic, accessToken);
  }

  const handleInitSocket = (user, accessToken) => {

    const socket = inicializaSocket({
      room: user.rut,
      rut: user.rut,
      name: `${user.firstname} ${user.lastname}`,
    });

    socket.emit("userConnect", user, () => { });

    socket.on("nueva_coneccion", ({ data }) => {
      console.log("nueva_coneccion: disabled: ", data);
    });

    socket.emit("userLogsIn", { accessToken }, (error) => {
      if (error) {
        console.log("userLogsIn", error);
      }
    });

  };

  const setAccessToken = (accessToken) => {
    dispatch({
      type: 'SET_ACCESS_TOKEN',
      payload: accessToken
    });
  }

  const setUser = (user) => {
    dispatch({
      type: 'SET_USER',
      payload: user
    });
  }

  const setLogged = (isLogged) => {
    dispatch({
      type: 'SET_LOGGED',
      payload: isLogged
    });
  }

  const setData = (data) => {
    dispatch({
      type: 'SET_DATA',
      payload: data
    })
  }

  return (
    <UserContext.Provider value={
      {
        ...state,
        setAccessToken,
        setData,
        setLogged,
        setUser,
        dispatch
      }
    }>
      {state.isFinishChecking ? children : <Loading />}
    </UserContext.Provider>
  )
}

export {
  UserProvider,
  UserContext
};