import React from 'react';
import moment from 'moment';


export const AgendaEvent = ({event}) => {

  const { 
    // detail,
    end,
    id, 
    start,
    title,
  } = event;


  return (
    <>
    <div 
      className=""
      id={id}
      >
        <span className="bg-aqua small" style={datosCita}>
          <strong>{title}</strong>
          <small>{moment(start).format("DD/MM ( hh:mm")} - {moment(end).format("hh:mm )")}</small>
        </span>
      {/* <span
          className="text-light m-0 p-0 small"
          style={datosCita}
        >
        <span className="">rut   : {detail.patientRut || ''}</span>
        <span className="">email : {detail.email || "-"}</span>
      </span> */}

    </div>
    </>
    
  )
}


const datosCita = {
  display: 'flex',
  height: 'auto',
  justifyContent:'space-between'
}