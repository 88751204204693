import browser from 'platform-detect/browser.mjs';
import system from 'platform-detect/os.mjs';

//Chrome
import imgHabilitarPermisoCamaraOne from './images/habilitar-permiso-camara-windows-1.svg';
import imgHabilitarPermisoCamaraTwo from './images/habilitar-permiso-camara-windows-2.svg';
import imgHabilitarPermisoCamaraThree from './images/habilitar-permiso-camara-windows-3.svg';
import imgHabilitarPermisoCamaraFour from './images/habilitar-permiso-camara-windows-4.svg';

//Safari
import imgHabilitarPermisoSafariCamaraOne from './images/habilitar-permiso-camara-safari-1.svg';
import imgHabilitarPermisoSafariCamaraTwo from './images/habilitar-permiso-camara-safari-2.svg';
import imgHabilitarPermisoSafariCamaraThree from './images/habilitar-permiso-camara-safari-3.svg';
import imgHabilitarPermisoSafariCamaraFour from './images/habilitar-permiso-camara-safari-4.svg';
import imgHabilitarPermisoSafariCamaraFive from './images/habilitar-permiso-camara-safari-5.svg';

//Android
import imgHabilitarPermisoAndroidCamaraOne from './images/habilitar-permiso-camara-android-1.svg';
import imgHabilitarPermisoAndroidCamaraTwo from './images/habilitar-permiso-camara-android-2.svg';
import imgHabilitarPermisoAndroidCamaraThree from './images/habilitar-permiso-camara-android-3.svg';
import imgHabilitarPermisoAndroidCamaraFour from './images/habilitar-permiso-camara-android-4.svg';
import imgHabilitarPermisoAndroidCamaraFive from './images/habilitar-permiso-camara-android-5.svg';
import imgHabilitarPermisoAndroidCamaraSix from './images/habilitar-permiso-camara-android-6.svg';

//iPhone
import imgHabilitarPermisoiPhoneCamaraOne from './images/habilitar-permiso-camara-iphone-1.svg';
import imgHabilitarPermisoiPhoneCamaraTwo from './images/habilitar-permiso-camara-iphone-2.svg';
import imgHabilitarPermisoiPhoneCamaraThree from './images/habilitar-permiso-camara-iphone-3.svg';
import imgHabilitarPermisoiPhoneCamaraFour from './images/habilitar-permiso-camara-iphone-4.svg';
import imgHabilitarPermisoiPhoneCamaraFive from './images/habilitar-permiso-camara-iphone-5.svg';

const PermisoCamaraHabilitar = () => {

  return (
    <div>
      <div className="title mb-4">
        <span className="pb-2">Guía de configuración</span>
        <h1>Permiso de la cámara ha sido bloqueado</h1>
      </div>

      <h2>Descripción</h2>
      <div className="section border-top pt-3">
        <p>Este problema ha ocurrido porque no has presionado el botón de "permitir" al momento en que se ha realizado la solicitud para acceder a la cámara. La plataforma requiere poder acceder tanto a la cámara y micrófono para hacer uso de ella al momento de la teleconsulta.</p>
      </div>

      <h2>Solución:</h2>
      <div className="section border-top pt-3">
        <p className="mb-4">Sigue estas indicaciones para corregir el problema:</p>

        {system.windows ||
          (system.macos && browser.chrome) ||
          system.chromeos || 
          system.linux ?
          <WindowsGuide />
          : null}


        {system.macos && browser.safari ?
          <MacOsSafariGuide />
          : null}

        {system.android &&
          <AndroidGuide />
        }

        {system.ios && browser.safari ?
          <IphoneGuide />
        : null}

      </div>
    </div>
  )
};

export default PermisoCamaraHabilitar;

const WindowsGuide = () => {
  return (
    <ol>
      <li>
        <p>Presione el ícono con la figura de un candado en la barra de navegación.</p>
        <img src={imgHabilitarPermisoCamaraOne} alt="" />
      </li>

      <li>
        <p>Al presionar el candado se desplegará un cuadro en donde indicará si la cámara y/o el micrófono están bloqueados. Presione el botón indicado para activarlo.</p>
        <img src={imgHabilitarPermisoCamaraTwo} alt="" />
      </li>

      <li>
        <p>Se marcará en color azul cuando el micrófono y cámara esten habilitados.</p>
        <img src={imgHabilitarPermisoCamaraThree} alt="" />
      </li>

      <li>
        <p>Presiona el botón "Volver a cargar" para que los cambios tomen efecto.</p>
        <img src={imgHabilitarPermisoCamaraFour} alt="" />
      </li>
    </ol>
  )
};

const MacOsSafariGuide = () => {
  return (
    <ol>
      <li>
        <p>En la esquina izquierda de la barra superior presione la opción <b>"Safari"</b></p>
        <img src={imgHabilitarPermisoSafariCamaraOne} alt="" />
      </li>

      <li>
        <p>En el menú desplegable presione la opción <b>"Configuración para este sitio"</b> o <b>"Configuración para telemedicina.ticblue.cl"</b></p>
        <img src={imgHabilitarPermisoSafariCamaraTwo} alt="" />
      </li>

      <li>
        <p>Se levantará un nuevo cuadro de dialogo, ubiquese en la parte donde diga <b>"Cámara"</b> y <b>"Micrófono"</b> y presione el texto "Preguntar".</p>
        <img src={imgHabilitarPermisoSafariCamaraThree} alt="" />
      </li>

      <li>
        <p>Cambie la opción a <b>"Permitir"</b> tanto para donde dice <b>"Cámara"</b> y <b>"Micrófono"</b></p>
        <img src={imgHabilitarPermisoSafariCamaraFour} alt="" />
      </li>

      <li>
        <p>Luego de realizar los cambios presiona el icono indicado para recargar la página y ver reflejado los cambios.</p>
        <img src={imgHabilitarPermisoSafariCamaraFive} alt="" />
      </li>
    </ol>
  )
};

const AndroidGuide = () => {
  return(
    <ol>
      <li>
        <p>Presiona el icono del candado</p>
        <img src={imgHabilitarPermisoAndroidCamaraOne} alt="" />
      </li>
      <li>
        <p>Presiona la opción <b>"Permisos"</b></p>
        <img src={imgHabilitarPermisoAndroidCamaraTwo} alt="" />
      </li>
      <li>
        <p>Presiona el switch para habilitar la cámara y micrófono</p>
        <img src={imgHabilitarPermisoAndroidCamaraThree} alt="" />
      </li>
      <li>
        <p>Ambas opciones deberían quedar en color commo indica la imagen</p>
        <img src={imgHabilitarPermisoAndroidCamaraFour} alt="" />
      </li>
      <li>
        <p>Presiona el ícono con 3 puntos</p>
        <img src={imgHabilitarPermisoAndroidCamaraFive} alt="" />
      </li>
      <li>
        <p>En el menú que se desplegará presiona el icono indicado para recargar la página.</p>
        <img src={imgHabilitarPermisoAndroidCamaraSix} alt="" />
      </li>
    </ol>
  )
};

const IphoneGuide = () => {
  return(
    <ol>
      <li>
        <p>Presiona el icono indicado en la imágen y luego la opción <b>"Ajustes del sitio"</b></p>
        <img src={imgHabilitarPermisoiPhoneCamaraOne} alt="" />
      </li>

      <li>
        <p>Al presionarló se desplegará un cuadro en donde aparecerá la cámara y el micrófono. Presiona la opción <b>"Preguntar"</b></p>
        <img src={imgHabilitarPermisoiPhoneCamaraTwo} alt="" />
      </li>

      <li>
        <p>Luego presiona la opción <b>"Permitir"</b> y repite el proceso dejando habilitado la cámara y micrófono.</p>
        <img src={imgHabilitarPermisoiPhoneCamaraThree} alt="" />
      </li>

      <li>
        <p>Presiona la opción <b>"Listo"</b></p>
        <img src={imgHabilitarPermisoiPhoneCamaraFour} alt="" />
      </li>

      <li>
        <p>Finalmente recarga la página presionando el ícono indicado para que los cambios tomen efecto.</p>
        <img src={imgHabilitarPermisoiPhoneCamaraFive} alt="" />
      </li>
    </ol>
  )
}