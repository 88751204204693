import React, { Component } from "react";
// import { Link } from "react-router-dom";

// images
import IconPlay from "./assets/images/icon6.svg";


// Components
import AppTeleconsultaMedico from "../teleconsulta/AppTeleconsultaMedico";
import AsignarActividades from "./AsignarActividades/AsignarActividades";
import EntradasFichaPaciente from './AsignarActividades/EntradasFichaPaciente'
import { GetSeguimiento } from "./components/seguimientoActividad/GetSeguimiento";

// Services
import AssignmentServices from "../services/assignment";
import { AgendaModal } from './AgendaMedico/AgendaModal';

// Other
import moment from "moment-timezone";
import { FetchgetRemindersByDates } from "./helpers/FetchgetRemindersByDates";
import {
  Grid,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import {
  AccessTime,
  Today,
} from '@material-ui/icons'

// Analytics
import { AssignmentInd } from "@material-ui/icons";
import LockIcon from '@material-ui/icons/Lock';

import AgendaLateral from "./AgendaMedico/AgendaLateral";

import ReactGA from 'react-ga';
import "react-datepicker/dist/react-datepicker.css";
import configEnv from "../configEnv";
const rutBackOfficeValidos = configEnv.ENV_URL_RUT_VALIDOS_BACK_OFFICE || '';

export default class FichaPaciente extends Component {
  state = {}
  dateInit = "";
  constructor(props) {
    super(props);
    this.getDateIni = moment(props.returnDates.fechaActual).hour(0).minute(0).seconds(0).subtract(6, "days");
    this.getDateFin = moment(props.returnDates.fechaActual).hour(23).minute(59).seconds(59);
    this.state = {
      actividadesRealizadas: 0,
      actividadesNoRealizadas: 0,
      arrayDaysFilter: [],
      diaInicial: this.getDateIni,
      diaFinal: this.getDateFin,
      diaIniH: this.getDateIni,
      diaFinH: this.getDateFin,
      actividadesAsignadas: [],
      diffDays: moment(this.getDateFin).diff(moment(this.getDateIni), "days"),
      loading: true,
      loadingModal: true,
      id: '',
      promedioTareasCompletadas: 0,
      recurso: [],
      totalActividades: 0,
      totalTareas: 0,
      tareasCompletadas: null,
      alertModalIsOpen: false,
      disabledDate: true,
      ocultaVistaTeleconsulta: true,
      mostrarFicha: false,
      selectData: false,
      ocultaVistaAsignar: false,
      ocultaVistaFicha: false,
      active: false,
      selectedView: 'ficha-clinica',
    };

    this.chartContainer = null;
    this.ocultaVistaTeleconsulta = true;
  }
  // componentDidMount : no recibe ningun elemento
  // se utiliza mas que nada cuando tenenmos que llamar setState para poder actualizar el estado del componente
  componentDidMount() {
    ReactGA.pageview("/teleconsulta/medico");
    const params = {
      startOfPeriod: moment(this.state.diaInicial).format("YYYY-MM-DD"),
      endOfPeriod: moment(this.state.diaFinal).format("YYYY-MM-DD"),
    };

    this.getApiData(this.props.sessionData.pacienteId, params);

    this.props.setMedicState({ state: 'Ficha' });
  }
  // Servicio para actividades ( Grafico )

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (  prevState.diaInicial !== this.state.diaInicial || prevState.diaFinal !== this.state.diaFinal ) {
      const params = {
        startOfPeriod: moment(this.state.diaInicial).format("YYYY-MM-DD"),
        endOfPeriod: moment(this.state.diaFinal).format("YYYY-MM-DD"),
      };
      this.getApiData(this.props.sessionData.pacienteId, params);
    }

    // if (prevProps.sessionData.pacienteId !== this.props.sessionData.pacienteId) {
    //   const params = {
    //     startOfPeriod: moment(this.state.diaInicial).format("YYYY-MM-DD"),
    //     endOfPeriod: moment(this.state.diaFinal).format("YYYY-MM-DD"),
    //   };
    //   this.getApiData(this.props.sessionData.pacienteId, params);
    // }

    this.props.setMedicState({ state: this.state.ocultaVistaTeleconsulta ? 'Ficha' : 'Teleconsulta' });

    // console.log(prevState)
    this.getSeguimiento = () => {

      // LLamada al servicio

      return (
        <GetSeguimiento
          arrayDaysFilter={this.state.arrayDaysFilter}
          diaInicial={this.state.diaInicial}
          diaFinal={this.state.diaFinal}
          diffDays={this.state.diffDays}
          chartContainer={this.chartContainer} //ojo
          actividadesRealizadas={this.state.actividadesRealizadas}
          actividadesNoRealizadas={this.state.actividadesNoRealizadas}
          totalActividades={this.state.totalActividades}
          pacienteId={this.props.sessionData.pacienteId}//ojo
          actividadesAsignadas={this.state.actividadesAsignadas}
          renderSaved={this.props.renderSaved}
          props={this.props}
          state={this.state}
          setState={this.setState.bind(this)}
        />
      );
    };
  }

  async getApiData(pacienteId, params) {
    let notificationsForToday = [];
    // Servicio para actividades ( Grafico )

    try {
      // await patientApi
      //   .getResources(pacienteId, params)
      await AssignmentServices
        .getRemindersByDates(pacienteId, params.startOfPeriod, params.endOfPeriod)
        .then(async ({ data }) => {

          if (Array.isArray(data)) {
            data.map(({ _id, days, startOfPeriod, endOfPeriod, assignmentDate }, i) => {
              let reminderObj = {};
              days.map((day, d) => {

                reminderObj = {

                  reminderId: _id,
                  dayId: day._id,
                  dateTime: moment.utc(day.dateTime),
                  completed: day.completed,
                  startOfPeriod,
                  endOfPeriod,
                };

                return notificationsForToday.push(reminderObj);
              });

            });
          }
        })
        .catch((err) => {
          notificationsForToday.push([]);
          console.log("error : ", err);
        });
    } catch (error) {
      console.log(error);
    }


    notificationsForToday.sort(
      (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
    );

    let totaActividades = notificationsForToday.length;
    let completadas = notificationsForToday.filter((r) => r.completed === true).length;
    let noCompletadas = notificationsForToday.filter((r) => r.completed === false).length;

    this.setState({
      totalActividades: totaActividades,
      actividadesRealizadas: completadas,
      actividadesNoRealizadas: noCompletadas,
      arrayDaysFilter: notificationsForToday,
      // actividadesAsignadas: data,
    });
    this.updatePromedioTareasCompletadas();
  }
  // Porcentaje de Tareas Completadas
  updatePromedioTareasCompletadas() {
    const actividadesCompletadas = [];
    const actividadesNoCompletadas = [];
    // let totalActividades = 0;
    // valido cuando no hay ninguna actividad asignada
    if (this.state.arrayDaysFilter.length === 0) {
      actividadesCompletadas.push(0);
      actividadesNoCompletadas.push(0);
    }

    this.setState((state, props) => ({
      loading: false,
      diaInicial: state.diaInicial,
      diaFinal: state.diaFinal,
      tareasCompletadas: "Actividades Completadas",
    }));
    if (this.state.totalActividades === 0) {
      this.setState({
        promedioTareasCompletadas: 0,
      });
    } else {
      this.setState((state, props) => ({
        promedioTareasCompletadas: parseInt(
          (state.actividadesRealizadas * 100) / state.totalActividades
        ),
      }));
    }
    if (this.state.arrayDaysFilter.length === 0) {
      this.setState({
        tareasCompletadas: "Sin actividad Asignada",
      });
    }
  }

  componentWillUnmount() {
    this.state = {};
    this.chartContainer = null;
    this.ocultaVistaTeleconsulta = true;
  }

  render() {

    const handleTeleconsulta = (e) => {
      let estado = true;
      if (this.state.ocultaVistaTeleconsulta) {
        estado = false;
      }

      window.history.replaceState(null, null,  `?APIKey=${this.props.sessionData.apikey}`);

      this.setState((state, props) => ({
        ocultaVistaTeleconsulta: (state.ocultaVistaTeleconsulta = estado),
      }));
      ReactGA.event({ category: "Teleconsulta", action: "Click Botón Teleconsulta" })
    };

    const handleOutTeleconsulta = (e) => {
      console.log("here?");
      this.setState((state, props) => ({
        ocultaVistaTeleconsulta: (state.ocultaVistaTeleconsulta = true),
      }));
    }

    const closeModal = () => {
      this.setState((state, props) => ({
        alertModalIsOpen: (state.alertModalIsOpen = false),
        loadingModal: (state.loadingModal = false),
      }));
    };

    // Double Click
    const onDoubleClick = (e) => {
      console.log("onDoubleClick", e);
      this.setState((state, props) => ({
        selectData: (state.selectData = e),
      }));
    };

    // Select --> evento de click
    const onSelectEvent = (e) => {
      console.log("onSelectEvent", e);

      this.setState((state, props) => ({
        selectData: (state.selectData = e),
      }));
    };

    const classes = makeStyles((theme) => ({
      tooltipFicha: {
        maxWidth: 50,
      }
    }));

    return (
      <div className="d-flex" id="wrapper">
        <div className="border-end bg-white" id="sidebar-wrapper">
          <AgendaLateral
            events={this.props.citas}
            localizer={this.props.localizer}
            onDoubleClick={onDoubleClick}
            onSelectEvent={onSelectEvent}
          />
          {
            this.state.selectData ? <AgendaModal
              event={this.state.selectData}
              psicologo={this.props.psicologo}
              events={this.props.citas}
              // apikey={this.props.apikey}
              setConsulta={this.props.con}
              // setApikey={this.props.setApikey}
              setSelectData={this.props.setSelectData}
              setFechaValida={this.props.setFechaValida}
              setIdDate={this.props.setIdDate}
              setBeforeDate={this.props.setBeforeDate}
              setDateStart={this.props.setDateStart}
              setDateEnd={this.props.setDateEnd}
              visible={false}
            />
              : null
          }
        </div>
        <div id="page-content-wrapper">
          <div className="ficha-paciente-container border">
            <div className="col-12">
              <div className="row row-header-ficha align-items-center">
                <div className="col-5">
                  <div className="ficha-patient-header">
                    <AssignmentInd />
                    <div>
                      <h2>{this.props.sessionData.nombrePaciente}</h2>
                      <span>{this.props.sessionData.rutPaciente}</span>
                    </div>
                  </div>
                </div>
                <div className="col-7 p-2 ficha-patient-header-date">
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-end"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6} className="appointment-date">
                      <span>Atención Nº: <b>{this.props.idDate}</b></span>
                      <div>
                        <span><Today /> {moment(this.props.dateStart).format('DD/MM/YYYY')}</span>
                        <span><AccessTime /> {moment(this.props.dateStart).format('HH:mm')} a {moment(this.props.dateEnd).format('HH:mm')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} className="text-right" style={{borderColor:"black", borderWidth: 1}}>
                        <Tooltip arrow classes={{ tooltip: classes.tooltipFicha }} title="La opción se encuentra habilitada, atención agendada para el día de hoy.">
                          <span>
                            <button
                              className='btn-iniciar mt-2'
                              to={"?APIKey=" + this.props.sessionData.apikey}
                              onClick={handleTeleconsulta}
                            >
                              <img src={IconPlay} width="20" alt="icon_play" className="pr-1" />
                              {this.state.ocultaVistaTeleconsulta ? 'Iniciar Teleconsulta' : 'Cerrar Teleconsulta'}
                            </button>
                          </span>
                        </Tooltip>
                    </Grid>
                  </Grid>
                </div>

                <div className="col-md-12">
                  {!this.state.ocultaVistaTeleconsulta &&
                    <div className="">
                      <AppTeleconsultaMedico
                        location={window.location}
                        handleTeleconsulta={handleOutTeleconsulta}
                        rutMedico={this.props.sessionData.rutMedico}
                        rutPaciente={this.props.sessionData.rutPaciente}
                      />
                    </div>
                  }
                </div>

              </div>

              <div className="row mt-2">
                <div className="col-12 p-0">
                  <ul className="profesional-sub-nav">
                    <li>
                      <button
                        onClick={() => {
                          this.setState((state, props) => ({
                            selectedView: 'ficha-clinica',
                          }));
                          ReactGA.event({ category: "Ver ficha clínica paciente", action: "Click" })
                        }}
                        className={this.state.selectedView === 'ficha-clinica' ? 'menu-active' : ''}
                      >Ficha clínica</button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          this.setState((state, props) => ({
                            selectedView: 'seguimiento-de-actividad',
                          }));
                          ReactGA.event({ category: "Ver seguimiento de actividad", action: "Click" })
                        }}
                        className={this.state.selectedView === 'seguimiento-de-actividad' ? 'menu-active' : ''}
                      >Seguimiento de actividad</button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          this.setState((state, props) => ({
                            selectedView: 'actividades-asignadas',
                          }));
                          ReactGA.event({ category: "Ver actividades asignadas", action: "Click" })
                        }}
                        className={this.state.selectedView === 'actividades-asignadas' ? 'menu-active' : ''}
                      >Actividades asignadas</button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          this.setState((state, props) => ({
                            selectedView: 'asignar-actividad',
                          }));
                          ReactGA.event({ category: "Asignar una actividad", action: "Click" })
                        }}
                        className={this.state.selectedView === 'asignar-actividad' ? 'menu-active' : ''}
                      >Asignar actividad</button>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12">
                  { /* Validación de datos confidenciales en caso de ser un rut permitido */
                    this.props.userContext && this.props.userContext.rut && rutBackOfficeValidos.indexOf(this.props.userContext.rut) !== -1
                      ?
                      <>
                        {this.state.selectedView === 'ficha-clinica' &&
                          <div className="row text-center abs-center-50">
                            <div className="col-md-12 ">
                              
                              <span className="activity-name">
                                <LockIcon /> Información confidencial
                              </span>
                            </div>
                          </div>
                        }
                      </>
                      :
                      <>
                        {this.state.selectedView === 'ficha-clinica' &&

                          <EntradasFichaPaciente
                            rutMedico={this.props.sessionData.rutMedico}
                            nombreMedico={this.props.sessionData.nombreMedico}
                            idMedico={this.props.sessionData.medicoId}
                            idPaciente={this.props.sessionData.pacienteId}
                            render={this.props}
                            rutPaciente={this.props.sessionData.rutPaciente}
                            mostrarFicha={this.state.mostrarFicha}
                          />
                        }
                      </>
                  }

                  {this.state.selectedView === 'seguimiento-de-actividad' &&
                    this.getSeguimiento()
                  }

                  {this.state.selectedView === 'actividades-asignadas' &&
                    <FetchgetRemindersByDates
                      idPaciente={this.props.sessionData.pacienteId}
                      fechaIniWeek={this.state.diaIniH}
                      fechaFinWeek={this.state.diaFinH}
                      tipo={"Historico"}
                      renderSaved={this.props.renderSaved}
                      closeModal={closeModal}
                      filtroFechas={true}
                      state={this.state}
                      setState={this.setState.bind(this)}
                    />
                  }

                  {this.state.selectedView === 'asignar-actividad' &&
                    <AsignarActividades
                      idMedico={this.props.sessionData.medicoId}
                      idPaciente={this.props.sessionData.pacienteId}
                      render={this.props}
                      rutPaciente={this.props.sessionData.rutPaciente}
                      fechaIniWeek={this.state.diaIniH}
                      fechaFinWeek={this.state.diaFinH}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
