import React from 'react';
import imgIconSafari from "./../../assets/images/help/safari.svg";
import imgIconChrome from "./../../assets/images/help/chrome.svg";

const PermisoSinAcceso = () => {
    return(
        <div>
            <div className="page-name mb-4">
                <h1>Oups!</h1>
                <p>El dispositivo o navegador que estás usando <strong>no es compatible</strong> ya que no se puede acceder a la cámara o micrófono posiblemente porque un problema de configuración o de instalación.</p>
            </div>
            <div className="homeRecomendations">
                <div className="row">
                <div className="col-12">
                    <div className="guiastep">
                        <div className="number">1</div>
                        <div className="guiastep-title">
                            <h3>Desde un iPhone</h3>
                        </div>
                        <div className="guiastep-content">
                            <p>Si estás usando un iPhone, debes obligatoriamente utilizar <em>Safari</em>. Busca en las aplicaciones de tu móvil el siguiente icono <img src={imgIconSafari} width="24" /> y presionalo.</p>
                                <p>Luego digita la página web: <em>app.redblu.cl</em></p>
                        </div>
                    </div>

                    <div className="guiastep mt-4">
                        <div className="number">2</div>
                        <div className="guiastep-title">
                            <h3>Desde celular Android</h3>
                        </div>
                        <div className="guiastep-content">
                            <p>Te recomendamos que utilices el navegador de <em>Google Chrome</em> . Lo puedes reconocer en el listado de aplicaciones de tu dispositivo por medio del siguiente icono <img src={imgIconChrome} width="24" /></p>
                                <p>Luego de que lo habrás ingresa a la pagina web: <em>app.redblu.cl</em></p>
                        </div>
                    </div>

                    <div className="guiastep mt-4">
                        <div className="number">3</div>
                        <div className="guiastep-title">
                            <h3>Desde Computador</h3>
                        </div>
                        <div className="guiastep-content">
                            <p>Tu cámara web o micrófono no está instalado correctamente o se encuentra bloqueado en la configuración del navegador que estás usando.</p>
                            <p>Si estás a la espera de una atención en los próximos minutos, te recomendamos que utilices <em>otro dispositivo (Celular o tablet)</em> ya que corregir este tipo de problemas te puede llevar varios minutos.</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default PermisoSinAcceso;