import React, { useState} from "react";
import { Button, Tooltip } from '@material-ui/core';
import TooltipResponsive from './../../utils/TooltipResponsive';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import { Mic, MicOff, Videocam, VideocamOff } from '@material-ui/icons';
import AjustesPopUp from './AjustesPopUp';
import './PlayerControllers.css';
import AgoraRTC from "agora-rtc-sdk-ng";
var isSharingEnabled = false
const PlayerControllers = ({
    switchCamera, 
    switchMicrophone, 
    localVideoTrack, 
    localAudioTrack, 
    localCameras, 
    localMicrophones, 
    joinState,
    client,
    localSharingScreen,
    setIsSharingScreen,
    isSharingScreen,
}) => {
    const [ enableVideo, setEnableVideo ] = useState(true); 
    const [ audioMuted, setAudioMuted ] = useState(true); 
    const [ sharingUse, setSharingUser ] = useState(false); 
    const [ levelSound, setLevelSound ] = useState(0);
    const [ isButtonDisabled, setIsButtonDisabled ] = useState(false);
    const [ camaraSeleccionada, setCamaraSeleccionada ] = useState("");
    const [ microfonoSeleccionado, setMicrofonoSeleccionado ] = useState("");

    /**
     * handleCameraSelection
     * @function switchCamera cambia la transmisión al dispositivo seleccionado
     *
     */
    const handleCameraSelection = async (e) => {
        setIsButtonDisabled(true);
        setCamaraSeleccionada(e.target.value);
        await switchCamera(e.target.value);
        setIsButtonDisabled(false);
    };

    /**
     * handleMicrophoneSelection
     * @function switchMicrophone cambia la transmisión al dispositivo seleccionado
     *
     */
    const handleMicrophoneSelection = async (e) => {
        setIsButtonDisabled(true);
        setMicrofonoSeleccionado(e.target.value);
        await switchMicrophone(e.target.value);
        setIsButtonDisabled(false);
    };

    /**
     * handleMuteVideo
     * @function setIsButtonDisabled Habilita o deshabilita el botón
     * @function localVideoTrack.setEnabled Habilita o deshabilita localVideoTrack en curso.
     * @function setEnableVideo Actualiza el state enableVideo
     * @function setIsButtonDisabled Vuelve a habilitar el botón
     */
    const handleMuteVideo = async () => {
        if(localVideoTrack){
            setIsButtonDisabled(true);
            await localVideoTrack.setEnabled(!enableVideo);
            setEnableVideo(!enableVideo);
            setIsButtonDisabled(false);
        }
    }

    /**
     * handleMuteVideo
     * @function setIsButtonDisabled Habilita o deshabilita el botón
     * @function localAudioTrack.setEnabled Habilita o deshabilita localVideoTrack en curso.
     * @function setAudioMuted Actualiza el state enableVideo
     * @function setIsButtonDisabled Vuelve a habilitar el botón
     */
    const handleMuteAudio = async () => {
        if(localAudioTrack){
            setIsButtonDisabled(true);
            await localAudioTrack.setEnabled(!audioMuted);
            setAudioMuted(!audioMuted);
            setIsButtonDisabled(false);
        }
    }

    // const handleSharedVideo = async () => {
        
    //     if (isSharingEnabled === false) {
    //         // Create a screen track for screen sharing.
    //         localVideoTrack.screenTrack = await AgoraRTC.createScreenVideoTrack( { } );
    //         // Stop playing the local video track.
    //         // localVideoTrack.localVideoTrack.stop();
    //         handleMuteVideo();
    //         // Unpublish the local video track.
    //         // await client.unpublish(localVideoTrack.localVideoTrack);
    //         // Publish the screen track.
    //         // await client.publish(localVideoTrack.screenTrack);
    //         // Play the screen track on local container.
    //         // localVideoTrack.screenTrack.play(localVideoTrack);
    //         // Update the button text.
    //         // document.getElementById(`inItScreen`).innerHTML = "Stop Sharing";
    //         // Update the screen sharing state.
    //         isSharingEnabled = true;
    //     } else {
    //         // Stop playing the screen track.
    //         localVideoTrack.screenTrack.stop();
    //         // Unpublish the screen track.
    //         await client.unpublish(localVideoTrack.screenTrack);
    //         // Publish the local video track.
    //         await client.publish(localVideoTrack.localVideoTrack);
    //         // Play the local video on the local container.
    //         localVideoTrack.localVideoTrack.play(localVideoTrack);
    //         // Update the button text.
    //         // document.getElementById(`inItScreen`).innerHTML = "Share Screen";
    //         // Update the screen sharing state.
    //         isSharingEnabled = false;
    //     }
    // }



    /**
         * handleShareScreen
         * @function setIsButtonDisabled Habilita o deshabilita el botón
         * @function localVideoTrack.setEnabled Habilita o deshabilita localVideoTrack en curso.
         * @function setSharingUser Actualiza el state enableVideo
         * @function setIsButtonDisabled Vuelve a habilitar el botón
    */

    const handleShareScreen = async () => {
        if (!isSharingScreen && localAudioTrack) {
            setIsButtonDisabled(true);
            await localVideoTrack.setEnabled(false);
            setIsSharingScreen(true);
            setSharingUser(true);

            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
            await localVideoTrack.setEnabled(true);
            setIsSharingScreen(false);
            setSharingUser(false);

            setIsButtonDisabled(false);
        }
    }



    return(
        <>
        {joinState &&
            <div className="local-controllers col-12">
                <div className="row align-items-center">
                    <div className="col-4 text-start">
                        <AjustesPopUp 
                            localCameras={localCameras}
                            localMicrophones={localMicrophones}
                            handleCameraSelection={handleCameraSelection}
                            handleMicrophoneSelection={handleMicrophoneSelection}
                            camaraSeleccionada={camaraSeleccionada}
                            microfonoSeleccionado={microfonoSeleccionado}
                        />
                    </div>
                    <div className="col-8 text-left">
                            {/* <button className="btn btn-primary" type="button" onClick={handleSharedVideo}>Share Screen</button> */}
                        {localVideoTrack ?
                            <Tooltip title={localVideoTrack.enabled ? 'Presiona para Desactivar' : 'Presiona para Habilitar'}>
                                <span>
                                    <Button 
                                    onClick={handleMuteVideo}
                                    disabled={isButtonDisabled}>
                                        {localVideoTrack.enabled ? <Videocam className="on"/> : <VideocamOff className="off"/>}
                                    </Button>
                                </span>
                            </Tooltip>
                            :
                            <TooltipResponsive title={"No se ha detectado que hayas iniciado una cámara. Intenta cambiando la cámara en Ajustes"}>
                                <span>
                                    <Button 
                                    disabled={true}>
                                        <VideocamOff className="disabled"/>
                                    </Button>
                                </span>
                            </TooltipResponsive>
                         }
    
                        {localAudioTrack ?
                            <Tooltip  title={localAudioTrack.enabled ? 'Presiona para Desactivar' : 'Presiona para Habilitar'}>
                                <span>
                                    <Button 
                                    onClick={handleMuteAudio}
                                    disabled={isButtonDisabled}>
                                        {localAudioTrack.enabled ? <Mic className="on"/> : <MicOff className="off"/>}
                                    </Button>
                                </span>
                            </Tooltip>
                            :
                            <TooltipResponsive title={"No se ha detectado el micrófono, intenta cambiandolo presionado el botón ajustes"}>
                                <span>
                                    <Button 
                                    disabled={true}>
                                        <MicOff className="disabled"/>
                                    </Button>
                                </span>
                            </TooltipResponsive>
                            }
                            {/* TODO: Editar */}
                            <Tooltip title={isSharingScreen ? 'Presiona para dejar de Presentar' : 'Presiona para Presentar'}>
                                <span>
                                    <Button
                                        onClick={handleShareScreen}
                                        disabled={isButtonDisabled}>
                                        {sharingUse ? <PresentToAllIcon className="on" /> : <PresentToAllIcon className="disabled" />}
                                    </Button>
                                </span>
                            </Tooltip>
    
                    </div>
                </div>
            </div>
        }
        </>
    )
   
}

export default PlayerControllers;