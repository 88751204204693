// import axios from 'axios';
import API from "../../services/api";
// import URLS from "../../URLS";
import { utcToZonedTime } from 'date-fns-tz';
import { useCustomers } from "../hooks/useCustomers";

const citasService = {
  // getCitas: function ({ token, email }) {
  //   return API.axios({
  //     method: 'get',
  //     url: `https://redblu.cl/calendario-profesional/`,
  //     params: { token, email }
  //   });
  // },

  getCitas: function({ id }) {
    return new Promise((resolve, reject) => {
      API.axios({
        method: "get",
        url: "/medics/" + id + "/citasWoocommerce",
        // params: { token, email }
      })
        .then((result) => {
          // console.log("result citasWoocommerce :>> ", result.data);
          // Se agrega logica en caso de no encontrar resultados
          if (result.data.length === 0) {
            resolve( { message: 'No hay citas disponibles'});
          }
            
          
          if (Array.isArray(result.data)) {
            resolve(
              result.data.map((c) => {

                let title     = "";
                let billing   = "";
                let detail    = {
                                  patientRut: "",
                                  patientId: "",
                                  nombrePaciente: "",
                                  email: "",
                                  phone: "",
                                  region: "",
                                  ciudad: "",
                                  calle: "",
                              };
            
             
                if (c.customerZero) {
                  title     = (c.customerZero.billing.first_name || '') + " " + (c.customerZero.billing.last_name || '');
                  billing   = c.customerZero.billing || {};

                  useCustomers({
                    billing,
                    detail,
                    title,
                  });

                  detail.patientRut = c.rut;
                }
                if (c.redbluNoUser) {
                  title = (c.redbluNoUser.billing.first_name || '') + " " + (c.redbluNoUser.billing.last_name || '');
                  billing = c.redbluNoUser.billing || {};

                  useCustomers({
                    billing,
                    detail,
                    title,
                  });

                  detail.patientRut = c.rut;
                  detail.patientId = null;
                }
                if (c.customer) {
                  title = (c.customer.billing.first_name || '') + " " + (c.customer.billing.last_name || '');
                  billing = c.customer.billing || {};
                  useCustomers({
                    billing,
                    detail,
                    title,
                  });
                }

                if (c.redbluUser) {
                  // console.log(c.redbluUser.rut )
                  detail.patientRut = c.redbluUser.rut ;
                  detail.patientId = c.redbluUser._id ;
                } 

     
                // if (c.dataExtra && !detail.patientId) {
                //   detail.patientId = c.dataExtra.patientId;
                // }
                if (!title) {
                  // console.log('pasa --> ',c)
                  return {};
                } else {
                  let data = {
                    ...c,
                    title: title,
                    start: utcToZonedTime(new Date(c.start * 1000), ""),
                    end: utcToZonedTime(new Date(c.end * 1000), ""),
                    detail: detail,
                  };
                  return data;
                }

              })
            );
          } else {
            reject(result);
          }
        })
        .catch((err) => {
          console.log("err get citas woocommerce :>> ", err);
          reject(err);
        });
    });
  },
};

export default citasService;
