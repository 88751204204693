import ProfesionalService from "../../../services/ProfesionalService";

export const getSelectAniosExperiencia = async (setSelectAniosExperiencia, setErrors?) => {
  try {
    const response = await ProfesionalService.getAniosExperiencia();
    if (response) {

      setSelectAniosExperiencia(response);
    }
  } catch (error) {
    setErrors(error)
    console.log('error : ', error)
  }
};

export const getSelectTituloProfesional = async (setSelectTituloProfesional, setErrors?) => {
  try {
    const response = await ProfesionalService.getTituloProfesional();
    if (response ) {
      setSelectTituloProfesional(response);
    }
  } catch (error) {
    setErrors(error)
    console.log('error : ', error)
  }
};

export const getSelectCasaEstudio = async(setSelectCasaEstudio, setErrors?) => {
  try {
    const response = await ProfesionalService.getCasaEstudio();
    if (response) {
      setSelectCasaEstudio(response);
    }
  } catch (error) {
    setErrors(error)
    console.log('error : ', error)
  }
};

export const getSelectEspecialidadesDestacadas = async (setSelectEspecialidadesDestacadas, setErrors?) => {
  try {
    const response = await ProfesionalService.getEspecialidadesDestacadas();
    if (response) {
      setSelectEspecialidadesDestacadas(response);
    }
  } catch (error) {
    setErrors(error)
    console.log('error : ', error)
  }
};

export const getSelectOtrasEspecialidades = async (setSelectOtrasEspecialidades, setErrors?) => {
  try {
    const response = await ProfesionalService.getOtrasEspecialidades();
    if (response) {
      setSelectOtrasEspecialidades(response);
    }
  } catch (error) {
    setErrors(error)
    console.log('error : ', error)
  }
};

export const getSelectEdadesAtencion = async (setSelectEdadesAtencion, setErrors?) => {
  try {
    const response = await ProfesionalService.getEdadesAtencion();
    if (response) {
      setSelectEdadesAtencion(response);

    }
  } catch (error) {
    setErrors(error)
    console.log('error : ', error)
  }
};
export const getSelectTecnicaTerapia = async (setSelectTecnicaTerapia, setErrors?) => {
  try {
    const response = await ProfesionalService.getTecnicaTerapia();
    if (response) {
      setSelectTecnicaTerapia(response);

    }
  } catch (error) {
    setErrors(error)
    console.log('error : ', error)
  }
};
