import React, { Component } from "react";
// Components
import Button from "../components/button/Button";
import { InfoFicha } from "../components/actividadFicha/InfoFicha";
// Images
// Icons
// Services
import { Add } from '@material-ui/icons';
import patientAPI from "../../services/patients";
import { formatWithOptions } from 'date-fns/fp'
import { es } from 'date-fns/locale'

import "./styles.css";
// SLIDER
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from 'react-slick';

// Analytics
import ReactGA from 'react-ga';

export default class EntradasFichaPaciente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fichas: [],
      fichaActiva: null,
      loading: true,
      showFicha: false,
      fichasFiltered: [],
      searchText: "",
      pagination: 30,
      updated:false
    };
    this.timebuscar = null;
  }

  componentDidMount() {
    patientAPI.getFichaPatient(this.props.rutPaciente,this.props.rutMedico)
      .then(result => {

        const dateToString = formatWithOptions({ locale: es }, 'd MMMM yyyy, HH:mm')

        let dataFicha = result.data;
        dataFicha.map(f => {
          let fechaTemp = new Date(f.fecha);
          return f.fecha = dateToString(fechaTemp);
        });

        this.setState({
          loading: false,
          fichas: dataFicha || [],
          fichaActiva: result.data.length - 1
        });
      })
      .catch((err) => {
        this.setState({ fichas: [] });
      });
  }
  // En caso de modificación
  componentDidUpdate(prevProps) {
    if (prevProps.render.renderSaved !== this.props.render.renderSaved) {
      patientAPI.getFichaPatient(this.props.rutPaciente, this.props.rutMedico)
        .then(result => {
          const dateToString = formatWithOptions({ locale: es }, 'd MMMM yyyy, HH:mm')

          let dataFicha = result.data;
          dataFicha.map(f => {
            let fechaTemp = new Date(f.fecha);
            return f.fecha = dateToString(fechaTemp);
          });

          this.setState({
            loading: false,
            fichas: dataFicha || [],
            fichaActiva: result.data.length - 1
          });
         
        })
        .catch((err) => {
          this.setState({ fichas: [] });
        });
    }
    return null;
  }

  componentWillUnmount() {
    this.state = {};
    this.timebuscar = null;
  }
  
  verFicha = (fichaIndex, fichaNombreMedico, lengthFilterFicha) => (e) => {
    e.preventDefault();
    this.setState({ fichaActiva: fichaIndex, showFicha: false  });
    ReactGA.event({ category: "Ver Ficha", action: fichaNombreMedico });
  };

  // Muestra el contenido de la ficha activa
  getContentFicha = () => {
    const ficha = this.state.fichas[this.state.fichaActiva];

      return (
        <InfoFicha
          {...ficha}
          key={this.state.fichaActiva}
          button={this.state.showFicha}
          show={this.state.showFicha}
          patientId={this.props.idPaciente}
          medicUser={this.props.idMedico}
          rutMedico={this.props.rutMedico}
          nombreMedico={this.props.nombreMedico}
          renderSaved={this.props.render.renderSaved}
          render={this.props.render.saved}
          rutPaciente={this.props.rutPaciente}
          props={this.props}
          filterFichas={this.state.fichas}
        />
      );
  
  };


  handlerBuscarFicha = (ev) => {
    // ev.preventDefault();
    ReactGA.event({ category: "Ver ficha paciente", action: "Buscar entradas" });
    const inputVal = ev.target.value;
    clearTimeout(this.timebuscar);
    this.timebuscar = setTimeout(() => this.buscarFicha(inputVal), 500);
  };

  // Busca Ficha Search
  buscarFicha = (inputValue) => {
    if (!inputValue || !inputValue.trim()) {
      return this.setState({ fichasFiltered: [], searchText: "" });
    }

    const fichasFiltered = this.state.fichas.filter((f) => {
      try {
        let regx = new RegExp(inputValue, "im");
        if (regx.test(f.titulo)) {
          return true;
        }
        if (regx.test(f.texto)) {
          return true;
        }
        return null;
      } catch (error) {
        return false;
      }
    });
    // console.log("fichasFiltered: ", fichasFiltered);
    this.setState({ fichasFiltered: fichasFiltered, searchText: inputValue });
  };

  toggleShow = () => {
    this.setState((currentState) => ({ 
      showFicha: !currentState.showFicha,
      fichaActiva: -1
    }));

    if (this.state.showFicha === true) {
      ReactGA.event({ category: "Ver ficha paciente", action: "Click ver ficha paciente" });
    } else {
      ReactGA.event({ category: "Agregar entrada ficha paciente", action: "Click agregar entrada ficha paciente" });
    }
  }

  render() {
    const filterFichas = this.state.searchText
      ? this.state.fichasFiltered
      : this.state.fichas;

    return (
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="row">
              <div className="col-3">
                <div>
                  <button
                    onClick={this.toggleShow}
                    className="btn-white btn-add"
                  >
                    Nuevo registro <Add />
                  </button>
                </div>
                <div className="scrollbar">
                    {filterFichas.length === 0 ?
                      <p className="alert alert-secondary">
                        Sin registros
                      </p>
                    :
                      <>
                        {filterFichas.map((ficha, index) => (
                          <div
                            className="item-ficha-clinica"
                            key={index}
                          >
                            <Button
                              key={index}
                              name={ficha.nombreMedico}
                              onClick={this.verFicha(index, ficha.nombreMedico, filterFichas.length)}
                              active={this.state.fichaActiva === index}
                              shortDescription={ficha.fecha}
                            />
                          </div>
                        ))}
                      </>
                    }
                </div>
              </div>
              <div className="col-9">
                {this.getContentFicha()}
              </div>
          </div>
        </div>
      </div>
    );
  }
}
