const Dictionary = {

  //Strings for roles
  ROLE: {
    PROFESIONAL: 'PROFESIONAL',
    PATIENT:'PATIENT' 
  }

};


export default Dictionary;