import React from 'react';
import Accordion from '../../../components/Accordion';
import imgGuiaIphone1 from "./../../../assets/images/help/guiaIphone1.png";
import imgGuiaIphone2 from "./../../../assets/images/help/guiaIphone2.png";
import imgGuiaIphone3 from "./../../../assets/images/help/guiaIphone3.png";
import imgGuiaIphone4 from "./../../../assets/images/help/guiaIphone4.png";

const GuiaIphone = () => {
    return(

        <Accordion
            id={"iphone"}
            titulo={"Teléfono iPhone"}
            descripcion={"Presiona para ver las indicaciones"}
        >
            <div className="guiastep">
                <div className="number">1</div>
                <div className="guiastep-title">
                    <h3>Presionar las letras</h3>
                </div>
                <div className="guiastep-content">
                    <p>Presiona el botón indicado en la imagen.</p>
                    <img src={imgGuiaIphone1} width="100%" className="border-image" />
                </div>
            </div>

            <div className="guiastep mt-2">
                <div className="number">2</div>
                <div className="guiastep-title">
                    <h3>Presionar Configuración del sitio</h3>
                </div>
                <div className="guiastep-content">
                    <img src={imgGuiaIphone2} width="100%" className="border-image" />
                </div>
            </div>

            <div className="guiastep mt-2">
                <div className="number">3</div>
                <div className="guiastep-title">
                    <h3>Habilitar permisos</h3>
                </div>
                <div className="guiastep-content">
                    <p>Presiona la opción de Cámara para desplegar las opciones y presiona el botón de permitir.</p>
                    <img src={imgGuiaIphone3} width="100%" className="border-image mb-2" />

                    <p>Repite el paso anterior para la opción del micrófono</p>
                    <img src={imgGuiaIphone4} width="100%" className="border-image mb-2" />

                    <p>Luego de haber terminado, <strong>te recomendamos que recargues la página</strong> para que los cambios sean tomados.</p>
                </div>
            </div>
        </Accordion>
    )
}


export default GuiaIphone;