import API from "./api";
import URLS from "../URLS";

export const datesService = {


  getFechas: function (params) {
    return API.axios({
      method: "get",
      url: URLS.tokenredgesam + "/getFechas", 
      params: params,
    });
  },

  

};

export default datesService;
