// import axios from 'axios';
import API from "./api";
import URLS from "../URLS";


const patientsCitasService = {

  getCitas: function ({ id }) {
    return API.axios({
      method: "get",
      url: URLS.patients + "/" + id + "/citasWoocommerce",
      //params: { token, email }
    })
  },
};

export default patientsCitasService;
