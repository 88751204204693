export const settingsSlider = {
  dots: true,
  lazyLoad: false,
  infinite: false,
  focusOnSelect: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  rows: 2,
  slidesPerRow: 1,
  adaptiveHeight: false,
  className: "center",
  vertical: false,
  verticalSwiping: false,
  appendDots: dots => (
    <div
      style={{
        backgroundColor: "rgb(238, 238, 238)",
        borderRadius: "1px",
        padding: "5px",
        margin:'0px',
        marginBottom:'3px',
      }}
    >
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  ),
  customPaging: i => (
    <div
      className="hover-slider"
      style={{
        width: "27px",
        color: "#4354FE",
        padding: "0px",
        borderRadius: "50%",
        border: "1px solid #4354FE",
        transition:'ease 0.1s'
      }}
    >
      {i + 1}
    </div>
  ),
  
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
        dots: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false
      }
    }
  ],

};