import React, { useContext, useEffect, useState } from "react";
import imgLogo from "../../assets/images/redblu.png";
import imgIconNextWhite from "./../../assets/images/iconos/icono-white-next.svg";
import DisplayAppointments from "./DisplayAppointments";
import { useRouteMatch, Link } from "react-router-dom";
import patiensService from "../../services/patients";
import DetectUserGetMediaFeature from "./DetectGetUserMediaFeature";
import ReactGA from 'react-ga';
import RecomendedResource from './RecomendedResource';
import {
   IconButton,
   Divider
} from '@material-ui/core';
import { EventAvailable, ExitToApp, NavigateNext } from '@material-ui/icons';
import PWAPrompt from 'react-ios-pwa-prompt';
import InstallPWA from "../../components/Pwa";
import Modal from "react-modal";
import { UserContext } from "../../context/UserContext";
import configEnv from "../../configEnv";

const customModalStyles = {
   content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
   },
};
const Home = ({ handleEnEspera, handleLogout }) => {

  const { user, setAppointment }  = useContext(UserContext);

   const [accesoACamara, setAccesoACamara] = useState(true);
   const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);

   const rutBackOfficeValidos = configEnv.ENV_URL_RUT_VALIDOS_BACK_OFFICE || '';
   const match = useRouteMatch();
   ReactGA.pageview(match.path);
   ReactGA.event({ category: "HomeTemporal", action: "HomeTemporal" });

   const verificacionUserMedia = (citaId) => {
      handleEnEspera(citaId);
   };

   useEffect(() => {
      patiensService
         .setLogActivityUser(user.rut, "En pantalla de Inicio")
         .then((response) => { });
   }, [user.rut])
   
   return (
      <>
         {!accesoACamara &&(
            <DetectUserGetMediaFeature
               activo={true}
               setAccesoACamara={setAccesoACamara}
            />
         )}
      { user  && (
         <div className="hoy-component fullwidth middday-bg animated fadeIn slow homev2">
            <div className="header-redgesam col-12">
               <div className="row pb-2 align-items-center">
                  <div className="col-3 pr-0 col-sm-4 logo-corporativo">
                     <img
                        src={imgLogo}
                        width="90%"
                        className="img-fluid"
                        alt="RedBlu Logo"
                     />
                  </div>
                  <div className="col-9 col-sm-8 text-right pacienteCuenta">
                     <div className="accountDetails">
                        <span className="rutAccount">{user.rut}</span>
                        <IconButton data-tour="cerrarsesion" aria-label="delete" onClick={handleLogout}>
                           <ExitToApp className="text-danger" /> 
                           <span className="btn-salir">Cerrar sesión</span>
                        </IconButton>
                     </div>
                  </div>
               </div>
            </div>
            <div className="enter-wait-room pt-3 pb-2 px-2">
               <div className="row py-2 header-home-temporal">
                  <div className="col-md-4 col-sm-4 col-12 pb-md-0 pb-1 pr-0">
                    <div className="col-12">
                      <h1>Hola, {user.firstname}</h1>
                    </div>
                  </div>
                  {/* <div className="col-md-4 col-sm-4 col-6 pb-md-0 pl-0 pr-0 pb-2">
                     <NotificacionWebPush />
                  </div> */}
                  </div>
               </div>
               
               {rutBackOfficeValidos.indexOf(user.rut) !== -1 && (
                  <div className="col-md-6 col-sm-6 col-12 pb-md-0 pb-2 shad">
                    <sup>Sección exclusiva para equipo de backoffice</sup>
                    <button
                        className={`
                        full-width btn-submit 
                          ${window.location.pathname === '/listado-profesionales' ? 'active' : ''}
                        `}
                        onClick={() => window.location.pathname = '/listado-profesionales'}>
                        <EventAvailable className="mr-2" />
                        Revisión Profesionales

                    </button>
                  </div>
                )
               }
            <div className="col-12">
               <DisplayAppointments 
                  onClickWaitingRoom={verificacionUserMedia}
                  patient={user} />
            </div>

            <div className="col-12 mt-3">
              <div className="test-teleconsulta">
                <div className="row align-items-center">
                  <div className="col-sm-7 col-12">
                    <h3>Prueba de teleconsulta</h3>
                    <p className="mb-2">Haz una prueba para verificar que tu equipo está en óptimas condiciones</p>
                  </div>
                  <div className="col-sm-5 col-12">
                    <Link to="/paciente/probar-teleconsulta" className="btn-submit full-width btn">
                      Iniciar prueba
                      <NavigateNext />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mt-3">
              <InstallPWA  />
              <PWAPrompt />
            </div>

            <div className="col-12">
               <Divider />
            </div>

            <RecomendedResource />
            <div className="footerPush"></div>
            </div>
         )}
      </>
   );
};

export default Home;
