import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { ZoomIn } from '@material-ui/icons';
// Components
import { AsignarRecurso } from './AsignarRecurso';
import { OpcionesRecurso } from './OpcionesRecurso';
// Hooks
import { useForm } from '../../hooks';
import { UseReturnDates } from '../../../Patient/hooks/UseReturnDates';
// Services
import { getSocketClient } from '../../../services/socket';
import AssignmentServices from '../../../services/assignment';
// Hours
// import getHours from 'date-fns/getHours'
// import getMinutes from 'date-fns/getMinutes';
// Styles
import { customModalStyles } from '../../utils/Modal';
import moment from "moment-timezone";
// Helpers
import { optionsPeriodicity } from '../../helpers/OptionSelect';

import authentication from '../../../services/authentication';



export const InfoRecurso = (props) => {

  const { fechaActual } = UseReturnDates();

  const dateAssign = moment(fechaActual).hours(0).minutes(0).seconds(0).millisecond(0).toDate();

  const dateCurrentDay = moment(fechaActual).hours(0).minutes(0).seconds(0).millisecond(0).toDate();

  const dateNextDay = moment(fechaActual).hours(0).minutes(0).seconds(0).millisecond(0).add(1, 'days').toDate();

  const [startDate, setStartDate] = useState(dateCurrentDay);

  const [endDate, setEndDate] = useState(dateNextDay);

  const [time, setTime] = useState(moment(fechaActual).toDate());

  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);

  const [alertModalText, setAlertModalText] = useState("");

  const [alertSuccess, setAlertSuccess] = useState(false);

  const [guardado, setGuardado] = useState("");



  const [formValues, handleInputChange, reset, errors] = useForm({
    activityName: props.name,
    repeat: 1,
    periodicity: "",
  });
  const {
    activityName,
    repeat,
    periodicity,
  } = formValues;

  


  const onChangeHour = (hora) => {
    setTime(hora);
  };

  useEffect(() => {
    props.render(moment(fechaActual).toDate());
  }, [guardado, fechaActual])




  const submitHandler = async (e) => {
    e.preventDefault();


    if (isFormValid()) {

      let data = {
        medicUser: props.medicUser, // obtener id de medico logeado
        patient: props.patientId, // obtener id de paciente logeado
        resource: props._id,
        isActive: props.active,
        pushNotifications: false,
        notificationCategory: props.category.map((c) => c.nameCategory)[0],
        message: null,
        urlname: null,
        assignmentDate: dateAssign,
        startOfPeriod: startDate,
        endOfPeriod: endDate,
        timesInTheDay: [
          {
            hour: 9, // se asignaran todas las actividades a las 9 de la mañana
            minute: 0,
          },
        ],
        data: {
          activityName: activityName,
          repeat: repeat,// 1 repetición por defecto
          pacientInstructions: "",
          objective: props.shortDescription,
          externalLink: props.media.url,
          externalLinkDescription: "",
          typeLink: props.media.typeMedia,
        },
      };
      //  console.log(data);
      // Asignando recurso
      try {
        await AssignmentServices.assignResource(data.patient, data).then(

          async (res) => {
            // console.log(res);
  
            try {
              setAlertModalText(`Se ha asignado correctamente`);
              setAlertModalIsOpen(true);
              setAlertSuccess(true);
              let socket = getSocketClient();
              // console.log(socket);
               socket.emit('assignment', { type: 'asignment', value: 'true' }, () => console.log('mensajeEmit'));
            } catch (error) {
              console.log(error);
            }
          }
        );
        setGuardado(moment(fechaActual).toDate());
        setTime(moment(fechaActual).toDate());
        sendActivityEmail(props.rutPaciente, activityName);
        setStartDate(dateCurrentDay);
        setEndDate(dateNextDay);
        reset();

      } catch (error) {
        console.log(error);
      }

    }


  };

  const sendActivityEmail = (patientRut, activityName) => {
    authentication.restorePasswordEmail(patientRut, "3", activityName);
  };
  // validación del formulario
  const isFormValid = () => {

    // if (periodicity.trim().length === 0) {
    //    console.log(errors.periodicity);
    //    setAlertSuccess(false);
    //    setAlertModalText(errors.periodicity);
    //    setAlertModalIsOpen(true);
    //    return false;
    // }
    return true;
  };

  // SE DEJA PARA UN FUTURO CUANDO SE INSERTE EL VIDEO O PDF EN LA MISMA PAGINA
  // const rendermediaPreview = ({ media, image, name }) => {

  //   if (media.typeMedia === 'PDF') {
  //     return (
  //       <a href={media.url} download={name} className="btn-download" >
  //         <img src={image} alt={name} className="img-fluid mb-1" />
  //         <div className="text-center">
  //           <ZoomIn className="color-primario mr-1" /> Ver </div>
  //       </a>)
  //   }
  //   if (media.typeMedia === 'YOUTUBE') {
  //     return (<iframe src={media.url}
  //       frameborder='0'
  //       allow='autoplay; encrypted-media'
  //       title='video'
  //     />)
  //   }
  //   if (image) {
  //     return <img alt={name} src={image} width="100%" />
  //   }

  //   return <iframe src={media.url} />

  // }

  const rendermediaPreview = ({ media, image, name }) => {

    let btnName = "Ver el recurso";

    // if (media.typeMedia === 'PDF') {
    //   btnName = ""
    // }
    if (media.typeMedia === 'YOUTUBE') {
      btnName = "Ver video"
    }


    return <a href={props.media.url} download={props.name} className="btn-download" target="_blank" >
      <div className="text-center">
        <ZoomIn className="color-primario mr-1" /> <span style={{ fontSize: 'larger' }}>{btnName} </span>
      </div>
    </a>

  }

  return (

    <div className="col-md-12">
      {props.button ? (
        <AsignarRecurso
          key={props._id}
          {...props}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          optionsPeriodicity={optionsPeriodicity}
          render={props.render}
        />
      ) : (
          <form className="animated fadeIn" onSubmit={submitHandler}>
            <div className="row pt-3 animated fadeIn">

              <div className="col-md-12 text-left mt-2 mb-2" >
                {rendermediaPreview(props)}
              </div>

              <div className="col-md-12 text-left mt-2">

                <div className="row">
                  <div className="col-12">
                    <span className="titulo-recurso">{props.name}</span>
                    <p className="descripcion-recurso">{props.longDescription}</p>
                  </div>
                  <div className="col-sm-4 col-12">
                    <p><strong>Duración :</strong> {props.extraDetail.duration}</p>
                  </div>
                  <div className="col-sm-8 col-12">
                    <p><strong>Frecuencia :</strong>{" "}{props.extraDetail.suggestFrecuency}</p>
                  </div>
                  <div className="col-sm-6 col-12">
                    <p className="font-weight-bold text-dark">Categorías</p>
                    <div className="btn-group">
                      {props.category.map((cat, i) => (
                        <button
                          type="button"
                          className=" btn-secondary btn-sm p-1 ml-2  disabled"
                          key={i}
                        >
                          {cat.nameCategory}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <OpcionesRecurso
                key={props._id}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                time={time}
                onChangeHour={onChangeHour}
                handleInputChange={handleInputChange}
                errors={errors}
                repeat={repeat}
                periodicity={periodicity}
                optionsPeriodicity={optionsPeriodicity}
                fechaActual={fechaActual}
                {...props}
                row={'row'}
              />
            </div>

            <Modal
              isOpen={alertModalIsOpen}
              style={customModalStyles}
              onRequestClose={() => setAlertModalIsOpen(false)}
            >
              <div className="alertModal">
                <h1 className={(alertSuccess) ? 'text-tele' : 'text-dark'}>{alertModalText}</h1>
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-submit btn-lg"
                    onClick={() => setAlertModalIsOpen(false)}
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </Modal>

        </form>
      )}
    </div>
  );
};
