import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// Routes
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoutes } from "./PublicRoutes";

// Pages
import Login from "../Pages/Login/Login";
import NewAccount from "../Pages/NewAccount/Index";
import Unsubscribe from "../Pages/Unsubscribe/Unsubscribe";
import PasswordForgot from "../Pages/PasswordForgot/PasswordForgot";
import RestablecerContrasena from "../Pages/PasswordRecover/PasswordRecover";
import SuccessMessage from "../Pages/NewAccount/SuccessMessage";
import { VerifyAccount } from "../Pages/VerifyAccount/VerifyAccount";
import IndexResumenAtencion from "./../Patient/ResumeAtention/Index";
import TeleconsultaPaciente from "./../teleconsulta/AppTeleconsultaPaciente";

// Context
import ChatProvider from "../components/chat/ChatProvider";
import { UserContext } from "../context/UserContext";

// Plugins
import ReactGA from "react-ga";
import { ToastContainer, toast } from "react-toastify";

// CSS
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/animate.css";
import "../assets/css/style.css";
import "../assets/css/develop.css";
import TroubleShooting from "../Pages/TroubleShooting/routes";
import ApikeyLogin from "../ApikeyLogin";
import { WebIndex } from "../Profesional";
import Wizzard from "../Pages/TroubleShooting/VerificacionDispositivos/Wizzard";
import { MultipleSessionError } from "../Pages/MultipleSessionError/MultipleSessionError";
import ProfesionalIndex from "../Profesional/ProfesionalIndex";
import PatientIndex from "../Patient/PatientIndex";
import NotFound from "../Pages/NotFound/NotFound";
import ProfesionalesPruebas from "../Profesional/PruebasProfesionales/ProfesionalesPruebas";
import configEnv from "../configEnv";

toast.configure();

if (process.env.REACT_APP_SOCKET_URL !== "false") {
  ReactGA.initialize("UA-79356370-7");
  ReactGA.pageview("/");
}

export const AppRouter = () => {
  const { isLogged, user } = useContext(UserContext);
  const lastPath = localStorage.getItem('lastPath') || '/';
  const rutBackOfficeValidos = configEnv.ENV_URL_RUT_VALIDOS_BACK_OFFICE || '';
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
      />
      <ChatProvider />
      <Router>
        <>
          <Switch>
            <Route
              exact
              path={["/login", "/"]}
              component={Login}
            />
            <PublicRoutes
              strict
              user={user}
              path={"/olvido-contrasena"}
              component={PasswordForgot}
              isAuthenticated={isLogged}
            />
            <PublicRoutes
              strict
              user={user}
              path={"/restablecer-contrasena"}
              component={RestablecerContrasena}
              isAuthenticated={isLogged}
            />
            <PublicRoutes
              strict
              user={user}
              path={"/dar-de-baja"}
              component={Unsubscribe}
              isAuthenticated={isLogged}
            />
            <PublicRoutes
              strict
              user={user}
              path={"/registro"}
              component={NewAccount}
              isAuthenticated={isLogged}
            />
            <PublicRoutes
              strict
              user={user}
              path="/validaCuenta"
              component={VerifyAccount}
              isAuthenticated={isLogged}
            />
            <PublicRoutes
              strict
              user={user}
              path={"/registro-completado"}
              component={SuccessMessage}
              isAuthenticated={isLogged}
            />

            <Route 
              strict 
              path="/solucion" 
              component={TroubleShooting} />

            <Route
              path="/apikey-login/:rutpaciente/:nombrepaciente"
              component={ApikeyLogin}
            />
            <Route exact path="/teleconsulta/medico/resumen/:vattentionid">
              <IndexResumenAtencion userType="medic" />
            </Route>
            <Route
              path="/teleconsulta/paciente"
              component={TeleconsultaPaciente}
            />
            <Route path="/teleconsulta" component={WebIndex} />
            
            {/* <Route path="/probar-teleconsulta">
              <div className="container">
                <div className="row justify-content-md-center">
                  <div className="col-md-10 col-lg-8 col-sm-12 col-12">
                    <Wizzard />
                  </div>
                </div>
              </div>
            </Route> */}

            <Route exact path="/tabs" component={MultipleSessionError} />
            {rutBackOfficeValidos.indexOf(user.rut) !== -1 
              &&
              <PrivateRoute
                strict
                isAuthenticated={isLogged}
                path="/listado-profesionales"
                component={(props) => {
                  return <ProfesionalesPruebas {...props} />
                }}
              />
            }
            
            <PrivateRoute
              strict
              path="/profesional"
              isAuthenticated={isLogged}
              component={(props) => {
                return <ProfesionalIndex {...props} />
              }}
            />
            <PrivateRoute
              strict
              path="/paciente"
              isAuthenticated={isLogged}
              component={(props) => {
                return <PatientIndex {...props} />
              }}
            />
            
            <Route path="*" component={NotFound} />
          </Switch>
        </>
      </Router>
    </>
  );
};

/**
 * El route que direcciona al hijo no debe incluir el atributo exact
 */
