import React, { useState, useEffect } from 'react';
import serviceChat from '../../../services/chat';
import Loading from './../../Loading';
import { getSocketClient, addChatRoom, getSocketById } from '../../../services/socket';
import ChatHeader from './../ChatHeader/ChatHeader';
import ChatDialog from './../ChatDialog/ChatDialog';
import ChatList from './../ChatList/ChatList';
import './ChatMain.css';


const ChatMain = ({ ...props }) => {
  var socket = getSocketClient();
  const { _id, email, firstname, lastname, phone, rut } = props.user;
  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState(props.nombreCompleto || '');
  const [isLoading, setIsLoading] = useState(true);
  const [chatRooms, setChatRooms] = useState([]);
  const [isChatSelected, setIsChatSelected] = useState(false);
  const [chatRoomSelected, setChatRoomSelected] = useState({});   
  let tipoUsuario = props.type; //paciente o profesional
  

  useEffect(() => {
    let socket = getSocketClient();
    if(socket){
      socket.on('nueva_sala', (data) => {
        getChatRooms();
     });
    }

  }, []);

  useEffect(() => {
    setUserName(firstname+' '+lastname);
    
    if(_id && props.type && props.user && userName && rut){
      getChatRooms();
    }
  }, [_id, props.type, props.user, userName, rut]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {

    setOpen(!open);
    if(!open){
      getChatRooms();
    }
  };

  //Displaymode ChatRoom
  const handleDisplayMode = (chatRoom, newDisplayMode) => {
    let index = chatRooms.findIndex( chat => chat._id === chatRoom._id);
    let chatRoomStateToMutate = chatRooms;
    let item = chatRoomStateToMutate[index];
    item.displayMode = newDisplayMode;
    chatRoomStateToMutate[index] = item;

    setChatRooms(chatRoomStateToMutate);

    if(newDisplayMode==='extend'){
      setChatRoomSelected(chatRoom);
      setIsChatSelected(true);
    }else{
      setChatRoomSelected(null);
      setIsChatSelected(false);
    }
  };

  const handleUpdateMessage = (chatRoom, message) => {
    let index = chatRooms.findIndex( chat => chat._id === chatRoom._id);
    let chatRoomStateToMutate = chatRooms;
    let item = chatRoomStateToMutate[index];
    item.messages.push(message);
    chatRoomStateToMutate[index] = item;
    setChatRooms(chatRoomStateToMutate);
  };

  const getChatRooms = async () => {
    setIsLoading(true);
    
    let response = await serviceChat.getChatRooms(props.type, _id);

    if(response && response.data && response.data.success){
      let roomsToPush = [];
      response.data.chatRooms.forEach(chat => {

        let socket = getSocketById(chat._id);
        if(!socket){
          //Solo genero salas de chat si no han sido generadas previamente.
          addChatRoom({room:chat._id, rut:rut, name:userName});
        };
        if(chatRooms){
          //Mantengo el displayMode para cuando ya habían cargado chatRooms y el usuario había extendido.
          let index = chatRooms.findIndex( x => x._id === chat._id);
          let chatRoomStateToMutate = chatRooms;
          let item = chatRoomStateToMutate[index];
          
          if(item){
            roomsToPush.push({
              ...chat,
              displayMode: item.displayMode
            })
          }else{
            roomsToPush.push({
              ...chat,
              displayMode:'compact'
            });
          }

        }else{

          roomsToPush.push({
            ...chat,
            displayMode:'compact'
          });
        }
      });
      setChatRooms(roomsToPush);
    };
    
    setIsLoading(false);
  };

  const style = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 70,
    left: 'auto',
    borderRadius: '10%',
    position: 'fixed',
  };

  return(
    <>
      <ChatDialog
        open={open}
        handleClose={handleClose}
        handleToggle={handleToggle}
        style={style}
        chatRoomSelected={chatRoomSelected}
        isChatSelected={isChatSelected}
      >
        {isLoading ? 
          <Loading />
        : 
          <>
            <ChatHeader
              isChatSelected={isChatSelected}
              handleToggle={handleToggle}
            />
            <ChatList 
              chatRooms={chatRooms}
              isChatSelected={isChatSelected}
              tipoUsuario={tipoUsuario}
              rut={rut}
              userName={userName}
              chatRoomSelected={chatRoomSelected}
              handleDisplayMode={handleDisplayMode}
              handleToggle={handleToggle}
              handleUpdateMessage={handleUpdateMessage}
            />  
          </>
        }
      </ChatDialog>
    </>
  )
}

export default ChatMain;