import { useEffect } from 'react';
import datesService from '../../services/dates';

let responseData = {};

export const UseReturnDates = (params) => {
    
    useEffect(() => {

      datesService
        .getFechas(params)
        .then(async ({ data }) => {
          return responseData = {
            fechaActual: data.fechaActual,
            fechaFinDia: data.fechaFinDia,
            fechaPersonalizada: data.fechaPersonalizada,
          }
        });
      
    }, [params]);
  

    return responseData;

}
