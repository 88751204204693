import React, { useEffect, useState } from 'react';
// FORMIK
import { Formik, Form } from 'formik';
// COMPONENTS

// MATERIAL UI
import { Button, TextField, Divider, OutlinedInput, InputAdornment, IconButton, CircularProgress } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

// SERVICES
import authentication from '../../../../services/authentication';
import patientServices from '../../../../services/patients';

import Modal from "react-modal";
import customModalStyles from '../hooks/customModalStyles';



const InfoAcceso = ({
  user,
}) => {
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [guardando, setGuardando] = useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // ======================================
  // Modals
  // ======================================

  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");


  // ======================================
  // Edición de contraseña
  // ======================================



  return (

    <Formik
      initialValues={
        {
          rut: user.rut,
          nombre: user.firstname,
          apellido: user.lastname,
          email: user.email,
          emailConfirm: '',
          password_old: '',
          password1: '',
          password2: '',
        }}
      onSubmit={(values: any, { setSubmitting, resetForm }) => {

      }}
    >
      {
        ({
          values,
          errors,
          touched,
          handleChange,
          handleReset,
          handleSubmit,
          setFieldValue,
          handleBlur,
          setFieldError,
          setFieldTouched,
          isSubmitting,
          validateForm,
        }) => (
          <Form
            onChange={(event: (React.FormEvent<HTMLFormElement>)) => {


            }}
          >
            <div className='info-section shadow-sm p-4 mt-2'>

              <span className='title-info-profesional'>Información de acceso</span>
              <span className='text-info-profesional'>
                Modifica la información de acceso de tu cuenta de redblu.cl
              </span>

              <div className='mt-3 row'>
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                  <span className='text-muted font-weight-bold'>
                    Correo
                  </span>
                  <TextField
                    id="email"
                    name="email"
                    label=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    defaultValue={values.email}
                    variant="outlined"
                    className={"w-100 mb-2"}
                    type='text'
                    size="small"
                  />


                </div>
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                  <span className='text-muted font-weight-bold'>
                    Repetir Correo
                  </span>
                  <TextField
                    id="emailConfirm"
                    name="emailConfirm"
                    label=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    className={"w-100 mb-2"}
                    type='text'
                    size="small"
                  />


                </div>
              </div>
              <div className='mt-3 row'>
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                  <span className='text-muted font-weight-bold'>
                    Contraseña actual
                  </span>

                  <OutlinedInput
                    id="password_old"
                    name="password_old"
                    type={showOldPassword ? 'text' : 'password'}
                    value={values.password_old}
                    className={"w-100"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowOldPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={100}
                  />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                  <span className='text-muted font-weight-bold'>
                    Nueva contraseña
                  </span>

                  <OutlinedInput
                    id="password1"
                    name="password1"
                    type={showPassword1 ? 'text' : 'password'}
                    value={values.password1}
                    className={"w-100"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={100}
                  />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                  <span className='text-muted font-weight-bold'>
                    Repetir Nueva contraseña
                  </span>
                  <OutlinedInput
                    id="password2"
                    name="password2"
                    type={showPassword2 ? 'text' : 'password'}
                    value={values.password2}
                    className={"w-100"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={100}
                  />
                </div>
              </div>
              < Modal isOpen={alertModalIsOpen} style={customModalStyles} onRequestClose={() => setAlertModalIsOpen(false)}>
                <div className="alertModal">
                  <h1>{alertModalText}</h1>
                  <div className="text-center">
                    <button type="button" className="btn btn-submit btn-lg" onClick={() => { setAlertModalIsOpen(false) }}>Aceptar</button>
                  </div>
                </div>
              </Modal>
              <Divider light className="mt-3" />
              <Button
                variant="contained"
                className="mt-4"
                color="primary"
                size="large"
                type="submit"
                disabled={guardando}
                onClick={async () => {


                  // Emails no coinciden
                  if (values.email !== values.emailConfirm) {
                    setAlertModalText("Los emails no coinciden.");
                    setAlertModalIsOpen(true);
                    return;
                  }
                  if (values.password_old.length !== 0) {

                    if (!values.password1 || values.password1.length < 5) {
                      setAlertModalText("La contraseña debe tener al menos 5 caracteres.");
                      setAlertModalIsOpen(true);
                      return;
                    }

                    if (values.password1 !== values.password2) {
                      setAlertModalText("Las contraseñas no coinciden.");
                      setAlertModalIsOpen(true);
                      return;
                    }

                    if (values.password1 || values.password2) {
                      if (values.password_old.length === 0) {
                        setAlertModalText("Debe ingresar la contraseña actual");
                        setAlertModalIsOpen(true);
                      } else {


                        try {
                          setGuardando(true);
                          await authentication.editarPassword({
                            rutPaciente: values.rut,
                            oldPassword: values.password_old,
                            newPassword: values.password1
                          });
                        } catch (error: any) {
                          console.log('error : >>', error.response)
                          if (error.response.status === 403) {
                            setAlertModalText("La contraseña actual es incorrecta");
                            setAlertModalIsOpen(true);
                          } else {
                            throw error;
                          }
                        }

                        await patientServices.patch(user._id, {
                          firstname: values.nombre,
                          lastname: values.apellido,
                          rut: values.rut,
                          phone: '',
                          email: values.email
                        }).then(response => {
                          setAlertModalText("Datos actualizados.");
                          setAlertModalIsOpen(true);
                          setGuardando(false);
                        }).catch(error => {

                          setAlertModalText(error.toString());
                          setAlertModalIsOpen(true);
                          setGuardando(false);
                        })


                      }
                    }
                  }


                }}
              >
                Guardar Cambios
              </Button>
            </div>
          </Form>
        )
      }
    </Formik>

  );
}

export default InfoAcceso;