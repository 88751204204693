import React from 'react';
import { Avatar, Button, Divider, Grid, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';
import { Edit, ExitToApp, RedoOutlined } from '@material-ui/icons';
import { handleLogout } from '../../../hooks/useLogout';





export const InfoCardProfesional = ({
  profesionalImagen,
  dataProfesional
}) => {

  // const classes = useStyles();

  return (
   
      <List
        className="section-mi-cuenta mt-4 border-rounded mb-0 pb-0 rounded-lg"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader" className="card-row bg-white">
            <span className="text-title-perfil">Mi Cuenta</span>
          </ListSubheader>
        }
      >
        <ListItem className="card-row shadow-sm item-section" >
          <ListItemAvatar className="pb-4 pl-4">

            <Avatar src={profesionalImagen.src} alt={profesionalImagen.alt} className="" />

          </ListItemAvatar>

          <ListItemText className="pb-4 pl-4"
            primary={
              <React.Fragment>
                <span className="profile"> {dataProfesional.name}</span>
                {/* <span className="profile"> {user.lastname}</span> */}
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                {/* <span className="role">{dataProfesional.categories[0].name}</span> */}
              </React.Fragment>
            }
          />

        </ListItem>

        <Divider light />

        {/* <ListItem className="card-row item-section pl-2 pt-1 pb-1">
          <Button variant="text" className="" >
            <ListItemIcon className="">
              <RedoOutlined color="primary" />
            </ListItemIcon>
            <span className="font-weight-bold">Compartir Perfil</span>
          </Button>

        </ListItem> */}

        <Divider light />

        {/* <ListItem className="card-row item-section pl-2 pt-1 pb-1">
          <Button variant="text"  className="">
            <ListItemIcon className="">
              <Edit color="primary" />
            </ListItemIcon>
              <span className="text-perfil">Editar Perfil</span>
          </Button>
        </ListItem> */}


        <ListItem
          className="mt-3 bg-white"
          button
          onClick={handleLogout}
        >
          <ListItemIcon>
            <ExitToApp color="secondary" className="mr-2 " />
          </ListItemIcon>
          <ListItemText primary="Cerrar Sesión" />
        </ListItem>

      </List >
  )
}

export default InfoCardProfesional