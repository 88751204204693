import React,{useEffect, useState} from 'react';
// import IconCheckDone from '../../assets/images/check-done.svg';
// import IconCheckUnDone from '../../assets/images/check-undone.svg';
import store from './store';
// import storeFechaSeleccionada from './useFechaSeleccionada';
import { FaRegHourglass, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import moment from "moment-timezone";
import "moment/locale/es";
import patientApi from "../../../services/patients";

const ResumenActividadesGrafico = ({actividades, patientId}) => {
   const[listaActividades,setListaActividades] = useState([]);
   const[fechaSeleccionada, setFechaSeleccionada] = useState(null);

   useEffect(() => {

      //TODO: Si se cambia la forma en que se llama al servicio para reducir la cantidad de peticiones al backend
      //actualizar el renderizado de los datos.
      if(store.fecha.fecha.length>0 && patientId){
         let fechaMoment = moment(store.fecha.fecha,'DD-MM-YYYY');
         setFechaSeleccionada(fechaMoment.format("LL"));
         
      
         const params = {
            startOfPeriod: fechaMoment,
            endOfPeriod: fechaMoment,
         };
        
         buscarActividades(params);
      }
   },[store.fecha]);

   const buscarActividades = async (params) => {
      let notificationsForToday = [];
      try {
         
      const patientNotifications = await patientApi
         .getResources(patientId, params)
         .then( async({ data }) => {

            if (Array.isArray(data)) {
                data.map(({ _id, resource, medicUser, days, data }) => {
               
                  let reminderObj = {};
                   days.map((day, i) => {
                     reminderObj = {
                        reminderId: _id,
                        medicUser: medicUser,
                        notificationCategory: data.typeLink,
                        resource: resource,
                        data: data,
                        dayId: days[i]._id,
                        dateTime: moment.utc(days[i].dateTime),
                        completed: days[i].completed,
                     };

                     notificationsForToday.push(reminderObj);
             
                   });
               });
            }
         })
         .catch((err) => {
            notificationsForToday.push([]);
            console.log("error : ", err);
         });
      } catch (error) {
         console.log(error)
      }
      setListaActividades(notificationsForToday);
   };

   return(
      <>
         {
            fechaSeleccionada ? (
               <MensajeFechaSeleccionada 
                  fechaSeleccionada={fechaSeleccionada} 
                  listaActividades={listaActividades} 
               />
            ) : (
               <MensajeSinFechaSeleccionada />
            )
         }
      </>
   );
}

export default ResumenActividadesGrafico;


const MensajeSinFechaSeleccionada = () => {
   return(
      <div className="row mt-1 animated fadeIn">
         <div className="col-md-12 col-12 dayResume">
            <div className="card little-shadow mb-4">
               <div className="p-3">
                  <div className="row dayResumeSelection align-items-center">
                     <div className="col-12 text-center">
                        <span className="text-center">Presiona un punto dentro del gráfico para ver el detalle de las actividades asignadas en el día seleccionado.</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

const MensajeFechaSeleccionada = ({fechaSeleccionada, listaActividades}) => {
   return(
      <div className="row mt-1 animated fadeIn">
         <div className="col-md-12 col-12 dayResume">
            <div className="card little-shadow mb-4">
               <div className="p-3">
                  <div className="row dayResumeSelection align-items-center">
                     <div className="col-7">
                        <span>Resumen de actividad</span>
                        <h4 className="m-0">{fechaSeleccionada}</h4>
                     </div>
                     <div className="col-5 text-right">
                        <span className="text-right">{listaActividades.length} actividades asignadas</span>
                     </div>
                  </div>
               </div>
               
               <div className="colTable">
                  {listaActividades.length > 0 ? (
                     <>
                        <div className="col-12 headerTable py-2">
                           <div className="row">
                              <div className="col-6">
                                 <span>Nombre de recurso</span>
                              </div>
                              <div className="col-3">
                                 <span>Estado</span>
                              </div>
                              <div className="col-3">
                                 <span>Categoría</span>
                              </div>
                           </div>
                        </div>
                        <div className="bodyTable">
                           {listaActividades.map((reminder,index) => 
                              <DetalleActividadAsignada key={index} data={reminder} />
                           )}
                        </div>
                     </>
                  ) : (
                     <div className="bodyTable">
                        <div className="col-12 py-2 dayResumeItem">
                           <div className="row align-items-center">
                              <div className="col-12">
                                 <span className="gray">El paciente no realizó actividades el día seleccionado.</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </div>
   )
}

const DetalleActividadAsignada = ({ data}) => {
   const[estado,setEstado] = useState("completada");
   // console.log('data : ', data)
   useEffect(() => {
      let today = moment();
      let daySelected = moment(data.dateTime);

      if (!data.completed && !today.isAfter(daySelected, 'd')){
         setEstado("pendiente");
      } else if (!data.completed ) {
         setEstado("incompleta");
      } else {
         setEstado("completada");
      }
   }, [data]);
   

   
   return(
      <div className="col-12 py-2 dayResumeItem">
         <div className="row align-items-center">
            <div className="col-6">
               <span>{data.data.activityName ? data.data.activityName : 'Sin nombre'}</span>
            </div>
            <div className="col-3">
               <span className={estado + ' catName'}>
                  {estado==="completada" && 
                     <FaCheckCircle />
                  }
                  {estado==="incompleta" && 
                     <FaTimesCircle />
                  }
                  {estado==="pendiente" &&
                     <FaRegHourglass />
                  }
                  {estado}
               </span>
            </div>
            <div className="col-3">
               <span className="gray catName">{data.notificationCategory ? data.notificationCategory : 'Sin categoría'}</span>
            </div>
         </div>
      </div>
   )
}