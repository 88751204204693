
import React from 'react';
// import DOMPurify from 'dompurify'

// import { ErrorMessage, useField } from 'formik';
import { TextField } from '@material-ui/core';
import getStringFromHtml from '../../helpers/getStringFromHtml';

interface Props {
  title?: string;
  name: string;
  placeholder?: string;
  onChange: (event: any) => void;
  setFieldValue: (name: string, value: any) => void;
  setFieldError: (name: string, value?: any) => void,
  setFieldTouched: (name: string, isTouched?: boolean, shouldValidate?: boolean) => void,
  touched?: any;
  error?: string;
  value?: any;
  [x: string]: any; // puede resibir cualquier cosa
}


export const TextAreaComponent = ({
  title,
  type,
  placeholder,
  value,
  onChange,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  touched,
  name,
  ...props
}: Props) => {

  // const sanitizedData = () => ({
  //   __html: DOMPurify.sanitize(value)
  // })
  // const { __html } = sanitizedData() //parse(sanitizedData())

  const formatedValue = getStringFromHtml(value);


  return (
    <div className="form-group">
       <label htmlFor="dataProfesional"
        className="text-label-profesional">{title ? title : ''}
      </label> 

      
      <TextField
        type={''}
        id="outlined-multiline-static"
        label={''}
        multiline
        minRows={'10'}
        maxRows={'10'}
        variant="outlined"
        fullWidth
        name={name}
        value={value ? formatedValue : ''}
        onChange={onChange}
        onFocus={() => setFieldTouched(name)}
        onBlur={() => setFieldTouched(name)}
        autoComplete="off"

      />

      {/* <ErrorMessage name={props.name} component="span" className="custom-error-class" /> */}
      {/* {
        
        // errores con la forma opcional
        meta.touched && meta.error && (
          <span className='error'>{meta.error}</span>
        )
        
      } */}
    </div>
  )
}

export default TextAreaComponent;