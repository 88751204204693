import vattentionService from '../../services/vattentions';

const findPatientAgoraChanel = () => {

	return new Promise((resolve, rejected) => {
		vattentionService
			.getPatientRoom()
			.then((response) => {
				if (response.data && response.data.key && response.data.channelName) {
					resolve(response.data);
				} else {

					rejected(new Error('key or channelName not found'));
				}
			})
			.catch((error) => {

				rejected(error);
			});
	});
};

export default findPatientAgoraChanel;
