import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
// Librerías
import moment from 'moment';
// Helpers
import { messages } from '../helpers/calendar-messages-es';
// Estilos del calendario
import 'react-big-calendar/lib/css/react-big-calendar.css';

import './../assets/css/Agenda.css';
// Components
import { AgendaEvent } from './AgendaEvent';
import { AgendaModal } from './AgendaModal';
import Loading from '../../components/Loading';
import { Grid } from '@material-ui/core';

const localizer = momentLocalizer(moment);
moment.locale('es');

export const Agenda = (props) => {

  const {
    con,
    psicologo,
    setApikey,
    // handleLogout,
    // dummyDataCalendar,
    citas,
    apikey,
    // location,
    // history,
    // patient,
    // setIsLoged,
    // setPatient,
    setFechaValida,
    setIdDate,
    setBeforeDate,
    setDateStart,
    setDateEnd,
    isLoadingServiceIndex

  } = props;
  const [eventsData, setEventsData] = useState([]);
  const [selectData, setSelectData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
  }, [eventsData, selectData])

  const [lastView, setLastView] = useState(localStorage.getItem('lastView') || 'month')

  // Double Click
  const onDoubleClick = (e) => {
    setSelectData(true);
    setEventsData(e);
  };

  // Select --> evento de click
  const onSelectEvent = (e) => {
    // console.log("Agenda: onSelectEvent = (e) => ", e)

    if (!selectData) {
      setSelectData(true);
    }
    setEventsData(e);
    // console.log(selectData)
  };

  // OnViewChange
  const onViewChange = (e) => {
    // console.log(e)
    setLastView(e)
    localStorage.setItem('lastView', e);
  }

  // Estilos personalizado del calendario
  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: '#4354FE',
      borderRadius: '4px',
      color: 'white',
      fontSize: '1rem',
      heigh: 'auto',
      opacity: 1,
    };
    return {
      style,
    };
  };

  

  return (
    <Grid
      item
    >
      <div className='col-12 calendar-screen bg-white pb-4'>
        {
          (citas.length > 0) ?
            <Calendar
              events={citas}
              localizer={localizer}
              messages={messages}
              startAccessor='start'
              endAccessor='end'
              eventPropGetter={eventStyleGetter}
              onDoubleClickEvent={onDoubleClick}
              onSelectEvent={onSelectEvent}
              view={lastView}
              onView={onViewChange}
              components={{
                event: AgendaEvent,
              }}
              onSelectSlot={eventStyleGetter}
              resizable
              views={['month', 'week', 'day']}
              popup={false}
            />
            :
            (isLoading && citas.message === undefined) 
              ? <Loading />
              :
              <div className='d-flex justify-content-center pt-5 mt-5 mx-auto vh-100'>
                <h2 className=' titulo-recurso mt-5 p-t-5'>{citas.message}</h2>
              </div>
        }
  
        {
          selectData ? <AgendaModal
            event={eventsData}
            psicologo={psicologo}
            events={citas}
            apikey={apikey}
            setConsulta={con}
            setApikey={setApikey}
            setSelectData={setSelectData}
            setFechaValida={setFechaValida}
            setIdDate={setIdDate}
            setBeforeDate={setBeforeDate}
            setDateStart={setDateStart}
            setDateEnd={setDateEnd}
            visible={true}
          />
            : null
        }
      </div>
    </Grid>
  );
};
