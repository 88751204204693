import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
// Date and Hours
import moment from 'moment-timezone';
// Hooks
import { useForm } from '../../hooks';
// Services
import AssignmentServices from '../../../services/assignment';
import { getSocketClient } from '../../../services/socket';
// Components
import { Encabezado } from './EncabezadoRecurso';
import { OpcionesRecurso } from './OpcionesRecurso';
// Helpers
import { optionsType, optionsPeriodicity, optionsTypeLink } from '../../helpers/OptionSelect';
// Icons

// Styles
import 'react-datepicker/dist/react-datepicker.css';
import { customModalStyles } from '../../utils/Modal';
import Switch from 'react-input-switch';
import authentication from '../../../services/authentication';

//Analytics
import ReactGA from 'react-ga';

const fechaAsign = moment();
const fechaActual = moment().hour(0).minute(0).seconds(0);
const fechaFin = fechaActual.clone().hour(23).minute(59).seconds(59);

export const AsignarRecurso = (props) => {
  const [formValues, handleInputChange, reset, errors] = useForm({
    activityName: '',
    pacientIntructions: '',
    objective: '', // se deja campo vacio y oculta de recurso personalizado historia 461
    externalLink: '',
    externalLinkDescription: '',
    typeLink: '',
    repeat: 1,
    periodicity: '',
  });

  const {
    activityName,
    pacientIntructions,
    objective,
    externalLink,
    externalLinkDescription,
    typeLink,
    repeat,
    periodicity,
  } = formValues;

  // Date
  const [startDate, setStartDate] = useState(fechaActual.toDate());
  const [endDate, setEndDate] = useState(fechaFin.toDate());
  const [placeholderEnlace, setPlaceholderEnlace] = useState('https://');
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState('');
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [flagError, setFlagError] = useState(false);
  const [toogleButton, setToogleButton] = useState(false);
  const [guardado, setGuardado] = useState('');
  //  console.log(toogleButton);
  // hour and minutes
  const [time, setTime] = useState(new Date());
  const onChangeHour = (hora) => {
    setTime(hora);
  };
  useEffect(() => {
    switch (typeLink) {
      case 'audio':
        setPlaceholderEnlace('https://open.spotify.com/track/...');
        ReactGA.event({ category: 'Tipo Recurso', action: 'audio' });
        break;
      case 'video':
        setPlaceholderEnlace('https://www.youtube.com/watch?v=....');
        ReactGA.event({ category: 'Tipo Recurso', action: 'video' });
        break;
      case 'lectura':
        setPlaceholderEnlace('https://www...');
        ReactGA.event({ category: 'Tipo Recurso', action: 'lectura' });
        break;
      case 'enlace-externo':
        setPlaceholderEnlace('https://www...');
        ReactGA.event({ category: 'Tipo Recurso', action: 'enlace-externo' });
        break;
      case 'otros':
        setPlaceholderEnlace('....');
        ReactGA.event({ category: 'Tipo Recurso', action: 'otros' });
        break;
      case 'captivate':
        setPlaceholderEnlace('app.redblu.cl/recursos/test1');
        ReactGA.event({ category: 'Tipo Recurso', action: 'captivate' });
        break;
      default:
        setPlaceholderEnlace('https://');
        break;
    }
  }, [typeLink]);

  //  useEffect(() => {}, [repeat]);

  useEffect(() => {
    props.render(new Date());

  }, [guardado])

  const submitHandler = async(e) => {
    e.preventDefault();
    if (isFormValid()) {
      let data = {
        medicUser: props.medicUser, // obtener id de medico logeado
        patient: props.patientId, // obtener id de paciente logeado
        resource: props._id, //id recurso precargado
        isActive: props.active,
        pushNotifications: 'false',
        notificationCategory: props.category.map((c) => c.nameCategory)[0],
        message: activityName,
        urlname: '',
        assignmentDate: fechaAsign.toDate(),
        startOfPeriod: startDate,
        endOfPeriod: endDate,
        timesInTheDay: [
          {
            hour: 9, // se asignaran todas las actividades a las 9 de la mañana
            minute: 0,
          },
        ],
        data: {
          activityName: activityName,
          repeat: 1,// 1 repetición por defecto
          pacientInstructions: pacientIntructions,
          objective: objective,
          externalLink: externalLink,
          externalLinkDescription: externalLinkDescription,
          typeLink: typeLink,
        },
      };
      // console.log(data);
      // Asignando recurso
      try {
        await AssignmentServices.assignResource(data.patient, data).then(
          async (res) => {
            // console.log(res);
            setAlertModalText(`Se ha asignado correctamente`);
            setAlertModalIsOpen(true);
            setAlertSuccess(true);
            sendActivityEmail(props.rutPaciente, activityName);
            try {
              let socket = getSocketClient();
              // console.log(socket);
              socket.emit('assignment', { type: 'asignment', value: 'true' }, () => console.log('mensajeEmit'));
            } catch (error) {
              console.log(error);
            }
            setGuardado(new Date());
            reset();
            setStartDate(fechaActual.toDate());
            setEndDate(fechaFin.toDate());
            setTime(new Date());
          }
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      setFlagError(true);
    }

  };

  const sendActivityEmail = (patientRut, activityName) => {
    authentication.restorePasswordEmail(patientRut, '3', activityName);
  };
  // validación del formulario
  const isFormValid = () => {
    // console.log('typelink : ',typeLink);
    // console.log('externalLink : ', externalLink);

    if (activityName.trim().length === 0) {
      setFlagError(true);
      setAlertSuccess(false);
      setAlertModalText(errors.activityName);
      setAlertModalIsOpen(true);
      return false;
    }

    if (pacientIntructions.trim().length === 0) {
      setFlagError(true);
      setAlertSuccess(false);
      setAlertModalText(errors.pacientIntructions);
      setAlertModalIsOpen(true);
      return false;
    }

    // if (externalLink.trim().length === 0) {
    //    setFlagError(true)
    //    setAlertSuccess(false);
    //    setAlertModalText(errors.externalLink);
    //    setAlertModalIsOpen(true);
    //    return false;
    // }

    if (typeLink.trim().length === 0 && toogleButton === 1) {
      setFlagError(true);
      setAlertSuccess(false);
      setAlertModalText(errors.typeLink);
      setAlertModalIsOpen(true);
      return false;
    }
    // if (periodicity.trim().length === 0) {
    //    setFlagError(true);
    //    setAlertSuccess(false);
    //    setAlertModalText(errors.periodicity);
    //    setAlertModalIsOpen(true);
    //    return false;
    // }

    return true;
  };
  if (toogleButton === 0) {
    ReactGA.event({ category: 'Agregar Tipo de Recurso', action: 'false' });
    setToogleButton('');
    // Limpieza de campos en caso de arrepentirse de agregar tipo de recurso
    formValues.typeLink = '';
    formValues.externalLink = '';
  }
  if (toogleButton === 1) {
    ReactGA.event({ category: 'Agregar Tipo de Recurso', action: 'true' });
  }

  return (
    <>
      <Encabezado />
      <form className='animated fadeIn' onSubmit={submitHandler}>
        <div className='row mb-2 pb-3 mt-2 mr-2  pr-0 bg-white pt-2 shadow-sm'>
          <div className='col-md-6 col-sm-12 col-12'>
            {/* <div className='pb-3'>
              <label className='zoom_label'>(*)Objetivo de la actividad</label>
              <input
                type='text'
                autoComplete='off'
                className='zoom form-control-tele'
                placeholder='Objetivo: (Ejemplo Mejorar higiene del paciente) '
                name='objective'
                onChange={handleInputChange}
                value={objective}
              />
            </div> */}

            <div className='pb-3'>
              <label className='zoom_label'>(*)Nombre de la actividad</label>
              <input
                autoComplete='off'
                className={`zoom form-control-tele ${errors.activityName && flagError && 'border-danger'
                  }`}
                placeholder='Nombre de actividad (Ejemplo: Ducha Diaria)'
                type='text'
                name='activityName'
                onChange={handleInputChange}
                value={activityName}
                autoFocus
              />
              {errors.activityName && flagError && (
                <sup className='zoom_label text-danger'>
                  {errors.activityName}
                </sup>
              )}
            </div>
            <div className='pb-3'>
              <label className='zoom_label'>
                (*)Instrucciones al paciente
              </label>
              <textarea
                rows='6'
                autoComplete='off'
                className={`zoom form-control-tele ${errors.pacientIntructions && flagError && 'border-danger'
                  }`}
                placeholder='Instrucciones al paciente ( Ejemplo : Ducharse todos los días de 09:00 a 10:00)'
                type='text'
                name='pacientIntructions'
                onChange={handleInputChange}
                value={pacientIntructions}
              />
              {errors.pacientIntructions && flagError && (
                <sup className='zoom_label text-danger'>
                  {errors.pacientIntructions}
                </sup>
              )}
            </div>
          </div>

          <div className='col-md-6 col-sm-12 col-12'>
            <label className='zoom_label '>
              <Switch
                value={toogleButton}
                onChange={setToogleButton}
                className={'mr-2 p-2'}
              />
              Agregar Tipo de recurso
            </label>
            {toogleButton && (
              <div className='card p-3'>
                <div className='pb-3'>
                  <label className='zoom_label mb-0'>Tipo de recurso</label>
                  <select
                    className={`zoom form-control-tele ${errors.typeLink && flagError && 'border-danger'
                      }`}
                    name='typeLink'
                    onChange={handleInputChange}
                    value={typeLink}
                  >
                    {optionsType.map((opt, idx) => {
                      return (
                        <option key={idx} value={opt.value}>
                          {opt.icon}
                          {opt.label}
                        </option>
                      );
                    })}
                  </select>
                  {errors.typeLink && flagError && (
                    <sup className='zoom_label text-danger'>
                      {errors.typeLink}
                    </sup>
                  )}


                  {
                    (typeLink === "captivate") ? (
                      <>
                        <label className='zoom_label mb-0 mt-2'>Seleccione recurso</label>
                        <select
                          className={`zoom form-control-tele`}
                          name='externalLink'
                          onChange={handleInputChange}
                          value={externalLink}
                        >
                          {optionsTypeLink.map((opt, idx) => {
                            return (
                              <option key={idx} value={opt.value}>
                                {opt.label}
                              </option>
                            );
                          })}
                        </select>
                        <iframe src={externalLink} className="mt-2" width="210" height="150"></iframe>
                      </>
                    ) : (
                      <div className='pb-3'>
                        <label className='zoom_label mb-0'>
                          Copiar enlace del recurso{' '}
                        </label>

                        <input
                          type='text'
                          autoComplete='off'
                          className={`zoom form-control-tele`}
                          placeholder={placeholderEnlace}
                          name='externalLink'
                          onChange={handleInputChange}
                          value={externalLink}
                        />
                      </div>
                    )
                  }
                </div>

              </div>
            )}
          </div>
        </div>

        <OpcionesRecurso
          key={props._id}
          {...props}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          time={time}
          onChangeHour={onChangeHour}
          handleInputChange={handleInputChange}
          errors={errors}
          repeat={repeat}
          periodicity={periodicity}
          optionsPeriodicity={optionsPeriodicity}
          row={'row'}
        />
        <Modal
          isOpen={alertModalIsOpen}
          style={customModalStyles}
          onRequestClose={() => setAlertModalIsOpen(false)}
        >
          <div className='alertModal'>
            <h1 className={alertSuccess ? 'text-tele' : 'text-dark'}>
              {alertModalText}
            </h1>
            <div className='text-center'>
              <button
                type='button'
                className='btn btn-submit btn-lg'
                onClick={() => setAlertModalIsOpen(false)}
              >
                Aceptar
              </button>
            </div>
          </div>
        </Modal>
      </form>
    </>
  );
};
