
const MicrofonoDosAnimado = () => {

  return(
    <svg width="280" viewBox="0 0 280 280">
      <defs>
        <clipPath id="clip-micrófono-dos">
          <rect width="280" height="280"/>
        </clipPath>
      </defs>
      <g id="micrófono-dos" clipPath="url(#clip-micrófono-dos)">
        <g id="Grupo_2662" data-name="Grupo 2662">
          <path id="Trazado_3599" data-name="Trazado 3599" d="M4805.6,3777.769s-10.58,18.982-31.74,26.45-31.428,10.269-37.34,23.338,0,55.078,0,55.078,16.493,13.691,99.576,11.513,79.348-6.845,79.348-6.845v-37.652s-1.556-14.314-6.534-22.094c-4.79-7.483-28.874-25.334-44.85-36.024-.319-.212-.925-.615-1.516-1.005-15.558-10.269-15.558-23.027-15.558-23.027h-34.229Z" transform="translate(-4687.556 -3625.383)" fill="#f7ad8a"/>
          <path id="Trazado_3600" data-name="Trazado 3600" d="M4744.179,3810.35l64.1,21.471s42.942,10.269,46.988,10.58,49.788-12.758,49.788-12.758,2.295-9.783.875-12.467-3.131-3.189-3.131-3.189-42.554,10.678-77.4,7.876-74.681-18.378-74.681-18.378l-8.713,5.31Z" transform="translate(-4685.571 -3616.578)" fill="#f8f0eb"/>
          <path id="Trazado_3601" data-name="Trazado 3601" d="M4753.515,3803.094c-10.269,4.415-13.789,8.657-17,15.753-5.913,13.07,0,55.078,0,55.078s16.493,13.691,99.576,11.514,79.348-6.846,79.348-6.846v-37.652s-1.244-14.314-6.222-22.094c-4.79-7.483-7.157,4.356-45.744,8.091s-56.322-1.556-87.439-9.957S4753.515,3803.094,4753.515,3803.094Z" transform="translate(-4687.556 -3616.673)" fill="#799fa0"/>
        </g>
        <path id="Trazado_3602" data-name="Trazado 3602" d="M2560.137,2197.663s.133,16.412,0,19.546,6.729,10.163,20.092,10.163,17.63-10.163,17.63-10.163v-22.454Z" transform="translate(-2436.289 -2066.869)" fill="#f8ab8c"/>
        <path id="Unión_5" data-name="Unión 5" d="M74.735,119.534c-6.78-2.179-5.326-10.9-11.864-14.53s-4.359-22.76-17.192-26.877-16.467-7.75-13.318-19.613a23.059,23.059,0,0,1,4.37-8.5c-4.125-2.679-9.334-9.285-15.509-23.464C3.788,14.444,0,5.155,0,5.155L32.994,0V24.614S41.339,35.379,45.043,42.83a25.56,25.56,0,0,1,2.815-1.509S89.748,97.5,90.232,105c.435,6.744-6.946,14.856-13.367,14.855A6.941,6.941,0,0,1,74.735,119.534Z" transform="translate(128.721 133.054)" fill="#182020" opacity="0.2"/>
        <path id="Trazado_3605" data-name="Trazado 3605" d="M2556.775,2231.8c1.888.483,10.063,32.656,35.044,32.087s33.018-19.9,35.719-32.087,3.014-30.662,1.7-39.381-9.16-29-24.657-41.4-29.593-5.424-47.8,17.822-2.889,35.5-2.889,35.5S2554.887,2231.316,2556.775,2231.8Z" transform="translate(-2447.999 -2119.699)" fill="#f7ad8a"/>
        <path id="Trazado_3606" data-name="Trazado 3606" d="M4902.626,3661.34c0-4.1-1.367,9.567,9.566,9.567s20.5,9.225,18.792,16.742-4.442,15.034,4.783,21.526,10.252,12.983,6.492,19.474-1.367,16.741,2.392,19.817.341,21.867-3.758,26.992-10.592,12.983-5.125,21.184,10.934,15.717,8.884,19.816-8.2,14.692-2.392,17.425,20.5,3.758,23.574,18.108,3.076,14.35,9.568,20.5,3.417,18.45,14.35,10.25,4.783-19.815,5.468-26.991,10.931-12.983,9.565-21.867-9.565-12.642-3.074-20.159,0-10.25-2.05-17.083.682-11.617,5.124-18.792,1.708-19.817-6.833-26.308-1.023-19.476-8.2-24.6-18.451-14.008-19.816-31.775-11.618-48.176-43.393-53.642c-19.232-3.309-32.331,4.272-42.032,16.34a72.073,72.073,0,0,0-5.791,8.56,112.426,112.426,0,0,0-7.526,16.1c-8.542,22.209-5.81,29.042-5.81,36.559s8.2,31.091,18.108,38.608a26.471,26.471,0,0,1,10.592,17.767v-15.375l-10.592-20.159s-8.54-3.759-9.566-11.276-3.075-13.666,2.049-13.666-1.707-25.284,8.884-32.459S4902.626,3665.44,4902.626,3661.34Z" transform="translate(-4774.213 -3630.054)" fill="#804b0e"/>
        <path id="Trazado_3607" data-name="Trazado 3607" d="M2598.5,2180.513s-10.7-10.8-13.945-7.9-2.949,11.159-1.936,17.012,7.779,13.374,12.787,13.117S2598.5,2180.513,2598.5,2180.513Z" transform="translate(-2485.414 -2091.52)" fill="#f7ad8a"/>
        <g id="headset">
          <path id="Trazado_3608" data-name="Trazado 3608" d="M53.363,123.269a.5.5,0,0,1-.5-.5v-5.5a.5.5,0,0,1,.5-.5h13.62a.5.5,0,0,1,.5.5v5.5a.5.5,0,0,1-.5.5Zm14.932-1.624V118.8s18,.406,22.742-3.79a27.63,27.63,0,0,0,5.1-6.177l-3.646-1.255a3.5,3.5,0,0,1-2.17-4.449l10.942-31.78a3.5,3.5,0,0,1,4.332-2.208,162.51,162.51,0,0,0-1.364-24.051c-2.03-14.01-11.168-24.164-21.929-31.068s-29.239-6.7-42.844-2.639S16.514,29.661,11.642,43.469C8.209,53.193,8.806,65.234,9.459,71.505c4.187,1.134,8.883,7.06,11.471,15.024a32.757,32.757,0,0,1,1.714,12.195c.188,5.6-1.435,9.94-4.648,11.11-5.058,1.84-12.144-4.868-15.826-14.984-3.535-9.711-2.648-19.033,1.9-21.393C3.321,67.805,2.233,58.571,2.5,54.23A59.23,59.23,0,0,1,16.922,18.492C29.309,4.075,41.693.421,59.36.015s31.067,7.716,41.423,20.1,11.776,26.195,12.386,35.331c.364,5.458-.576,12.22-1.391,16.78a3.491,3.491,0,0,1,.242,2.825l-.074.215,1.855.639a4.5,4.5,0,0,1,2.79,5.72l-8.7,25.281a4.5,4.5,0,0,1-5.72,2.789l-1.856-.639c-1.653,3.7-5.956,10.653-15.229,11.639a146.22,146.22,0,0,1-14.831,1C68.865,121.7,68.295,121.646,68.295,121.646Z" transform="translate(86.364 4.616)" stroke="#000" strokeWidth="1"/>
        </g>
        <g id="lines-one" className="animated fadeIn infinite slow delay-3s">
          <path id="Trazado_3613" data-name="Trazado 3613" d="M-1.028,38.1l-2.694-6.461C-3.563,31.562,1.672,29,1.672,19.13,1.672,8.263-4.6,2.7-4.667,2.645L-.083-2.645C.274-2.336,8.672,5.1,8.672,19.13,8.672,33.885-.632,37.934-1.028,38.1Z" transform="matrix(0.978, 0.208, -0.208, 0.978, 217.584, 86.546)"/>
          <path id="Trazado_3610" data-name="Trazado 3610" d="M.469,37.9c-.356-.207-8.72-5.225-8.72-19.666,0-14.1,7.939-20.679,8.277-20.952l4.4,5.444L2.226,0,4.453,2.7c-.233.2-5.7,4.934-5.7,15.53,0,10.188,5.071,13.507,5.287,13.644Z" transform="matrix(0.978, -0.208, 0.208, 0.978, 70.857, 86.515)"/>
        </g>
        <g id="lines-two" className="animated fadeIn infinite slow delay-4s">
          <path id="Trazado_3612" data-name="Trazado 3612" d="M-3.967,72.749l-3.565-6.024c.491-.3,12.586-8,12.586-31.177C5.053,11.94-7.6,2.973-7.732,2.885l3.963-5.77c.646.444,15.822,11.182,15.822,38.433C12.053,62.952-3.313,72.362-3.967,72.749Z" transform="matrix(0.978, 0.208, -0.208, 0.978, 236.691, 72.784)"/>
          <path id="Trazado_3611" data-name="Trazado 3611" d="M3.567,72.725c-.661-.4-16.2-10.191-16.2-37.565,0-27.309,15.457-37.668,16.115-38.1L7.315,2.922c-.529.354-12.949,8.939-12.949,32.239,0,23.486,12.716,31.509,12.844,31.588Z" transform="matrix(0.978, -0.208, 0.208, 0.978, 51.958, 72.708)"/>
        </g>
      </g>
    </svg>
  )
}

export default MicrofonoDosAnimado;