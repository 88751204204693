import { setNewToken, setLogedPatient, setLogedPsicologo  } from "../../services/api";
import authentication from "../../services/authentication";

export const handleLogout = (e) => {
  e.preventDefault();
  authentication
    .logout()
    .then((response) => {
      // console.log(response)
      setNewToken(null);
      setLogedPsicologo(null);
      setLogedPatient(null);
      localStorage.clear();
      window.location.replace("/login"); // history.push('/paciente');

    })
    .catch((error) => {
      setNewToken(null);
      setLogedPsicologo(null);
      setLogedPatient(null);
      localStorage.clear();
      window.location.replace("/login");// history.push('/paciente');
    });
};
