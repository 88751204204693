import { Switch , Route, useRouteMatch } from 'react-router-dom'; 
import TroubleShootingIndex from "./index";

const TroubleShooting = () => {
  const match = useRouteMatch();

  return(
    <Switch>
      <Route 
        exact 
        path={`${match.path}/:error`}
        component={TroubleShootingIndex} 
      />
    </Switch>
  );
};


export default TroubleShooting;