import API from "./api";
import URLS from "../URLS";

const authentication = {
  nuevoUsuario: function (patientData) {
    return API.axios({
      method: "post",
      url: URLS.patients,
      data: patientData,
    });
  },

  login: function (loginData) {
    return API.axios({
      method: "post",
      url: URLS.login,
      data: loginData,
    });
  },

  roleVerify: function (loginData) {
    return API.axios({
      method: "post",
      url: URLS.login + "/roleVerify",
      data: loginData,
    });
  },

  logout: function () {
    return API.axios({
      method: "post",
      url: URLS.login + "/logout",
    });
  },

  refreshToken: function () {
    return API.axios({
      method: "post",
      url: URLS.login + "/refreshToken",
    });
  },

  getTokenApiKey: function (params) {
    return API.axios({
      method: "get",
      url: URLS.tokenredgesam,
      params: params,
    });
  },

  verifyRedgesamApiKey: function (apikey) {
    return API.axios({
      method: "get",
      url: URLS.tokenredgesam + "/verifyToken",
      params: {
        apikey: apikey,
      },
    });
  },

  getTokenExtendedDetails: function (apikey) {
    return API.axios({
      method: "get",
      url: URLS.tokenredgesam + "/getTokenExtendedDetails",
      params: {
        apikey: apikey,
      },
    });
  },

  restorePasswordEmail: function (rutPaciente, tipoMensaje, actividad, email) {
    return API.axios({
      method: "get",
      url: URLS.password + "/email",
      params: {
        rutPaciente: rutPaciente,
        tipoMensaje: tipoMensaje,
        actividad: actividad
        // "email" : email
      },
    });
  },

  validateRestoreUrl: function (rutPaciente, codigo) {
    return API.axios({
      method: "get",
      url: URLS.password + "/checkURL",
      // {rutPaciente, newPassword, codigo}
      params: { rutPaciente, codigo },
    });
  },

  changePassword: function (params) {
    //{rutPaciente, newPassword, codigo}
    return API.axios({
      method: "put",
      url: URLS.password + "/cambioPassword",
      data: params,
    });
  },

  editarPassword: function (params) {
    return API.axios({
      method: "put",
      url: URLS.password + "/editarPassword",
      data: params,
    });
  },

};

export default authentication;
