import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import queryString from "query-string";
import home_photo from '../../assets/images/introduccion/1.svg';
import authenticationApi from "../../services/authentication";
import imgLogo from '../../assets/images/redblu.png';
import imgListo from '../../assets/images/guia/listoVideoAtencion.svg';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const PasswordRecover = () => {

  const [inputValues, setInputValues] = useState({});
  const [isUrlCodeValid, setIsUrlCodeValid] = useState(null);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");

  const handleInputChange = (event) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value
    })
  }

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (inputValues.password != inputValues.confirmPassword) {
      setAlertModalText("Las contraseñas no coinciden.");
      setAlertModalIsOpen(true);
      return;
    }

    const parsed = queryString.parse(window.location.search);

    authenticationApi.changePassword({
      rutPaciente: parsed.rutPaciente,
      newPassword: inputValues.password,
      codigo: parsed.codigo
    })
    .then(response => {

      if (response && response.data && response.data.message == 'OK') {
        setIsPasswordChanged(true);
        setInputValues({
          "password": "",
          "confirmPassword": ""
        })
      }

    }).catch(error => {
      setIsPasswordChanged(false);
    })
  }

  useEffect(() => {
    try {
      
      const parsed = queryString.parse(window.location.search);
      authenticationApi.validateRestoreUrl(parsed.rutPaciente, parsed.codigo)
      .then(response => {
        if (response && response.data && response.data.message == 'OK') {
          setIsUrlCodeValid(true)
        }
      }).catch(error => {
        setIsUrlCodeValid(false);
      })

    } catch (error) {
      setIsUrlCodeValid(false);
    }

  }, [])

  if (isPasswordChanged === false) {
    return (
      <div className="content-container animated fadeIn fullh">
        <div className="middle-center-content pt-3">
          <div className="row pb-2">
            <div className="col-12 text-center">
              <img src={imgLogo} width="34%" className="img-fluid" alt="Redblu Logo" />
            </div>
          </div>
          <div className="login-photo text-center">
            <img src={home_photo} alt="Iniciar Sesión" height="160px" />
          </div>
          <div className="heading mt-4 mb-4">
            <h1 className="paged-title mb-2">Restablecer Contraseña</h1>
            <p>Ingrese la nueva contraseña que desea establecer:</p>
          </div>

          {isUrlCodeValid === false ?
            <div className="heading mt-4">
              <h1 className="paged-title text-danger">El link es invalido</h1>
            </div>
            : null}

          <form onSubmit={onSubmit}>
            <div className="bodycontent">
              <div className="row">
                <div className="col-12 mb-3">
                  <label>Ingrese su nueva contraseña</label>
                  <input
                    name="password"
                    type="password"
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-5">
                  <label>Confirme su nueva contraseña</label>
                  <input
                    name="confirmPassword"
                    type="password"
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="actioncontent">
              <div className="row">
                <div className="col-4 text-left create-account">
                  <Link to="/login" className="pt-2"> Cancelar </Link>
                </div>
                <div className="col-8 text-right">
                  <input type="submit" value={"GUARDAR CONTRASEÑA"} disabled={!isUrlCodeValid} />
                </div>
              </div>
            </div>
          </form>
        </div>
        <Modal isOpen={alertModalIsOpen} style={customModalStyles} onRequestClose={() => setAlertModalIsOpen(false)}>
          <div className="alertModal">
            <h1>{alertModalText}</h1>
            <div className="text-center">
              <button type="button" className="btn btn-submit btn-lg" onClick={() => setAlertModalIsOpen(false)}>Aceptar</button>
            </div>
          </div>
        </Modal>
      </div>
    )

  } else {

    return (
      <div className="content-container animated fadeIn slow fullh">
        <div className="row align-items-center fullh">
          <div className="col-12">
            <div className="animated jackInTheBox text-center">
              <img
                src={imgListo} alt="Cuenta lista"
                className="img-fluid animated pulse infinite slow mb-3" />
              <h1 className="mb-2">¡Cambio realizado!</h1>
              <p>La contraseña ha sido guardada correctamente</p>
            </div>
          </div>
          <div className="col-12 text-center create-account">
            <Link to="/login" className="pt-2"> Continuar </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default PasswordRecover;