import React from 'react';
import { Calendar, Views } from 'react-big-calendar';

// Helpers
import { messages } from '../helpers/calendar-messages-es';
import { AgendaEvent } from './AgendaEvent';

// Calendar
import './../assets/css/Agenda.css';
// Estilos personalizado del calendario
const eventStyleGetter = (event, start, end, isSelected) => {
  const style = {
    backgroundColor: "#4354FE",
    borderRadius: "5px",
    color: "white",
    display: "block",
    fontSize: "14px",
    minHeight: '50px',
    width: '100%',
    opacity: 1,
    border: '0px solid black'
  };
  return {
    style,
  };
};

const AgendaLateral = ({ events, localizer, onDoubleClick, onSelectEvent }) => {

  return (
    <>
      <Calendar
        defaultView={Views.DAY}
        endAccessor="end"
        events={events}
        localizer={localizer}
        max={new Date(2021, 0, 0, 21, 0, 0)}
        min={new Date(2021, 0, 0, 9, 0, 0)}
        scrollToTime={new Date(2021, 1, 1, 24)}
        messages={messages}
        startAccessor="start"
        eventPropGetter={eventStyleGetter}
        onDoubleClickEvent={onDoubleClick}
        onSelectEvent={onSelectEvent}
        views={{ day: true }}

        components={{
          event: AgendaEvent,
        }}
        onSelectSlot={eventStyleGetter}
        className="pt-4 agenda-lateral"
      />
    
    </>
  )
}

export default AgendaLateral;
