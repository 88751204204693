
const ReproducirSonido = () => {

  return(
    <svg width="260" viewBox="0 0 420 350" className="w-full">
      <defs>
        <clipPath id="clipPath">
          <path id="Trazado_897" data-name="Trazado 897" d="M1349.613,5099.411v193.272H1474.6V5099.411Z" transform="translate(-1349.613 -5099.411)" fill="#ededed"/>
        </clipPath>
        <clipPath id="clip-reproducir-sonido-dos">
          <rect width="420" height="350"/>
        </clipPath>
      </defs>
      <g id="reproducir-sonido-dos" clipPath="url(#clip-reproducir-sonido-dos)">
        <g id="Grupo_2659" data-name="Grupo 2659" transform="translate(-3353 -4324.085)">
          <g id="Grupo_2650" data-name="Grupo 2650" transform="translate(3333.727 4224)">
            <path id="Trazado_3543" data-name="Trazado 3543" d="M2.957,126.321C-7.673,100.283,4.386,0,140.3,0S282.48,113.466,281.534,126.321s-3.883,84.067-57.1,105.04-27.863-89.373-76.2-88.789S13.587,152.36,2.957,126.321Z" transform="translate(113.26 109.92) rotate(9)" fill="#fbecec"/>
            <path id="Trazado_3536" data-name="Trazado 3536" d="M854.295,742.454c8.829,14.874,2.464,37.735-12.41,46.564s-37.42.351-46.249-14.523a31.32,31.32,0,0,1,10.947-42.919C821.458,722.747,845.465,727.579,854.295,742.454Z" transform="translate(-730.244 -397.062)" fill="#ddd2f3" opacity="0.34"/>
            <path id="Trazado_3540" data-name="Trazado 3540" d="M1381.285,607.705c.213-3.159-8.125-4.172-7.444-7.2,3.344-5.8,1.144-7.8-1.256-8.4-3.775-.9-6.757.333-9.607,2.993a12.65,12.65,0,0,0-24.815,3.791,13.371,13.371,0,0,0-21.389,13.3c1.038,5.047,6.666,3.7,10.753,3.829l18.342.57c9.421.292,14.5-.293,23.916,0C1370.185,616.505,1383.285,618.205,1381.285,607.705Z" transform="translate(-987.512 -322.705)" fill="#fff"/>
            <path id="Trazado_3539" data-name="Trazado 3539" d="M2150.978,132.632c-.336-3.279-3.491-5.761-6.759-6.191s-6.546.795-9.391,2.46a11.7,11.7,0,0,0-19.1-8.809,10.506,10.506,0,0,0-8.3-8.451,12.99,12.99,0,0,0-11.514,3.8,17.122,17.122,0,0,0-4.625,11.444,13.123,13.123,0,0,0-15.1-4.986,13.28,13.28,0,0,0-8.151,10.347c-.911,5.381,2,7.774,7.381,7.894l60.392,1.344c3.332.074,6.782.127,9.851-1.176S2151.318,135.948,2150.978,132.632Z" transform="translate(-1807.033 52.535)" fill="#fff"/>
            <path id="Trazado_3541" data-name="Trazado 3541" d="M961.73,457.68a10.245,10.245,0,0,0-6.319,6.131,10.536,10.536,0,0,0,.789,8.785A10.539,10.539,0,1,0,957.713,460" transform="translate(-662.84 -137.062)" fill="#ddd2f3" opacity="0.4"/>
            <path id="Trazado_3537" data-name="Trazado 3537" d="M961.73,457.68a10.245,10.245,0,0,0-6.319,6.131,10.536,10.536,0,0,0,.789,8.785A10.539,10.539,0,1,0,957.713,460" transform="translate(-856.84 -43.062)" fill="#ddd2f3" opacity="0.4"/>
            <path id="Trazado_3542" data-name="Trazado 3542" d="M1005.51,669.162c8.829,14.874,2.465,37.735-12.41,46.565s-37.42.35-46.249-14.524A31.32,31.32,0,0,1,957.8,658.284C972.672,649.455,996.681,654.287,1005.51,669.162Z" transform="translate(-640.539 -277.062)" fill="#ddd2f3" opacity="0.24"/>
            <path id="Trazado_3538" data-name="Trazado 3538" d="M1327.577,154.631c-2.451.038-5.027.048-7.179-1.124a7.911,7.911,0,0,1-3.787-8.073,9.3,9.3,0,0,1,5.856-6.944,11.307,11.307,0,0,1,9.2.719,10.356,10.356,0,1,1,20.587-1.979,14.039,14.039,0,0,1,9.9-.458,10.761,10.761,0,0,1,6.811,6.972,8.755,8.755,0,0,1-2.8,9.124c-2.362,1.833-5.45,2.09-8.333,1.959-3.01-.137-5.774-.582-8.841-.534Z" transform="translate(-1224.512 66.426)" fill="#fff"/>
          </g>
          <g id="Grupo_2651" data-name="Grupo 2651" transform="translate(3495.029 4403.792)">
            <path id="Trazado_895" data-name="Trazado 895" d="M1349.613,5099.411v193.272H1474.6V5099.411Z" transform="translate(-1349.613 -5099.411)" fill="#ededed"/>
            <g id="Grupo_734" data-name="Grupo 734" clipPath="url(#clipPath)">
              <path id="Trazado_896" data-name="Trazado 896" d="M1445.323,5099.262v193.284H1554.87V5099.262Z" transform="translate(-1437.602 -5099.273)" fill="#fff"/>
            </g>
          </g>
          <path id="Trazado_3576" data-name="Trazado 3576" d="M1474.151,4821.559v-5.386s0-19.146-23.892-19.146h-77.2s-23.891-1.8-23.891,19.146v5.386Zm-124.979,193.274v16.286s0,14.4,23.891,14.4h77.2s23.892,1.091,23.892-19.823v-10.865Z" transform="translate(2145.821 -417.595)" fill="#38373b"/>
          <path id="Trazado_3582" data-name="Trazado 3582" d="M1999.373,4936.4v3.5h27.877v-3.5Z" transform="translate(1580.078 -545.758)" fill="#ededed"/>
          <path id="Trazado_3583" data-name="Trazado 3583" d="M1904.022,4936.4v3.5h4.652v-3.5Z" transform="translate(1667.735 -545.758)" fill="#ededed"/>
          <path id="Trazado_3580" data-name="Trazado 3580" d="M1628.652,5508.421a41.39,41.39,0,1,1,41.39-41.391,41.39,41.39,0,0,1-41.39,41.391Z" transform="translate(1929.737 -971.642)" fill="#ededed" opacity="0.4"/>
          <path id="Trazado_3577" data-name="Trazado 3577" d="M1857.751,6873.74l1.428-.247a6.411,6.411,0,0,0-5.426-5.262l-.2,1.438a4.956,4.956,0,0,1,4.2,4.071Z" transform="translate(1714.133 -2321.734)" fill="#fff"/>
          <path id="Trazado_3578" data-name="Trazado 3578" d="M1862.155,6825.167a10.521,10.521,0,0,0-6.041-3.015l-.2,1.437a9.2,9.2,0,0,1,7.78,7.54l1.429-.243a10.6,10.6,0,0,0-2.967-5.718Z" transform="translate(1711.963 -2279.373)" fill="#fff"/>
          <g id="Grupo_2652" data-name="Grupo 2652" transform="translate(3531.783 4456.325)">
            <path id="ic_music_video_24px" d="M10.722,72.675A21.69,21.69,0,0,1,39.837,52.149V7.167H76.231V21.724H54.395v51.17a21.837,21.837,0,0,1-43.673-.218Z" transform="translate(-10.722 -7.167)" fill="#fff"/>
          </g>
          <g id="Grupo_2653" data-name="Grupo 2653" transform="translate(3525.142 4448.624)">
            <path id="ic_music_video_24px-2" data-name="ic_music_video_24px" d="M10.722,72.675A21.69,21.69,0,0,1,39.837,52.149V7.167H76.231V21.724H54.395v51.17a21.837,21.837,0,0,1-43.673-.218Z" transform="translate(-10.722 -7.167)" fill="#fa9902"/>
          </g>
          <path id="Trazado_3579" data-name="Trazado 3579" d="M1599.023,5449.164a11.76,11.76,0,1,1,11.761-11.761,11.761,11.761,0,0,1-11.761,11.761Z" transform="translate(1980.423 -901.897)" fill="#ededed" stroke="#fff" strokeWidth="4" opacity="0.76"/>
          <path id="Trazado_3581" data-name="Trazado 3581" d="M1599.023,5449.164a11.76,11.76,0,1,1,11.761-11.761,11.761,11.761,0,0,1-11.761,11.761Z" transform="translate(1933.977 -983.164)" fill="#ededed" stroke="#fff" strokeWidth="4" opacity="0.76"/>
        </g>
        <g id="wave-2" className="animated fadeIn infinite slow delay-1s" transform="translate(-3353 -4324.085)">
          <path id="ic_looks_24px" d="M78.762,45.857A45.908,45.908,0,0,1,32.9,0h13.1A32.767,32.767,0,0,0,78.762,32.755,32.767,32.767,0,0,0,111.517,0h13.1A45.908,45.908,0,0,1,78.762,45.857Z" transform="translate(3454.708 4420.936) rotate(90)" fill="#fa9902"/>
          <path id="ic_looks_24px-2" data-name="ic_looks_24px" d="M45.857,0A45.908,45.908,0,0,0,0,45.857H13.1a32.755,32.755,0,1,1,65.51,0h13.1A45.908,45.908,0,0,0,45.857,0Z" transform="translate(3709.727 4453.841) rotate(90)" fill="#fa9902"/>
        </g>
        <g id="wave-3" className="animated fadeIn infinite slow delay-2s" transform="translate(-3353 -4324.085)">
          <path id="ic_looks_24px-3" data-name="ic_looks_24px" d="M79.062,86.895C39.3,86.895,0,54.6,0,14.833H13.1c0,32.493,33.466,58.959,65.959,58.959s65.959-26.466,65.959-58.959h13.1C158.123,54.6,118.826,86.895,79.062,86.895Z" transform="translate(3450.677 4420.936) rotate(90)" fill="#fa9902"/>
          <path id="ic_looks_24px-4" data-name="ic_looks_24px" d="M79.062,0C39.3,0,0,32.3,0,72.062H13.1C13.1,39.568,46.568,13.1,79.062,13.1s65.959,26.466,65.959,58.959h13.1C158.123,32.3,118.826,0,79.062,0Z" transform="translate(3754.796 4420.936) rotate(90)" fill="#fa9902"/>
        </g>
        <g id="wave-1" className="animated fadeIn infinite slow delay-0-4s" transform="translate(-3353 -4324.085)">
          <path id="Trazado_3584" data-name="Trazado 3584" d="M17.5,0c9.665,0,0,7.835,0,17.5S27.165,35,17.5,35a17.5,17.5,0,0,1,0-35Z" transform="translate(3451 4481.936)" fill="#fa9902"/>
          <path id="Trazado_3585" data-name="Trazado 3585" d="M4.3,0c-9.665,0,0,7.835,0,17.5S-5.369,35,4.3,35a17.5,17.5,0,1,0,0-35Z" transform="translate(3645.782 4481.936)" fill="#fa9902"/>
        </g>
      </g>
    </svg>
  )
}

export default ReproducirSonido;