import React, { useState, useEffect, useContext, useRef } from "react";
import Navigation from "../components/Navigation/Navigation";
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import Index from "./Teleconsulta/Index";
import RouterRecursos from "./Resources/RouterRecursos";
import BuscarConsultaMedica from "./Teleconsulta/BuscarConsultaMedica";
import ActividadesRouter from "./Assignments/ActividadesRouter";
import Loading from "../components/Loading";
import EditAccount from "./EditAccount/EditAccount";
import patiensService from "../services/patients";
import RejoinTeleconsulta from "./Teleconsulta/RejoinTeleconsulta";
import ResumeAtention from "./ResumeAtention/ResumeAtention";
import IndexResumenAtencion from "./ResumeAtention/Index";
import { UserContext } from "../context/UserContext";
import PruebaTeleconsulta from "./Teleconsulta/PruebaTeleconsulta";
import NotFound from "../Pages/NotFound/NotFound";
// import { set } from "date-fns/esm";
// import { useFetchReminder } from "./hooks/RemindersToday";

const beforeunloadEvent = (patient) => (ev) => {
  patiensService.patientCloseWindow(patient);
};

const PatientIndex = (props) => {
  const isMounted = useRef(true); // Utilizado para verificar si el componente está montado o no
  const match = useRouteMatch();
  const { isLogged, user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [readyJoinRoom, setReadyJoinRoom] = useState(false);
  const [isWaitingRoom, setWaitingRoom]= useState(false);

  const stopSearching = () => {
    setReadyJoinRoom(false);
    setWaitingRoom(false);
    const enSalaEspera = JSON.parse(localStorage.getItem("enSalaEspera"));
    localStorage.setItem("enSalaEspera", JSON.stringify({
      ...enSalaEspera,
      esperando: false,
    }));
  };

  const handleEntrarTeleConsulta = () => {
    stopSearching();
    setReadyJoinRoom(true);
  };

  useEffect(() => {
    if(isLogged){
      setIsLoading(false);
    }

    return () => {
      isMounted.current = false;
    };
  }, [isLogged, user]);

  useEffect(() => {
    if (
      isLogged &&
      user &&
      user.firstname &&
      user.lastname
    ) {
      setIsLoading(false);
      let esperando = JSON.parse(localStorage.getItem("enSalaEspera"));
      if (
        esperando &&
        esperando.esperando === true &&
        esperando.permisos === true
      ) {
        setWaitingRoom(true);
      }
    }

    window.addEventListener("beforeunload", beforeunloadEvent(user));

    return () => {
      window.removeEventListener(
        "beforeunload",
        beforeunloadEvent(user)
      );
      isMounted.current = false;
    };
  }, [isLogged, user]);

  if (isLoading) {
    return (
      <div className="fullh movile-app">
        <Loading />
      </div>
    );
  }

  return (
    <>
      {isWaitingRoom === true && readyJoinRoom === false ? (
        <BuscarConsultaMedica
          isLoged={isLogged}
          location={props.location}
          stopSearching={stopSearching}
          handleEntrarTeleConsulta={handleEntrarTeleConsulta}
        />
      ): null }

      <Switch>
        <Route exact path={`${match.path}/teleconsulta`}>
          <RejoinTeleconsulta />
        </Route>

        <Route exact path={`${match.path}/actividades`}>
          <ActividadesRouter />
        </Route>

        <Route path={`${match.path}/recursos`}>
          <RouterRecursos />
        </Route>

        <Route exact path={`${match.path}/editar`}>
          <EditAccount />
        </Route>

        <Route exact path={`${match.path}/probar-teleconsulta`}>
          <PruebaTeleconsulta />
        </Route>

        {/* <Route path={`${match.path}/resumen-atencion`}>
          <ResumeAtention />
        </Route> */}

        <Route exact path={`${match.path}/teleconsulta/resumen/:vattentionid`}>
          <IndexResumenAtencion />
        </Route>

        <Route exact path={`${match.path}`}>
          <Index
            location={props.location}
            readyJoinRoom={readyJoinRoom}
            setOpenModal={props.setOpenModal}
            openModal={props.openModal}
            setWaitingRoom={setWaitingRoom}
            isWaitingRoom={isWaitingRoom}
            // setIsOpen={setIsOpen}
          />
        </Route>
        
        <Route path="*" component={NotFound} />
      </Switch>

      {!readyJoinRoom && <Navigation data={props.data} />}
    </>
  );
};

export default PatientIndex;
