import axios from "axios";
import URLS from "../URLS";
import configEnv from "../configEnv";


const gatewayService = {
    axios: axios.create({
      baseURL: URLS.baseURLGateway,
      withCredentials: false,
      headers: { 'authorizationapi': URLS.authorizationApi },
    }),
}
const gatewayServiceAuth = {
    axios: axios.create({
      baseURL: URLS.baseURLGateway.replace(" ", ""),
      withCredentials: true,
      auth: {
        username: configEnv.ENV_WP_USER,
        password: configEnv.ENV_WP_PASSWORD
      },
      headers: { 'authorizationapi': URLS.authorizationApi },
    }),
}

export {
  gatewayService,
  gatewayServiceAuth
};