import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import FichaPaciente from '../FichaPaciente';
import Loading from '../../components/Loading';
import authenticationApi from '../../services/authentication';
import storeFechaSeleccionada from '../components/resumenActividadesGrafico/store';
import useFechaSeleccionada from '../components/resumenActividadesGrafico/useFechaSeleccionada';
import { Agenda } from '../AgendaMedico/Agenda';
import { UseReturnDates } from '../../Patient/hooks/UseReturnDates';
import { UserContext } from '../../context/UserContext';
import { inicializaSocket } from '../../services/socket';
import { momentLocalizer } from 'react-big-calendar';
import './../../assets/css/animate.css';

const localizer = momentLocalizer(moment);
moment.locale('es');

var medicState = { state: '' };
const setMedicState = (state) => {
   medicState = state;
}

export const WebIndexPruebas = ({
   history,
   psicologo,
   handleLogout,
   dummyDataCalendar,
   citas,
   location,
   patient,
   setIsLoged,
   setPatient,
   isLoadingServiceIndex

}) => {
   const [saved, setSaved] = useState(false);
   const [consulta, setConsulta] = useState(false);
   const [apikey, setApikey] = useState(null);
   // const [medicState, setMedicState] = useState({state:''});
   const [isLoading, setIsLoading] = useState(true);
   const [hasError, setHasErrors] = useState(false);
   const [selectData, setSelectData] = useState(false);
   const dataCita = localStorage.getItem('data_citaPaciente') || '{}';
   const [fechaValida, setFechaValida] = useState(false);
   const [idDate, setIdDate] = useState(false);
   const [beforeDate, setBeforeDate] = useState(false);
   const [dateStart, setDateStart] = useState(false);
   const [dateEnd, setDateEnd] = useState(false);
   const [temporalData, setTemporalData] = useState({
      apikey: null,
      nombrePaciente: null,
      nombreMedico: null,
      rutPaciente: null,
      rutMedico: null,
      pacienteId: null,
      medicoId: null,
   });
   const { isLogged, user } = useContext(UserContext);
   storeFechaSeleccionada.fecha = useFechaSeleccionada();
   // useScript('https://cdn-app.continual.ly/js/embed/continually-embed.latest.min.js');
   // Creación de render 
   useEffect(() => {
   }, [saved])

   useEffect(() => {
      // console.log('fechaValida', fechaValida);
   }, [fechaValida]);

   useEffect(() => {
      return;
   }, [temporalData])

   const returnDates = UseReturnDates();
   useEffect(() => {
      // console.log(returnDates)
   }, [returnDates]);

   useEffect(() => {

      if (apikey) {
      const verifyApikey = async () => {
         // console.log('verifyApikey');
         try {
            // let parsed = queryString.parse(window.location.search);
            // console.log('parsed', parsed)
            let tokenData;
            tokenData = await authenticationApi.getTokenExtendedDetails(
               // parsed.APIKey
               apikey
            );
            let sessionData = {
               apikey: apikey,
               nombrePaciente: tokenData.data.nombrePaciente,
               nombreMedico: tokenData.data.nombreMedico,
               rutPaciente: tokenData.data.rutPaciente,
               rutMedico: tokenData.data.rutMedico,
               pacienteId: tokenData.data.patientId,
               medicoId: tokenData.data.medicId,
            };
            // console.log('tokenData :>> ', tokenData);
            // console.log(sessionData)
            setTemporalData(sessionData);
            // console.log(sessionData)
            // localStorage.setItem('temporalData', JSON.stringify(sessionData));
            inicializaSocket({ room: tokenData.data.rutPaciente, name: tokenData.data.nombreMedico })
         } catch (error) {
            setHasErrors(false);
         }
         setIsLoading(false);
         };
         verifyApikey();
      } else {
         setIsLoading(false);
      }
   }, [apikey]);


   return (
      <>
        {isLoading &&
          <div className='fullh movile-app'>
              <Loading />
          </div>
        }

        {!isLoading && !hasError &&
          <section className="medicSite">

              {(!consulta && citas.length > 0) &&
                <Agenda
                  apikey={apikey}
                  citas={citas}
                  con={setConsulta}
                  dummyDataCalendar={dummyDataCalendar}
                  handleLogout={handleLogout}
                  local={localizer}
                  psicologo={psicologo}
                  sessionData={temporalData}
                  setApikey={setApikey}
                  history={history}
                  patient={patient}
                  setIsLoged={setIsLoged}
                  setPatient={setPatient}
                  setFechaValida={setFechaValida}
                  setIdDate={setIdDate}
                  setDateStart={setDateStart}
                  setDateEnd={setDateEnd}
                  setBeforeDate={setBeforeDate}
                  isLoadingServiceIndex={isLoadingServiceIndex}
                />
              }
              {consulta &&
                <FichaPaciente
                  apikey={apikey}
                  con={setConsulta}
                  handleLogout={handleLogout}
                  localizer={localizer}
                  location={location}
                  psicologo={psicologo}
                  renderSaved={saved}
                  saved={setSaved}
                  sessionData={temporalData}
                  setApikey={setApikey}
                  setMedicState={setMedicState}
                  citas={citas}
                  fechaValida={fechaValida}
                  setFechaValida={setFechaValida}
                  idDate={idDate}
                  setIdDate={setIdDate}
                  beforeDate={beforeDate}
                  setBeforeDate={setBeforeDate}
                  dateStart={dateStart}
                  setDateStart={setDateStart}
                  dateEnd={dateEnd}
                  setDateEnd={setDateEnd}
                  setSelectData={setSelectData}
                  returnDates={returnDates}
                  userContext={user}
                />
              }
          </section>
         }

         {hasError && (
            <div>
               El enlace ha expirado. Cierra está ventana e inicia una nueva
               sesión de teleconsulta.
            </div>
         )}
         
      </>
   );
};
