import React, { useReducer, useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import queryString from "query-string";
import vattentionService from "../services/vattentions";
import StatusPaciente from "./components/StatusPaciente/StatusPaciente";
import Cronometro from "./../components/Cronometer";
//Material
import EnviarNotificacion from "./components/EnviarNotificacion/EnviarNotificacion";

//NEW SDK
import './AppTeleconsulta.css';
import AgoraRTC from 'agora-rtc-sdk-ng';
import useAgora from './hooks/useAgora';
import MediaPlayer from './components/MediaPlayer';
import { CallEnd } from '@material-ui/icons';
import useTeleconsultaMedico from "./hooks/useTeleconsultaMedico";
import PlayerControllers from './components/MediaPlayerControllers/PlayerControllers';

/* Alertas */
import { AlertasErroresMedico } from "./components/Alertas/AlertasErroresMedico";
import { AlertasExcepciones } from "./components/Alertas/AlertasExcepciones";


import Loading from "../components/Loading";
import Alertas from "./components/Alertas/Alertas";
import alertaState from './components/Alertas/alertaState';
import useAlertMessage from './hooks/useAlertMessage';
import { useAudioLevel } from "./hooks/useAudioLevel";


Modal.setAppElement("#root");

const defaultState = {
  appId: "",
  channel: "",
  uid: "",
  token: undefined,
  cameraId: "",
  microphoneId: "",
  mode: "rtc",
  codec: "vp8",
  patientRut: "",
  patientName: "",
  medicName: "",
  creatingAndEntering: false,
  idVAttention: "",
  encryptionPassword: null,
  hasVattentionOpen: false,
  openVattentions: [],
};



//TODO: ver la posibilida de dejar este reducer en un archivo externo.
const reducer = (
  state: typeof defaultState,
  action: { type: string; [propName: string]: any }
) => {
  switch (action.type) {
    default:
      return state;
    case "setAppId":
      return {
        ...state,
        appId: action.value,
      };
    case "setChannel":
      return {
        ...state,
        channel: action.value,
      };
    case "setUid":
      return {
        ...state,
        uid: action.value,
      };
    case "setToken":
      return {
        ...state,
        token: action.value,
      };
    case "setPatientRut":
      return {
        ...state,
        patientRut: action.value,
      };
    case "setPatientName":
      return {
        ...state,
        patientName: action.value,
      };
    case "setidVAttention":
      return {
        ...state,
        idVAttention: action.value,
      };
    case "setEncryptionPassword":
      return {
        ...state,
        encryptionPassword: action.value,
      };
    case "setHasVattentionOpen":
      return {
        ...state,
        hasVattentionOpen: action.value,
      };
    case "setOpenVattentions":
      return {
        ...state,
        openVattentions: action.value,
      };
  }
};

const client = AgoraRTC.createClient({ codec: 'vp8', mode: 'rtc' });


function AppTeleconsultaMedico(props) {
  const {
    location,
    handleTeleconsulta,
    rutMedico,
    rutPaciente
  } = props;

  const [state, dispatch] = useReducer(reducer, defaultState);
  const [numRutMedico, setNumRutMedico] = useState<String>();
  const [exception, setException] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [audioLevelPatient, setAudioLevelPatient] = useState<number>(0);
  const {
    localAudioTrack, 
    localVideoTrack, 
    leave, 
    join, 
    joinState, 
    remoteUsers, 
    error,
    localCameras,
    localMicrophones,
    switchCamera,
    switchMicrophone,
    audioLevel,
    isSharingScreen,
    localSharingScreen,
    setIsSharingScreen,
  } = useAgora(client);

  const {
    obtenerUIUser,
    verifyApikey,
    getMedicActiveRoom,
    getAgoraDynamicRtcToken
  } = useTeleconsultaMedico();

  alertaState.alerta = useAlertMessage();

  useEffect(() => {
    let mounted = true;
    // const handleInitialData =
      (async () => {
      try {
        setNumRutMedico(obtenerUIUser(rutMedico));
        await verifyApikey(dispatch);
        await getMedicActiveRoom();
        if (mounted) {
          dispatch({
            type: "setPatientRut",
            value: rutPaciente,
          });
        }

      } catch (error) {
        return { rutMedico: null };
      }
    })();
    // handleInitialData();   
    
    return () => {
      if (client) {
        handleLeave();
        const parsed = queryString.parse(window.location.search);
        vattentionService.medicCloseWindow(parsed.APIKey);
      }
      mounted = false; // cleanup function
    };

  }, [client]);



/**
 * Controla el inicio e ingreso de usuario a teleconsulta
 * @function getAgoraDynamicRtcToken Obtiene el appid, channel y token.
 * @function join realiza el init del stream local y publica al canal.
 * @function updateVAttentionStartAttentionByName deja la marca que el médico inicia la consulta
 * @function getStats();
 */
  const handleJoin = async () => {
    
    setIsLoading(true);

    const { appid, channel, token } = await getAgoraDynamicRtcToken(
      dispatch, 
      state.codec, 
      state.patientRut
    );
    
    await join(appid, channel, token, numRutMedico);
    
    if (join) { setIsLoading(false) }
    
    vattentionService.updateVAttentionStartAttentionByName(
      channel,
      {
        userType: "medico",
        startAttention: new Date(),
      }
    );
  };
  

/**
 * handleLeave
 * @function leave Finaliza la teleconsulta
 * @function closeVAttentionByName Deja la marca anotando que la atención ha finalizado.
 * @function updateVAttentionByName añade la marca del "end" de la atención.
 * @function handleTeleconsulta cierra el componente
 *
 */
  const handleLeave = async () => {
    if (joinState) {
      await leave();
      await vattentionService.closeVAttentionByName(state.channel);
      await vattentionService.updateVAttentionByName(state.channel, { endAttention: new Date(), });
      await handleTeleconsulta();
    }
  };


  useEffect(() => {
    /**Excepciones */
    client.on("exception", function (evt) {
      setException({ evt });
      // console.log('exception ::>> ',evt.code, evt.msg, evt.uid);
    });
    
  }, []);

  useEffect(() => {
  /**Errores */
  // console.log('error ::> ',error);
  }, [error]);
  
  // UseAudioLevel
  const level = useAudioLevel(audioLevel, numRutMedico, 'profesional');
  // console.log('level medico: ', level.audioLvl);

  return (
    <>
        <div className="mt-2 _newsdk_videocall mb-3">
        
         {
          (!isLoading && joinState)
            ?  <div className="col-12 bg-light">
              <div className=" row align-items-center">
                <button
                  id='join'
                  type='button'
                  className='btn btn-danger btn-sm btn-cerrar-teleconsulta col-5'
                  onClick={handleLeave}
                  hidden={!joinState}
              > <CallEnd /> Finalizar atención </button>
                  {joinState ?
                    <div className="col-7 text-right">
                      <Cronometro
                        running={remoteUsers.length > 0 && joinState}
                      />
                    </div>
                    : null
                  }
                </div>
                </div>
            : null
          }
          
          <div className="_newsdk_medico_interface">
              <div className='player-container'>
                {joinState && !isLoading &&
                <>
                  <div className='local-player-wrapper'>
                {(localVideoTrack || localAudioTrack ) &&
                  <MediaPlayer
                    rol={'profesional'}
                    join={joinState}
                    isRemoteTrack={false}
                    videoTrack={localVideoTrack}
                    audioTrack={undefined}
                    audioLevel={level.audioLvl}></MediaPlayer>
                }
                </div>
                
                </>
                } 
              
              {(isLoading && !joinState) ? <Loading /> : null}
              
                {(!joinState && !isLoading) ?
                  <div className="remote-player-empty">
                    <span>
                      <button
                        id='join'
                        type='button'
                        className='btn btn-primary btn-sm btn-iniciar-teleconsulta'
                        disabled={isLoading}
                        hidden={joinState} onClick={handleJoin}>Iniciar atención
                      </button>
                      </span>
                   </div>
              : null
            }

                {(joinState && remoteUsers.length === 0) ?
                  <div className="remote-player-empty">
                    <span>Esperando al paciente...</span>
                  </div>
            
                  :
                  <>{remoteUsers.map(user => (
                    <div className='remote-player-wrapper' key={user.uid}>
                      <MediaPlayer
                        rol={'profesional'}
                        join={joinState}
                        isRemoteTrack={true}
                        videoTrack={user.videoTrack} 
                        audioTrack={user.audioTrack}
                        audioLevel={level.audioLvl}></MediaPlayer>
                    </div>
                  ))}
                  </>
                }

                {(joinState && remoteUsers.length === 0) ?
                  <div className="status-paciente">
                    <div className="nombre-paciente">
                      {(state.patientRut) && (
                        <>
                          <StatusPaciente patientRut={state.patientRut} />
                        </>
                      )}
                    </div>
                  </div>
                  : null}

              </div>
              <PlayerControllers
                switchCamera={switchCamera}
                switchMicrophone={switchMicrophone}
                localVideoTrack={localVideoTrack}
                localAudioTrack={localAudioTrack}
                localCameras={localCameras}
                localMicrophones={localMicrophones}
                joinState={joinState}
                client={client ? client : null}
                localSharingScreen={localSharingScreen}
                isSharingScreen={isSharingScreen}
                setIsSharingScreen={setIsSharingScreen}
              />
          </div>
      
          <Alertas/>

          {(joinState && remoteUsers.length === 0) &&
            <EnviarNotificacion patientRut={state.patientRut} />
          }

        </div>
    </>
  );
}



AppTeleconsultaMedico.propTypes = {
  autoJoin: PropTypes.bool,
};

AppTeleconsultaMedico.defaultProps = {
  autoJoin: false,
};

export default AppTeleconsultaMedico;
