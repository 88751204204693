
import API from "./api";
import URLS from "../URLS";

const wpaccountServices = {

   validarcuenta: function ({ rut }) {

      return API.axios({
         method: "get",
         url: `${URLS.baseURL}${URLS.wpaccount}/validarCuenta`,
         params: { rut}
      });
   },

};

export default wpaccountServices;
