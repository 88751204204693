import React from "react";

export const Encabezado = () => {
   return (
      <>
         <div className="row animated fadeIn mt-3">
            <div className="col-12">
               <h6 className="font-weight-bold">
                  Asignar un recurso personalizado
               </h6>
               <p className="small">
                  Detalla la actividad que quieras que el paciente relacice. La
                  información que ingreses será la que el paciente vea cuando
                  deba realizar la actividad, por lo que describe de la manera
                  más clara posible para evitar confusiones.
               </p>
            </div>
         </div>
      </>
   );
};
