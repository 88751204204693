import React from 'react';
import Accordion from '../../../components/Accordion';
import imgGuiaAndroid1 from "./../../../assets/images/help/guiaAndroid1.png";
import imgGuiaAndroid2 from "./../../../assets/images/help/guiaAndroid2.png";
import imgGuiaAndroid3 from "./../../../assets/images/help/guiaAndroid3.png";
import imgGuiaAndroid4 from "./../../../assets/images/help/guiaAndroid4.png";

const GuiaAndroid = () => {
    return(
        <Accordion
            id={"android"}
            titulo={"Teléfono Android"}
            descripcion={"Presiona para ver las indicaciones"}
        >
            <div className="guiastep">
                <div className="number">1</div>
                <div className="guiastep-title">
                    <h3>Presionar candado</h3>
                </div>
                <div className="guiastep-content">
                    <p>Presiona el botón con un icono de candado en la parte superior del navegador.</p>
                    <img src={imgGuiaAndroid1} width="100%" className="border-image" />
                </div>
            </div>

            <div className="guiastep mt-2">
                <div className="number">2</div>
                <div className="guiastep-title">
                    <h3>Presionar Configuración de sitios</h3>
                </div>
                <div className="guiastep-content">
                    <img src={imgGuiaAndroid2} width="100%" className="border-image" />
                </div>
            </div>

            <div className="guiastep mt-2">
                <div className="number">3</div>
                <div className="guiastep-title">
                    <h3>Habilitar permisos</h3>
                </div>
                <div className="guiastep-content">
                    <p>Verifica que en la parte superior aparezca el sitio web: https://app.redblu.cl. Presiona sobre cámara y micrófono.</p>
                    <img src={imgGuiaAndroid3} width="100%" className="border-image mb-2" />

                    <p>Marca la opción de "Permitir". Repite el proceso para el micrófono.</p>
                    <img src={imgGuiaAndroid4} width="100%" className="border-image mb-2" />

                    <p>Luego de haber terminado, <strong>te recomendamos que recargues la página</strong> para que los cambios sean tomados.</p>
                </div>
            </div>
        </Accordion>
    )
}

export default GuiaAndroid;