const configEnv = {
  // APP
  ENV_APP                           : process.env.REACT_APP_ENV,
  ENV_POLL_ACTIVE                   : process.env.REACT_APP_POLL_ACTIVE,
  ENV_PUBLIC_VAPID_KEY              : process.env.REACT_APP_PUBLIC_VAPID_KEY,
  ENV_REQUEST_VATTENTION_INTERVAL   : process.env.REACT_APP_REQUEST_VATTENTION_INTERVAL,
  ENV_APP_SOCKET_URL                : process.env.REACT_APP_SOCKET_URL,
  ENV_URL_REDBLU                    : process.env.REACT_APP_URL_REDBLU,
  ENV_URL_RUT_VALIDOS_BACK_OFFICE   : process.env.REACT_APP_RUT_VALIDOS_BACK_OFFICE,
  // WORDPRESS
  ENV_WP_USER                       : process.env.REACT_APP_WP_USER,
  ENV_WP_PASSWORD                   : process.env.REACT_APP_WP_PASSWORD,
  // API GATEWAY
  ENV_GATEWAY_SOCKET_URL            : process.env.REACT_APP_GATEWAY_SOCKET_URL,
  ENV_API_GATEWAY_USER              : process.env.REACT_APP_API_GATEWAY_USER,
  ENV_API_GATEWAY_PASSWORD          : process.env.REACT_APP_API_GATEWAY_PASSWORD,

}

export default configEnv;