import React, { useState, useRef, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import RedbluLogo from '../../../assets/images/redblu-logo.svg';
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListSubheader,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import {
  AssignmentInd,
  CalendarToday,
  Devices,
  ExitToApp,
  MailOutline,
  Person,
  Replay,
  EventAvailable
} from "@material-ui/icons";
import { handleLogout } from "../../hooks/useLogout";
import { UserContext } from "../../../context/UserContext";
// import { setNewToken, setLogedPatient, setLogedPsicologo } from "../../../services/api";
// import authentication from "../../../services/authentication";
import configEnv from "../../../configEnv";
import './Header.css';


const Header = ({
  psicologo,
  isLoading,
  // isLoadingEdit,
  dataProfesional,
  // profesionalImagen,
  setConsulta

}) => {


  const { setLogged } = useContext(UserContext);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const rutBackOfficeValidos = configEnv.ENV_URL_RUT_VALIDOS_BACK_OFFICE || '';


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);



  const handleEditProfile = (bool) => {
    bool
      ?
      history.push({
        pathname: '/profesional/editar-perfil',
        search: '',
        state: { psicologo }
      })
      : history.push("/profesional");
  }

  useEffect(() => {
  }, [dataProfesional]);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="header-redblu  "
      >
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <Grid className="header-redblu-logo">
              <img src={RedbluLogo} alt="Redblu" width={120} />
            </Grid>
            <Grid className="header-redblu-nav">
              <Button
                className={`
                  pl-3 pr-3
                  ${history.location.pathname === '/profesional' ? 'active' : ''}
                `}
                onClick={() => {
                  history.push('/profesional')
                  setConsulta(false)
                }}>
                <CalendarToday className="mr-2" />
                Agenda
              </Button>

              <Button
                className={`
                  ml-2 pl-3 pr-3
                  ${history.location.pathname === '/profesional/probar-teleconsulta' ? 'active' : ''}
                `}
                onClick={() => history.push('/profesional/probar-teleconsulta')}>
                <Devices className="mr-2" />
                Prueba de teleconsulta
             </Button>
             {
               rutBackOfficeValidos.indexOf(psicologo.rut) !== -1 && (
                 <Button
                   className={`
                  pl-3 pr-3
                  ${window.location.pathname === '/listado-profesionales' ? 'active' : ''}
                `}
                   onClick={() => window.location.pathname = '/listado-profesionales'}>
                   <EventAvailable className="mr-2" />
                   Revisión Profesionales
                 </Button>
               )
            }
            

            </Grid>
          </Grid>
        </Grid>

        {/* Perfil Profesional */}
        <Grid  >
          <Grid
            className="pr-4 header-redblu-profile"
          >
            <Button
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={(event) => handleToggle(event)}
              className="profile-button"
            >
              {!isLoading ? (
                <>
                  <ListItemAvatar>
                    <Avatar src={dataProfesional.imagen} alt={dataProfesional.nombre + "-img"} className="" />
                  </ListItemAvatar>
                  <div className="profile-redblu">
                    <div className="name">
                      {dataProfesional.nombre }  
                    </div>

                    <p className="titulo">
                      {dataProfesional.titulo}
                    </p>
                  </div>


                  <Person className="icon-profile" />

                </>
              ) :
                <>

                  <Box position="relative" display="flex">
                    <Typography variant="caption" component="div" color="primary">
                      Cargando información...
                      <LinearProgress />
                    </Typography>
                  </Box>
                </>
              }

            </Button>

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-end"
              transition
              disablePortal>
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: 'right top' }}
                >
                  <Paper elevation={4}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <List
                        // autoFocusItem={open} 
                        id="menu-list-grow"
                        className="header-profile-nav"
                        onKeyDown={handleListKeyDown}
                        subheader={
                          <ListSubheader component="div" id="nested-list-subheader">
                            Mi Cuenta
                          </ListSubheader>
                        }
                      >
                        <ListItem className="text-secondary">
                          <AssignmentInd className="mr-2" /> {psicologo.rut}
                        </ListItem>

                        <ListItem className="text-secondary">
                          <MailOutline className="mr-2" /> {psicologo.email}
                        </ListItem>
                        <Divider light />
                        <ListItem
                          button
                          //  disabled={isLoadingEdit}
                          onClick={history.location.pathname !== "/profesional/editar-perfil"
                            ? () => handleEditProfile(true)
                            : () => handleEditProfile(false)
                          }
                          className={`
                            ${history.location.pathname !== "/profesional/editar-perfil" ? "text-primary" : 'text-danger'}
                            `}
                        // ${isLoadingEdit ? 'p-event-none' : ''}

                        >
                          <Replay className="mr-2" />
                          {history.location.pathname !== '/profesional/editar-perfil' ? 'Editar Perfil' : 'Volver a Agenda'}
                        </ListItem>

                        <Divider light />

                        <Divider light />

                        <ListItem
                          button
                          onClick={(event) => handleLogout(event, setLogged)}
                          className="logout"
                        >
                          <ExitToApp color="secondary" className="mr-2" /> Cerrar Sesión
                        </ListItem>
                      </List>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Grid>
      </Grid>

    </>
  )
};

export default Header;