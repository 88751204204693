import io from "socket.io-client";
var socket;
var isNewMessage = false;
var isSocketEnable = false;
var socketRooms = [{ id:'', socket: socket }];

const getSocketClient = () => socket;
const getSocketsRooms = () => socketRooms;
const getSocketById = (id) => socketRooms.find((item) => item.id === id );

const inicializaSocket = ({ room, rut, name }) => {
    if (name && room && rut) {
        const ENDPOINT = process.env.REACT_APP_SOCKET_URL
        socket = io(ENDPOINT, {
            path: '/socket.io',
            transports: ['websocket'],
            secure: true,
        });
        socket.emit('join', { rut, name, room }, (error) => {
        });
        socket.on('message', message => {
          isNewMessage = true;
        });
        isSocketEnable = true;
        return socket
    } else {
        return null;
    }
}

const readedNewMessages = () => {
  isNewMessage = false;
}

const newRoomCreated = (rutPaciente) => {
    socket.emit('nueva_sala', { rutPaciente: rutPaciente }, (error) => {});
};

const addChatRoom = async ({ room, rut, name }) => {
    if (name && rut) {
        const ENDPOINT = process.env.REACT_APP_SOCKET_URL
        socket = io(ENDPOINT, {
            path: '/socket.io',
            transports: ['websocket'],
            secure: true,
        });
        socket.emit('join', { rut, name, room }, (error) => {});
        isSocketEnable = true;
        
        socketRooms.push({
            id: room,
            socket: socket
        });
        return socket
    } else {
        return null;
    }
}


export { 
  getSocketClient, 
  inicializaSocket, 
  isSocketEnable, 
  newRoomCreated, 
  addChatRoom, 
  getSocketsRooms, 
  getSocketById,
  isNewMessage,
  readedNewMessages 
};
