import React, { useState, useEffect } from "react";
import patiensService from "../../../services/patients";
import EnviaNotificacion from "../EnviarNotificacion/EnviarNotificacion";
import './StatusPaciente.css';

var timerStatus = null;

const StatusPaciente = ({ patientRut }) => {
   const [status, setStatus] = useState("Cargando..");
   const [statusClass, setStatusClass] = useState("");
   const [hace, setHace] = useState(0);
   const [isConnected, setIsConnected] = useState(false);

   const searchStatus = async () => {
      // setIsLoading(true);
      await patiensService
         .getActivityUser(patientRut)
         .then((response) => {
            setStatus(response.data.activity);
            updateClassStatus(response.data.activity);
            setHace(response.data.hace);

            if (status === "Ingreso a teleconsulta" || status === "Esta conectado") {
               timerStatus = setTimeout(searchStatus, 5000);
               setIsConnected(true);
            } else {
               timerStatus = setTimeout(searchStatus, 2000);
            }

         })
         .catch((error) => {
            console.log(error);
         });
      // setIsLoading(false);
   };

   const timeForHumans = (segundos) => {

      if (segundos === 0) {
         return ''
      } else
         if (segundos < 60) {
            return 'hace ' + parseInt(segundos) + ' segundos'
         } else
            if (segundos > 60 && segundos < 121) {
               return 'hace un minuto'
            } else
               if (segundos >= 121) {
                  return 'hace ' + parseInt(segundos / 60) + ' minutos'
               } else {
                  return ''
               }

   }

   const updateClassStatus = (status) => {
      if (status === "Desconectado") {
         setStatusClass("red-status");
      } else if (status === "Ingreso a teleconsulta" || status === "Esta conectado") {
         setStatusClass("green-status");
      } else {
         setStatusClass("orange-status");
      }
   };

   useEffect(() => {

      searchStatus()

      return () => {
         clearTimeout(timerStatus);
      };
   }, []);
   // }, [isLoading]);

   return (
      <div className="col-12 text-center nombre-paciente mt-2">
         <div className="status-styles">
            <span className="statusName">{status}</span>

            <div className="statusPointer">
               <span className={statusClass + " statusCircle"}></span>
            </div>
         </div>
         {statusClass !== 'green-status' ?
            <div className="status-styles" style={{ opacity: .8 }}>
               <span> {timeForHumans(hace)} </span>
            </div>
            : null
         }
      </div>
   );
};
export default StatusPaciente;
