import React from 'react'
import ResumenActividadesGrafico from '../resumenActividadesGrafico/ResumenActividadesGrafico';
import { Seguimiento } from '../seguimientoRecurso/Seguimiento';

import moment from "moment-timezone";
import DatePicker from "react-datepicker";

export const GetSeguimiento = ({
  arrayDaysFilter,
  diaInicial,
  diaFinal,
  diffDays,
  chartContainer,
  actividadesRealizadas,
  actividadesNoRealizadas,
  totalActividades,
  pacienteId,
  renderSaved,
  actividadesAsignadas,
  state,
  props,
  setState,

}) => {
  return (
  
    <div className="row mt-4 animated fadeIn">
      <div className="col-md-12 col-12">
        <div className="card little-shadow mb-4">
          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold text-dark col-4 col-md-6 col-sm-4">
              Resumen de actividad
            </h6>

            <div className="col-4 col-md-3 col-sm-4">
              <label>Fecha inicio</label>
              <DatePicker
                selected={moment(diaInicial).toDate()}
                name="fechaInicialSeguimiento"
                onChange={(date) => {
                  // console.log(date);
                  let dateIni = moment(date).toDate();
                  // let dateFin = moment(date).add(6, 'days').toDate();
                  setState((state, props) => ({
                    diaInicial: dateIni,
                    // diaFinal: dateFin,
                    diffDays: moment(diaFinal).diff(moment(date), "days"),
                  }));
                }}
                selectsStart
                className="p-0 pl-2 bg-light border "
                dateFormat="dd/MM/yyyy"
                startDate={moment(diaInicial).toDate()}
                minDate={moment(diaInicial).subtract(60,"days").toDate()}
                maxDate={moment(diaFinal).subtract(1, "days").toDate()}
              />
            </div>

            <div className="col-4 col-md-3 col-sm-4">
              <label>Fecha término</label>
              <DatePicker
                selected={moment(diaFinal).toDate()}
                name="fechaFinalSeguimiento"
                startDate={moment(diaFinal).toDate()}
                minDate={moment(diaInicial).add(1,"days").toDate()}
                onChange={(date) => {
                  setState((state, props) => ({
                    diaFinal: moment(date),
                    diffDays: moment(date).diff(
                      moment(diaInicial),
                      "days"
                    ),
                  })
                );
                }}
                className="p-0 pl-2 bg-light border "
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
          <Seguimiento
            arrayDias={arrayDaysFilter}
            diaInicial={diaInicial}
            diaFinal={diaFinal}
            diffDays={diffDays}
            chartContainer={chartContainer}
            actRealizadas={actividadesRealizadas}
            actNoRealizadas={actividadesNoRealizadas}
            totalActividades={totalActividades}
            patientId={pacienteId}
            render={renderSaved}
          />
        </div>
        <ResumenActividadesGrafico
          actividades={actividadesAsignadas}
          patientId={pacienteId}
        />
      </div>
    </div>

  );
}
