import React from 'react';
import {
    BrowserRouter as Router,
    useHistory,
} from "react-router-dom";
import iconTimer from "../../../assets/images/iconos/icon-time-black.svg";
import iconRepeat from "../../../assets/images/iconos/icon-repeat-black.svg";
import { IconButton } from '@material-ui/core';
import { SystemUpdateAlt } from '@material-ui/icons';
import imgNext from '../../../assets/images/iconos/icono-white-next.svg';

//Componente para el renderizado de PDF en la exploración de los recursos para el paciente.
const PDFLayout = ({ recurso }) => {
    let history = useHistory();

    return (
        <>
            <div className="steped-component">
                <div className="header-short">
                    <div className="stepped-nav">
                        <div className="backbutton">
                            <button onClick={() => history.goBack()}>
                                <svg
                                    version="1.1"
                                    id="Capa_1"
                                    x="0px"
                                    y="0px"
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                >
                                    {" "}
                                    <path
                                        fill="#000000"
                                        d="M21,11.25H4.811l7.72-7.72c0.293-0.293,0.293-0.768,0-1.062c-0.293-0.293-0.768-0.293-1.061,0l-9,9 c-0.005,0.005-0.006,0.012-0.011,0.017c-0.063,0.066-0.116,0.142-0.151,0.227c-0.038,0.091-0.057,0.187-0.057,0.283 c0,0.001-0.001,0.002-0.001,0.004c0,0.011,0.006,0.02,0.006,0.031c0.003,0.087,0.018,0.173,0.051,0.255 c0.038,0.093,0.094,0.176,0.164,0.246l8.999,8.999c0.146,0.146,0.339,0.22,0.53,0.22s0.384-0.073,0.531-0.219 c0.293-0.293,0.293-0.768,0-1.062l-7.72-7.72H21c0.414,0,0.75-0.336,0.75-0.75S21.414,11.25,21,11.25z"
                                    />{" "}
                                </svg>
                            </button>
                        </div>
                        <div className="stepped-title">
                            <span>{recurso.name.substr(0, 27) + "..."}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ver-historia">
                <div className="ver-historia">
                    <div className={recurso.media.url ? "actividadFondo text-center pb-1" : "actividadFondo p-4 text-center"}>
                        <object data={recurso.media.url} type="application/pdf" width="100%" height="100vh">
                            <a
                                href={recurso.media.url}
                                className={recurso.media.url ? "btn btn-submit btn-anchor btn-with-icon " : "btn btn-secondary disabled"}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {recurso.media.url ? 'Ver documento' : 'No hay documento'}
                                {recurso.media.url ? <img src={imgNext} alt="Ir al enlace" /> : null}
                            </a>
                            <div className="mt-1">
                                {recurso.media.url ? <small>Presiona el botón para visitar el documento.</small> : null}
                            </div>
                        </object>
                    </div>
                </div>

                <div className="recurso-view">
                    <div className="view-title">
                        <h3>{recurso.name}</h3>
                    </div>
                    <div className="view-extraDetail d-flex">
                        <span className="pr-1">
                            <img
                                width="20"
                                src={iconTimer}
                                className="opacity-05"
                            />
                            {recurso.extraDetail.duration}
                        </span>
                        <span>
                            <img
                                width="20"
                                src={iconRepeat}
                                className="opacity-05"
                            />
                            {recurso.extraDetail.suggestFrecuency}
                        </span>
                    </div>
                    <div className="view-description">
                        <p>{recurso.longDescription}</p>
                    </div>
                    <div className="end-page pt-3 pb-5">
                        <a href={recurso.media.url} download={recurso.name} className="btn-download" >
                            <SystemUpdateAlt className="color-primario mr-1" /> Descargar recurso
                        </a>
                    </div>


                </div>

                <div className="end-page pt-5 pb-5"></div>
            </div>
        </>
    )
}

export default PDFLayout;